import React, { useState, useEffect, useContext } from 'react';
import { callApi } from '../callApi';
import { AppContext } from '../components/AppContext';
import { useWebSocket } from './WebSocket';
import {
  Box, Grid, GridItem, Flex, Text, Button, 
  Table, Thead, Tbody, Tr, Th, Td, VStack, HStack, Heading,
  Divider, Icon, Avatar, Badge, Spinner, Tooltip
} from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';
import { FiUsers, FiMessageSquare, FiChevronUp, FiChevronDown } from 'react-icons/fi';

function Admin() {
  const [activeSection, setActiveSection] = useState('users');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [chatDetails, setChatDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  
  const { accessToken } = useContext(AppContext);
  const { socket } = useWebSocket();

  // Fixed color values
  const bgColor = 'white';
  const borderColor = 'gray.200';
  const sidebarBg = 'gray.50';
  const tableHeaderBg = 'gray.50';
  
  // Navigation items for the sidebar
  const navItems = [
    { id: 'users', label: 'Users', icon: FiUsers },
    { id: 'chatDetails', label: 'Chat Details', icon: FiMessageSquare },
  ];

  // Function to handle sorting
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Function to format date in a user-friendly way
  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else if (diffDays < 7) {
      return `${diffDays} days ago`;
    } else {
      return date.toLocaleDateString();
    }
  };

  // Get sorted users
  const getSortedUsers = () => {
    if (!sortConfig.key) {
      return users.sort(sortByRoleOrgAndEmail);
    }

    return [...users].sort((a, b) => {
      if (a[sortConfig.key] === null && b[sortConfig.key] === null) return 0;
      if (a[sortConfig.key] === null) return 1;
      if (b[sortConfig.key] === null) return -1;
      
      let comparison = 0;
      if (sortConfig.key === 'last_activity_date') {
        // For dates, compare as Date objects
        const dateA = a[sortConfig.key] ? new Date(a[sortConfig.key]) : null;
        const dateB = b[sortConfig.key] ? new Date(b[sortConfig.key]) : null;
        
        if (!dateA) return 1;
        if (!dateB) return -1;
        comparison = dateA - dateB;
      } else if (sortConfig.key === 'note_count' || sortConfig.key === 'case_count') {
        // For numeric values
        comparison = a[sortConfig.key] - b[sortConfig.key];
      } else {
        // For string values
        comparison = a[sortConfig.key].localeCompare(b[sortConfig.key]);
      }
      
      return sortConfig.direction === 'ascending' ? comparison : -comparison;
    });
  };

  const handleViewUsersButtonClick = async () => {
    setIsLoading(true);
    setError(null);
    setActiveSection('users');
    
    try {
      const response = await callApi('/get_user_list', accessToken, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const data = await response.json();
      
      if (data.status === 'success') {
        setUsers(data.user_list);
        setIsImpersonating(data.is_impersonating);
        setSelectedUser(data.impersonated_user);
      } else {
        // If the backend hasn't been updated to the new format yet
        setUsers(data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to load users. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleStartImpersonation = async (userId) => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await callApi('/start_impersonation', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ impersonated_user_id: userId }),
      });
      
      if (response.ok) {
        setIsImpersonating(true);
        setSelectedUser(users.find(user => user.id === userId));
        // Refresh the user list to get updated impersonation status
        handleViewUsersButtonClick();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to start impersonation');
      }
    } catch (error) {
      console.error('Error starting impersonation:', error);
      setError('Failed to start impersonation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleStopImpersonation = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await callApi('/stop_impersonation', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (response.ok) {
        setIsImpersonating(false);
        setSelectedUser(null);
        // Refresh the user list to get updated impersonation status
        handleViewUsersButtonClick();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to stop impersonation');
      }
    } catch (error) {
      console.error('Error stopping impersonation:', error);
      setError('Failed to stop impersonation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatDetailsButtonClick = async () => {
    setIsLoading(true);
    setError(null);
    setActiveSection('chatDetails');
    
    try {
      const response = await callApi('/admin_ionm_chat', accessToken, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch chat details');
      }
      
      const data = await response.json();
      setChatDetails(data);
    } catch (error) {
      console.error('Error fetching chat details:', error);
      setError('Failed to load chat details. Please try again.');
      setChatDetails(null);
    } finally {
      setIsLoading(false);
    }
  };

  const sortByRoleOrgAndEmail = (a, b) => {
    // Sort by role
    if (a.role === null && b.role === null) {
      // If both roles are null, proceed to org sorting
    } else if (a.role === null) return 1;
    else if (b.role === null) return -1;
    else if (a.role !== b.role) {
      return b.role.localeCompare(a.role);
    }
    
    // Sort by org
    const isADemo = a.org_name?.startsWith('Demo');
    const isBDemo = b.org_name?.startsWith('Demo');
    if (isADemo && !isBDemo) return 1;
    if (!isADemo && isBDemo) return -1;
    if (a.org_name !== b.org_name) {
      return a.org_name?.localeCompare(b.org_name);
    }
    
    // If roles and orgs are the same, sort by email
    return a.email?.localeCompare(b.email);
  };

  // Load users on initial render
  useEffect(() => {
    handleViewUsersButtonClick();
  }, []);

  // Render sort indicator
  const renderSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return null;
    }
    return sortConfig.direction === 'ascending' ? <FiChevronUp /> : <FiChevronDown />;
  };

  return (
    <Box>
      <HeaderMenus />
      
      <Grid
        templateColumns="250px 1fr"
        h="calc(100vh - 60px)"
      >
        {/* Sidebar */}
        <GridItem 
          bg={sidebarBg} 
          borderRight="1px" 
          borderColor={borderColor}
          p={4}
          overflowY="auto"
        >
          <VStack spacing={2} align="stretch">
            <Heading size="md" mb={4}>Admin Dashboard</Heading>
            
            {navItems.map(item => (
              <Button
                key={item.id}
                leftIcon={<Icon as={item.icon} />}
                justifyContent="flex-start"
                variant={activeSection === item.id ? "solid" : "ghost"}
                colorScheme={activeSection === item.id ? "blue" : "gray"}
                onClick={() => {
                  if (item.id === 'users') handleViewUsersButtonClick();
                  if (item.id === 'chatDetails') handleChatDetailsButtonClick();
                }}
                mb={1}
              >
                {item.label}
              </Button>
            ))}
          </VStack>
        </GridItem>

        {/* Main Content */}
        <GridItem 
          bg={bgColor} 
          p={6} 
          overflowY="auto"
        >
          {/* Error Message (if any) */}
          {error && (
            <Box p={4} mb={4} borderRadius="md" borderWidth="1px" borderColor="red.300" bg="red.50">
              <Text color="red.600">{error}</Text>
            </Box>
          )}
          
          {/* Loading Indicator */}
          {isLoading && (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="blue.500" />
            </Flex>
          )}

          {/* Users Section */}
          {!isLoading && activeSection === 'users' && (
            <Box>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Heading size="md">User Management</Heading>
                <Button 
                  colorScheme="blue" 
                  size="sm" 
                  onClick={handleViewUsersButtonClick} 
                  isLoading={isLoading}
                >
                  Refresh Users
                </Button>
              </Flex>
              
              {selectedUser && (
                <Box 
                  p={4} 
                  mb={4} 
                  bg="blue.50" 
                  borderRadius="md" 
                  borderLeft="4px solid" 
                  borderColor="blue.500"
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <HStack>
                      <Avatar size="sm" name={selectedUser.email} />
                      <Box>
                        <Text fontWeight="bold">Currently impersonating:</Text>
                        <Text>{selectedUser.email}</Text>
                      </Box>
                    </HStack>
                    <Button 
                      colorScheme="red" 
                      size="sm" 
                      onClick={handleStopImpersonation}
                      isLoading={isLoading}
                    >
                      Stop Impersonating
                    </Button>
                  </Flex>
                </Box>
              )}

              {users.length > 0 ? (
                <Box overflowX="auto" borderRadius="md" borderWidth="1px" borderColor={borderColor}>
                  <Table variant="simple" size="sm">
                    <Thead bg={tableHeaderBg}>
                      <Tr>
                        <Th 
                          cursor="pointer" 
                          onClick={() => requestSort('email')}
                        >
                          <Flex align="center">
                            Email {renderSortIcon('email')}
                          </Flex>
                        </Th>
                        <Th 
                          cursor="pointer" 
                          onClick={() => requestSort('role')}
                        >
                          <Flex align="center">
                            Role {renderSortIcon('role')}
                          </Flex>
                        </Th>
                        <Th 
                          cursor="pointer" 
                          onClick={() => requestSort('org_name')}
                        >
                          <Flex align="center">
                            Organization {renderSortIcon('org_name')}
                          </Flex>
                        </Th>
                        <Th 
                          cursor="pointer" 
                          onClick={() => requestSort('last_activity_date')}
                        >
                          <Flex align="center">
                            Last Activity {renderSortIcon('last_activity_date')}
                          </Flex>
                        </Th>
                        <Th 
                          cursor="pointer" 
                          onClick={() => requestSort('note_count')}
                        >
                          <Flex align="center">
                            Notes {renderSortIcon('note_count')}
                          </Flex>
                        </Th>
                        <Th 
                          cursor="pointer" 
                          onClick={() => requestSort('case_count')}
                        >
                          <Flex align="center">
                            Cases {renderSortIcon('case_count')}
                          </Flex>
                        </Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {getSortedUsers().map((user) => (
                        <Tr key={user.id}>
                          <Td>
                            <HStack>
                              <Avatar size="xs" name={user.email} />
                              <Text>{user.email}</Text>
                            </HStack>
                          </Td>
                          <Td>
                            {user.role ? (
                              <Badge colorScheme={user.role === 'admin' ? 'red' : 'blue'}>
                                {user.role}
                              </Badge>
                            ) : 'N/A'}
                          </Td>
                          <Td>{user.org_name}</Td>
                          <Td>
                            <Tooltip 
                              label={user.last_activity_date ? new Date(user.last_activity_date).toLocaleString() : 'No activity recorded'} 
                              placement="top"
                            >
                              <Text>{formatDate(user.last_activity_date)}</Text>
                            </Tooltip>
                          </Td>
                          <Td isNumeric>{user.note_count || 0}</Td>
                          <Td isNumeric>{user.case_count || 0}</Td>
                          <Td>
                            <Button 
                              colorScheme="teal"
                              size="sm"
                              onClick={() => handleStartImpersonation(user.id)}
                              isDisabled={isImpersonating}
                              isLoading={isLoading}
                            >
                              Impersonate
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ) : (
                <Box p={4} textAlign="center" borderRadius="md" borderWidth="1px">
                  <Text>No users found</Text>
                </Box>
              )}
            </Box>
          )}

          {/* Chat Details Section */}
          {!isLoading && activeSection === 'chatDetails' && (
            <Box>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Heading size="md">Chat Details</Heading>
                <Button 
                  colorScheme="blue" 
                  size="sm" 
                  onClick={handleChatDetailsButtonClick}
                  isLoading={isLoading}
                >
                  Refresh Chat Details
                </Button>
              </Flex>
              
              {chatDetails && chatDetails.chat_details && chatDetails.chat_details.length > 0 ? (
                <Box overflowX="auto" borderRadius="md" borderWidth="1px" borderColor={borderColor}>
                  <Table variant="simple" size="sm">
                    <Thead bg={tableHeaderBg}>
                      <Tr>
                        <Th>Chat Info</Th>
                        <Th>Messages</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {chatDetails.chat_details
                        .sort((a, b) => new Date(b.chat_created_at) - new Date(a.chat_created_at))
                        .map((row, index) => (
                          <Tr key={index}>
                            <Td width="30%">
                              <VStack align="start" spacing={1}>
                                <Text fontWeight="bold">{row.email}</Text>
                                <Text fontSize="sm" color="gray.500">ID: {row.chat_id}</Text>
                                <Text fontSize="sm">{new Date(row.chat_created_at).toLocaleString()}</Text>
                                {row.chat_additional_data && (
                                  <Text fontSize="xs" color="gray.500">{row.chat_additional_data}</Text>
                                )}
                              </VStack>
                            </Td>
                            <Td>
                              <VStack align="start" spacing={2} divider={<Divider />}>
                                {row.messages.map((message, messageIndex) => (
                                  <Box 
                                    key={messageIndex} 
                                    p={2} 
                                    borderRadius="md" 
                                    bg={messageIndex % 2 === 0 ? 'blue.50' : 'green.50'}
                                    maxWidth="100%"
                                  >
                                    <Text fontSize="xs" fontWeight="bold" mb={1}>
                                      {messageIndex % 2 === 0 ? 'User' : 'Assistant'}
                                    </Text>
                                    <Text fontSize="sm">{message}</Text>
                                  </Box>
                                ))}
                              </VStack>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
              ) : (
                <Box p={4} textAlign="center" borderRadius="md" borderWidth="1px">
                  <Text>No chat details available.</Text>
                </Box>
              )}
            </Box>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Admin;