import React, { useMemo, useState, useEffect, useContext } from 'react';
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi'; 
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { useParams, useLocation, useNavigate } from 'react-router-dom'; // Import useParams and useHistory

import DOMPurify from 'dompurify';

import ChatToggle from './ChatToggle'; // Adjust the import path as necessary



import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,

 } from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';
import { WebSocketProvider, useWebSocket } from './WebSocket';  

function Admin() {
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const [CDAData, setCDAData] = useState(null);
    const [CDAHTMLData, setCDAHTMLData] = useState(null);
    const [documentContent, setDocumentContent] = useState(null); // New state for document content
    const location = useLocation(); // Use useLocation to access the location object
    const navigate = useNavigate(); // Use useNavigate for navigation
    const { docId } = useParams(); // Extract the document ID from the URL path
    const [issueDescription, setIssueDescription] = useState("Freestyle continuous glucose monitor");
    const [issueRequirements, setIssueRequirements] = useState(`Patient has been diagnosed with diabetes.
Patient has experienced one of the following situations when it comes to treating their glucose:
1) they are insulin-treated
2) they have a history of problematic hypoglycemia with documentation of either: multiple level 2 hypoglycemic events or a history of one level 3 hypoglycemic event`);


    const { socket } = useWebSocket();

    const CustomLink = ({ href, children }) => {
      // Assuming the href is in the format "/abn/4139"
      // You can add additional logic here if needed to construct the correct path
      return <Link to={href}>{children}</Link>;
    };

    const MarkdownContent = ({ content }) => {
      // Specify the custom link component for anchor elements
      const components = {
        a: CustomLink,
      };
    
      return <ReactMarkdown components={components}>{content}</ReactMarkdown>;
    };

    useEffect(() => {
      if (docId) {
          const fetchDocumentContent = async () => {
              try {

                  const response = await callApi("/cda_document", accessToken, {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({ document_id: docId }),
                  });

                  if (!response.ok) {
                      throw new Error('Network response was not ok');
                  }

                  const content = await response.json();
                  const document_content = content.document_content;
                  const document_html = { __html: document_content };
                  setDocumentContent(document_html); // Update the state with the fetched content
              } catch (error) {
              }
        };

        fetchDocumentContent();
      }
    }, [docId]);

    const handleCDAHTMLData = async () => {
      
        try {
          // Call the API to get all CDA documents
          let response = await callApi("/cda_documents", accessToken, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const documentsList = await response.json();
      
          // Initialize an empty string to hold all sanitized HTML content
          let allDocumentsHTML = "";
      
          // Iterate through each document
          for (const doc of documentsList) {
            // Sanitize the document_content of each document
            //const sanitizedHTML = DOMPurify.sanitize(doc.document_content);
            const sanitizedHTML = doc.document_content;
            // Append the sanitized HTML content to the allDocumentsHTML string
            allDocumentsHTML += sanitizedHTML;
          }
      
          const markup = { __html: allDocumentsHTML };
          setCDAHTMLData(markup);
          
        } catch (error) {
        }
      };
    


      const handleCDASearchButtonClick = async (event) => {
        setCDAHTMLData(null);
        let response = await callApi("/find_relevant_cda_documents", accessToken, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              issue_description: issueDescription,
              socket_id: socket.id, 
              issue_requirements: issueRequirements,
            }),
        });  

        const data = await response.json();
        let cdaData = data.review_cda_documents;
        //remove all quotes from the cda data
        cdaData = cdaData.replace(/"/g, "");
        cdaData = cdaData.replace(/'/g, "");
        cdaData = cdaData + "test";
        setCDAData(cdaData);
    }

    return (
            <Box textAlign="center" fontSize="xl" bg="white">
            <Grid
            templateAreas={`"header header"    
                            "main results"
                            "form form"
                            "footer footer"`}
            gridTemplateRows={'60px auto 1fr 60px'}
            gridTemplateColumns={'1fr 1fr'}
            h='100%'
            gap='2'
            color='blackAlpha.800'
            fontWeight='bold'
            >            
                <HeaderMenus />
                <GridItem bg='white' padding={4} area={'main'}>
                <Flex justifyContent="left" alignItems="left" direction="column">
                  <Flex direction="column" mb={4}>
                    <Text fontSize="xl" color="3e81c3">
                      Issue
                    </Text>
                    <Textarea
                      placeholder="Issue Description"
                      value={issueDescription}
                      onChange={(e) => setIssueDescription(e.target.value)}
                    />
                  </Flex>
                  <Flex direction="column" mb={4}>
                    <Text fontSize="xl" color="3e81c3">
                      Requirements
                    </Text>
                    <Textarea
                      placeholder="Issue Requirements"
                      value={issueRequirements}
                      onChange={(e) => setIssueRequirements(e.target.value)}
                    />
                  </Flex>
                  <Button onClick={handleCDASearchButtonClick}>ABN Analysis</Button>
                </Flex>
                        {CDAData && (
                                            <Box bg='white' padding={4} area={'main'} verticalAlign={"bottom"} textAlign={"left"} className="markdown-content">
                                            <MarkdownContent content={CDAData} />
                                            <br/>
                                            <br/>
                                                <ReactMarkdown>
                                                {CDAData}
                                                </ReactMarkdown>
                                            </Box>
                                            
                                        )}
                        </GridItem>
            <GridItem bg='white' padding={4} area={'results'}>
                <Tabs>
                  <TabList>
                  <Tab>Results</Tab>
                  <Tab>Full CDA</Tab>
                  </TabList>
                  <TabPanels>
                  <TabPanel>
                  {documentContent && (
                                      <Box bg='white' padding={4} area={'results'} verticalAlign={"bottom"} textAlign={"left"}>
                                        <div dangerouslySetInnerHTML={documentContent} />
                                      </Box>
                   )}
                  </TabPanel>
                  <TabPanel>
                 
                  {CDAHTMLData && (
                <Box
                  bg='gray.50' // Chakra-UI uses a color scale, 'gray.50' is a very light grey
                  padding={4}
                  verticalAlign={"bottom"}
                  textAlign={"left"} 
                  border="10px solid gray" // This adds a 1px solid grey border
                >
                    {/* Use dangerouslySetInnerHTML to insert the sanitized HTML */}
                    <div dangerouslySetInnerHTML={CDAHTMLData} />
                  </Box>
                  )}
                  </TabPanel>
                  </TabPanels>
                  </Tabs>
              </GridItem>
              <GridItem
                      bg='white'
                      borderTop='1px'
                      borderColor='brand.200'
                      paddingY={4}
                      paddingX={6}
                      area={'footer'}
                      >
                      <Flex justifyContent="center" alignItems="center">
                          <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                          <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                          &copy; {new Date().getFullYear()}
                          </Text>
                      </Flex>
                </GridItem>
            </Grid>
                <ChatToggle
                    contextType={'revedy'}
                    contextData={'revedy_scribe_services'}
                    contextPersona={'revedy_scribe_services'}
                    firstMessage={"Hi, what can I do on this page?"}
                    useOptions={false}
                    maintainContext={true}
                    contextMode={'popup'}
                />
            </Box>
    );
}

export default Admin;