import React from 'react';
import {
  Box, Grid, GridItem, Heading, Text, Button, VStack, HStack, Icon, Link
} from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';
import { FiExternalLink, FiCode, FiFileText, FiActivity } from 'react-icons/fi';

function ExternalCaseManager() {
  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={8} area={'main'}>
          <VStack spacing={8} align="stretch">
            <Box textAlign="center">
              <Heading as="h1" size="xl" mb={4} color="blue.600">
                External Case Manager
              </Heading>
              <Text fontSize="lg" color="gray.600">
                This page has been deprecated as we've moved functionality to more specialized solutions.
                Please use the links below to access the updated tools.
              </Text>
            </Box>

            <VStack spacing={6} align="stretch" mt={6}>
              <Box 
                p={6} 
                borderWidth="1px" 
                borderRadius="lg" 
                boxShadow="md"
                _hover={{ boxShadow: "lg", borderColor: "blue.300" }}
                transition="all 0.3s"
              >
                <HStack spacing={4}>
                  <Icon as={FiCode} boxSize={8} color="blue.500" />
                  <VStack align="start" spacing={2}>
                    <Heading as="h2" size="md">General CPT Coding</Heading>
                    <Text>For general CPT coding of a wide variety of cases</Text>
                    <Button 
                      as={Link} 
                      href="/full-cpt-coder" 
                      colorScheme="blue" 
                      rightIcon={<FiExternalLink />}
                      _hover={{ textDecoration: 'none' }}
                    >
                      Go to Full CPT Coder
                    </Button>
                  </VStack>
                </HStack>
              </Box>

              <Box 
                p={6} 
                borderWidth="1px" 
                borderRadius="lg" 
                boxShadow="md"
                _hover={{ boxShadow: "lg", borderColor: "blue.300" }}
                transition="all 0.3s"
              >
                <HStack spacing={4}>
                  <Icon as={FiActivity} boxSize={8} color="green.500" />
                  <VStack align="start" spacing={2}>
                    <Heading as="h2" size="md">IONM Cases</Heading>
                    <Text>For intraoperative neurophysiological monitoring cases</Text>
                    <Button 
                      as={Link} 
                      href="/ionm-docs" 
                      colorScheme="green" 
                      rightIcon={<FiExternalLink />}
                      _hover={{ textDecoration: 'none' }}
                    >
                      Go to IONM Documentation
                    </Button>
                  </VStack>
                </HStack>
              </Box>

              <Box 
                p={6} 
                borderWidth="1px" 
                borderRadius="lg" 
                boxShadow="md"
                _hover={{ boxShadow: "lg", borderColor: "blue.300" }}
                transition="all 0.3s"
              >
                <HStack spacing={4}>
                  <Icon as={FiFileText} boxSize={8} color="purple.500" />
                  <VStack align="start" spacing={2}>
                    <Heading as="h2" size="md">API Documentation</Heading>
                    <Text>For general API documentation and integration details</Text>
                    <Button 
                      as={Link} 
                      href="/apidocs" 
                      colorScheme="purple" 
                      rightIcon={<FiExternalLink />}
                      _hover={{ textDecoration: 'none' }}
                    >
                      Go to API Documentation
                    </Button>
                  </VStack>
                </HStack>
              </Box>
            </VStack>

            <Box textAlign="center" mt={6} p={4} bg="gray.50" borderRadius="md">
              <Text fontSize="md" color="gray.600">
                If you need assistance with migrating your workflows to the new tools, 
                please contact the support team.
              </Text>
            </Box>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default ExternalCaseManager;