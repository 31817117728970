import React, { useState, useContext, useEffect } from 'react';
import { callApi } from '../callApi';
import { AppContext } from '../components/AppContext';
import {
  Box,
  Grid,
  GridItem,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Divider,
  Alert,
  AlertIcon,
  Flex,
  Spinner
} from "@chakra-ui/react";
import { RepeatIcon } from '@chakra-ui/icons';
import HeaderMenus from './HeaderMenus';

function ClientAdmin() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();
  
  // Create user form states
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [userContext, setUserContext] = useState('');
  
  const loadUsers = async () => {
    setIsLoading(true);
    try {
      const response = await callApi('/get_user_list', accessToken, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (response.status === 403) {
        setHasPermission(false);
        return;
      }
      
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      
      const data = await response.json();
      if (Array.isArray(data)) {
        setUsers(data);
        setHasPermission(true);
      } else {
        setUsers([]);
        console.warn('Received non-array data from API:', data);
      }
    } catch (err) {
      console.error('Error loading users:', err);
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleCreateUser = async (event) => {
    event.preventDefault();
    setIsCreatingUser(true);

    // Basic validation
    if (!email || !name) {
      toast({
        title: 'Missing information',
        description: 'Please provide both email and name.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      setIsCreatingUser(false);
      return;
    }

    try {
      const response = await callApi('/create_user', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email: email, 
          name: name,
          user_context: userContext,  
        }),
      });
      
      if (response.status === 403) {
        toast({
          title: 'Permission Denied',
          description: 'You do not have permission to create users.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setHasPermission(false);
        setIsCreatingUser(false);
        return;
      }
      
      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'User created',
          description: `${name} (${email}) has been added successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setEmail('');
        setName('');
        setUserContext('');
        loadUsers(); // Refresh the user list
      } else {
        toast({
          title: 'Creation failed',
          description: data.error || 'Failed to create user.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An unexpected error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsCreatingUser(false);
    }
  };
  
  // Sort users by email if we have users
  const sortedUsers = users.length > 0 ? [...users].sort((a, b) => a.email.localeCompare(b.email)) : [];
  
  // Load users on component mount
  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid
        templateAreas={`"header header"    
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px auto 1fr 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontSize='12px'
      >
        <HeaderMenus />

        <GridItem bg='gray.50' padding={6} area={'main'}>
          <Box maxWidth="1200px" margin="0 auto">
            <Heading as="h1" size="lg" mb={6} textAlign="left">
              Organization User Management
            </Heading>
            
            {!hasPermission ? (
              <Alert status="warning" borderRadius="md" mb={6}>
                <AlertIcon />
                <Text>You don't have permission to manage users. Please contact your administrator.</Text>
              </Alert>
            ) : (
              <>
                {/* Create User Form */}
                <Box bg="white" p={5} borderRadius="md" boxShadow="sm" mb={8}>
                  <Heading as="h2" size="md" mb={4} textAlign="left">
                    Add New User
                  </Heading>
                  
                  <form onSubmit={handleCreateUser}>
                    <Flex gap={4} flexWrap="wrap">
                      <FormControl id="email" isRequired flex="1" minW="250px">
                        <FormLabel>Email address</FormLabel>
                        <Input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="user@example.com"
                        />
                      </FormControl>
                      
                      <FormControl id="name" isRequired flex="1" minW="250px">
                        <FormLabel>Name</FormLabel>
                        <Input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Full Name"
                        />
                      </FormControl>
                    </Flex>

                    <FormControl id="user_context" mt={4}>
                      <FormLabel>User Description (Optional)</FormLabel>
                      <Input
                        type="text"
                        value={userContext}
                        onChange={(e) => setUserContext(e.target.value)}
                        placeholder="Brief description of user's role or responsibilities"
                      />
                    </FormControl>

                    <Button
                      mt={4}
                      colorScheme="blue"
                      type="submit"
                      isLoading={isCreatingUser}
                    >
                      Create User
                    </Button>
                  </form>
                </Box>
                
                <Divider my={6} />
              </>
            )}
            
            {/* User List */}
            <Flex justify="space-between" align="center" mb={4}>
              <Heading as="h2" size="md" textAlign="left">
                Organization Users
              </Heading>
              <Button 
                leftIcon={<RepeatIcon />}
                onClick={loadUsers}
                isLoading={isLoading}
                size="sm"
              >
                Refresh
              </Button>
            </Flex>
            
            {isLoading ? (
              <Flex justify="center" my={8}>
                <Spinner size="xl" />
              </Flex>
            ) : !hasPermission ? (
              <Alert status="info" borderRadius="md">
                <AlertIcon />
                <Text>User information is not available. You may not have sufficient permissions.</Text>
              </Alert>
            ) : sortedUsers.length > 0 ? (
              <Box overflowX="auto" bg="white" borderRadius="md" boxShadow="sm">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Email</Th>
                      <Th>Name</Th>
                      <Th>Role</Th>
                      <Th>Organization</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sortedUsers.map((user) => (
                      <Tr key={user.id || user.email}>
                        <Td>{user.email}</Td>
                        <Td>{user.name || 'N/A'}</Td>
                        <Td>{user.role || 'user'}</Td>
                        <Td>{user.org_name || 'N/A'}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            ) : (
              <Alert status="info" borderRadius="md">
                <AlertIcon />
                <Text>No users found. {hasPermission ? 'Create a new user to get started.' : ''}</Text>
              </Alert>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default ClientAdmin;