import { useState, version } from 'react';
import { useRef, useEffect, useContext } from 'react';
import { AppContext } from './components/AppContext';
import { useHistory, useNavigate, useLocation } from 'react-router-dom';

import React from 'react'
import { 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Grid,
  GridItem,
  Button,
  Text,
  Flex,
  Select,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
//  background 
} from '@chakra-ui/react';
import { ChevronDownIcon } from "@chakra-ui/icons";

import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import './custom.css';
import { renderProblems,
  renderCPTProcedure, 
  renderMdmProblems, 
  renderAnalysis, 
  renderRisk, 
  renderCPTScore,
  renderDiscussion,
  renderICD10,
  renderICD10Grid,
  renderPatient,
  renderZCodes,
  connectWebSocket } from './utils';
import AccountMenu from './account';
import { useAuth0 } from "@auth0/auth0-react";
import { callApi } from './callApi'; 
import { useAccessToken } from './useAccessToken'; 

import { Image } from '@chakra-ui/react';
import NoteServiceList from './noteServiceList';
import ChatBox from './components/ChatBox';
import Chat from './components/Chat';
import HeaderMenus from './components/HeaderMenus';
import ChatToggle from './components/ChatToggle';
import { WebSocketProvider, useWebSocket } from './components/WebSocket'; 
import { handleNoteList } from './noteService'; // adjust the import based on your project structure
import ReactMarkdown from 'react-markdown';

function TextEntry(props) {
  
  const [messageToSend, setMessageToSend] = useState('');
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [resetChat, setResetChat] = useState(false);

  let fullProgressArray = [];
  

  //const { appState, setAppState } = useContext(AppContext);
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();
  let colors = ['#bde8ff','#ffbde8','#e8ffbd','#ffe8bd','#e8bdff','#ffb8b8','#85ff85','#72ffe6','#56b5ff','	#d889ff'];

  const onProgress = (data) => {       
    if ('problems' in data) {
      setProblemsData({
        rawdata: data.problems,
        data:renderMdmProblems(data.problems),
        status: "complete",
      });
    }
    if ('z_codes' in data) {
      setZCodes(data.z_codes);
    }

    if ('intro' in data) {
      setIntroData({
        data: data.intro,
        status: "complete",
      });
    }
    if ('analysis_run_id' in data) {
      setRunIdChatBox(data.analysis_run_id);
    }

    if ('analysis' in data) {
      setAnalysisData({
        rawdata: data.analysis,
        data:renderAnalysis(data.analysis),
        status: "complete",
      });
    }
    if ('risk' in data) {
      setRiskData({
        rawdata: data.risk,
        data:renderRisk(data.risk),
        status: "complete",
      });
    }
    if ('score' in data) {
      setScoreData({
        data:renderCPTScore(data.score),
        status: "complete",
      });
    }
    if ('patient' in data) {
      setPatientData({
        data:renderPatient(data.patient),
        rawcontent: data.patient,
        status: "complete",
      });
    }
    if ('discussion' in data) {
      setDiscussionData({
        data:renderDiscussion(data.discussion, data.score),
        status: "complete",
      });
    }
    if ('icd10' in data) {
      setICD10Data({
        data:renderICD10(data.icd10,colors,formatDetailsList,setFormatDetailsList,handleTextClick, expandedCPTSections, setExpandedCPTSections),
        status: "complete",
      });
    }
    if ('cpt_codes' in data) {
      setCPTProcedureData({
        data:renderCPTProcedure(data.cpt_codes),
        status: "complete",
      });
    }

    if ('diagnosis' in data) {
      if ("list" in data.diagnosis) {
        let listText = "Found initial results for " + data.diagnosis.list.length 
          + " diagnosis codes (" + data.diagnosis.list.join(', ') + ")";
        setDiagnosisData({
          data: listText,
          status: "in progress",
        });
      }
      else {
        let colors = ['#bde8ff','#ffbde8','#e8ffbd','#ffe8bd','#e8bdff','#ffb8b8','#85ff85','#72ffe6','#56b5ff','	#d889ff'];
        if (interimDiagnosisData === "") {
          interimDiagnosisData = "Interim diagnosis:" + data.diagnosis.icd10_code;
        } else {
          interimDiagnosisData = interimDiagnosisData + ", " + data.diagnosis.icd10_code;
        }
        setDiagnosisData({
          data: interimDiagnosisData,
          status: "complete",
        });
      }
    }
    if ('message' in data) {
      const uniqueKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      const progressElement = (
        <div key={uniqueKey}>
          {data.message}
        </div>
      );
      let timestamp = new Date().toLocaleString();
      const progressElementwithTime = (
        <div key={uniqueKey}>
          <Spinner size="xs" marginLeft="8px" marginRight="8px"/>{data.message}
        </div>
      );
      fullProgressArray.push(progressElementwithTime);
      setProgressResultsContent(progressElementwithTime);
    }
    if ('status' in data) {
      if (data.status === 'run_complete') {
          const completedAnalyze = (
            <div key='completedAnalyze'>
              <Text
              fontSize="sm"
              color="blue.500"
              onClick={() => handleAnalyzeButtonClick(NeditorRef, noteId, versionId, nId)}
              _hover={{ textDecoration: "underline" }}
            >
              View Results
            </Text>
        
              Coding Complete
            </div>
          );          
          setProgressResultsContent(completedAnalyze);        
        setProgressResultsContent(completedAnalyze);
      }
    }
  };

  const { socket } = useWebSocket();

  useEffect(() => {
    if (socket) {
      socket.on('progress', onProgress);
      return () => {
        socket.off('progress', onProgress);
      };
    }
  }, [socket, onProgress]);

  const sendMessage = (message) => {
    setMessageToSend(message);
    setTriggerSubmit(Date.now());
  };

  
  const editorStyles = {
    fontWeight: 'normal'
  };
  
  const options = {
    sanitize: {
      br: true,
    },
  };

  const modules = {
    toolbar: false
  }

  const [formatDetailsList, setFormatDetailsList] = useState({});
  const [legendResultsContent, setLegendResultsContent] = useState([]);
  const [progressResultsContent, setProgressResultsContent] = useState([]);
  const [noteVersionsContent, setNoteVersionsContent] = useState([]);

  const [highlightStates, setHighlightStates] = useState({});
  const [selectedKey, setSelectedKey] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  let interimDiagnosisData = "";
  // Define state variables for each data field
  const [scoreData, setScoreData] = useState({
    status: "initial",
    data: null,
  });

  const [runIdChatBox, setRunIdChatBox] = useState(false);

  const [cptProcedureData, setCPTProcedureData] = useState({
    status: "initial",
    data: null,
  });

  const [icd10Data, setICD10Data] = useState({
    status: "initial",
    data: null,
  });

  const [diagnosisData, setDiagnosisData] = useState({
    status: "initial",
    data: null,
  });
  const [patientData, setPatientData] = useState({
    status: "initial",
    data: null,
  });
  const [discussionData, setDiscussionData] = useState({
    status: "initial",
    data: null,
  });
  const [problemsData, setProblemsData] = useState({
    status: "initial",
    data: null,
  });
  const [introData, setIntroData] = useState({
    status: "initial",
    data: null,
  });
  const [analysisData, setAnalysisData] = useState({
    status: "initial",
    data: null,
  });
  const [riskData, setRiskData] = useState({
    status: "initial",
    data: null,
  });
  const [selectedData, setSelectedData] = useState(null);
  const [diagnosisGrid, setDiagnosisGrid] = useState(null);
  const [expandedCPTSections, setExpandedCPTSections] = useState({});


const ScoringTable = ({ analysisData, problemsData, riskData }) => {
  console.log('Problems Data:', problemsData.rawdata); // Diagnostic log
  analysisData = analysisData.rawdata;

  const problemTypes = [
    { id: '1', description: 'Self-limited or minor' },
    { id: '2', description: 'Stable chronic illness' },
    { id: '3', description: 'Acute uncomplicated illness or injury' },
    { id: '4', description: 'Chronic illness with mild exacerbation, progression, or side effects of treatment' },
    { id: '5', description: 'Acute illness with systemic symptoms' },
    { id: '6', description: 'Acute complicated injury' },
    { id: '7', description: 'Chronic illness with severe exacerbation, progression, or side effects of treatment' },
    { id: '8', description: 'Acute or chronic illness or injury that poses a threat to life or bodily function' },
    { id: '9', description: 'Abrupt change in neurologic status' },
  ];

  const getCount = (problemType) => {
    if (problemsData.rawdata) {
      return problemsData.rawdata.filter((problem) => problem.id === problemType).length;
    }
    return 0;
  };

  const problemsContribution = () => {
    if (getCount('8') >= 1 || getCount('9') >= 1) {
      return 'High';
    }
    if (getCount('4') >= 1 || getCount('2') >= 2 || getCount('5') >= 1 || getCount('6') >= 1 || getCount('7') >= 1) {
      return 'Moderate';
    }
    if (getCount('1') >= 2 || getCount('2') >= 1 || getCount('3') >= 1) {
      return 'Low';
    }
    return 'N/A';
  };

  const getModerateRequirement = (problemType) => {
    switch (problemType) {
      case '2':
        return getCount(problemType) >= 2 ? 'Contributes to Moderate' : 'Requires 2 or more problems';
      case '4':
      case '5':
      case '6':
      case '7':
        return getCount(problemType) >= 1 ? 'Contributes to Moderate' : 'Requires 1 or more problems';
      default:
        return 'N/A';
    }
  };

  const getHighRequirement = (problemType) => {
    switch (problemType) {
      case '8':
      case '9':
        return getCount(problemType) >= 1 ? 'Contributes to High' : 'Requires 1 or more problems';
      default:
        return 'N/A';
    }
  };

  const analysisContribution = () => {
    const cat1Count = analysisData?.cat1?.count || 0;
    const cat2Count = analysisData?.cat2?.count || 0;
    const cat3Count = analysisData?.cat3?.count || 0;

    if ((cat1Count >= 3 && cat2Count >= 1) || (cat3Count >= 1 && cat2Count >= 1) || (cat1Count >= 3 && cat3Count >= 1)) {
      return 'High';
    }
    if (cat1Count >= 3 || cat2Count >= 1 || cat3Count >= 1) {
      return 'Moderate';
    }
    if (cat1Count >= 2 || cat2Count >= 1) {
      return 'Low';
    }
    return 'N/A';
  };

//            <td>{riskData.rawdata.moderate['likelihood score']}</td>

  const riskContribution = () => {
    const moderateRisk = riskData.rawdata.moderate['likelihood score'];
    const highRisk = riskData.rawdata.high['likelihood score'];

    if (highRisk >= 50) {
      return 'High';
    }
    if (moderateRisk >= 50) {
      return 'Moderate';
    }
    return 'Low';
  };


  return (
    <>
<Text as="div"  align="left" fontSize="12" fontWeight="normal">

      <table>
      <tbody>
  <tr>
    <td style={{ width: '50%', padding: '8px', borderBottom: '1px solid #ccc' }}>Problems Addressed</td>
    <td style={{ width: '50%', padding: '8px', borderBottom: '1px solid #ccc' }}>{problemsContribution()}</td>
  </tr>
  <tr>
    <td style={{ width: '50%', padding: '8px', borderBottom: '1px solid #ccc' }}>Data Reviewed and Analyzed </td>
    <td style={{ width: '50%', padding: '8px', borderBottom: '1px solid #ccc' }}>{analysisContribution()}</td>
  </tr>
  <tr>
    <td style={{ width: '50%', padding: '8px' }}>Risk of Complications/Morbidity </td>
    <td style={{ width: '50%', padding: '8px' }}>{riskContribution()}</td>
  </tr>
</tbody>  </table>


    </Text>
    
    <table style={{ width: '250px', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={{ width:'125px', textAlign:'left' }}>Problem</th>
        <th style={{ width:'125px', textAlign:'center' }}>Count</th>
      </tr>
    </thead>
    <tbody>
      {problemTypes
        .filter((problemType) => getCount(problemType.id) > 0)
        .map((problemType) => (
        <tr key={problemType.id}>
          <td style={{ width:'125px', textAlign:'left' }}>{problemType.id}</td>
          <td style={{ width:'125px', textAlign:'center' }}>{getCount(problemType.id)}</td>
        </tr>
      ))}
    </tbody>
  </table>
  Moderate: two or more problem types 2, or one or more problem types 4, 5, 6, 7<br/>
  High: one or more problem types 8, 9 
  <br/>
  <br/>

  <table style={{ width: '250px', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={{ width:'125px', textAlign:'left' }}>Analysis</th>
        <th style={{ width:'125px', textAlign:'center' }}>Count</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ width:'125px', textAlign:'left' }}>Category 1</td>
        <td style={{ width:'125px', textAlign:'center' }}>{analysisData.cat1?.count || 0}</td>
      </tr>
      <tr>
        <td style={{ width:'125px', textAlign:'left' }}>Category 2</td>
        <td style={{ width:'125px', textAlign:'center' }}>{analysisData.cat2?.count || 0}</td>
      </tr>
      <tr>
        <td style={{ width:'125px', textAlign:'left' }}>Category 3</td>
        <td style={{ width:'125px', textAlign:'center' }}>{analysisData.cat3?.count || 0}</td>
      </tr>
    </tbody>
  </table>

  <Text as="div"  align="left" fontSize="12" fontWeight="normal">
    Analysis Contribution
    Moderate: requires one of: three Category 1, one Category 2, one Category 3<br/>
    High: requires two of: three Category 1, one Category 2, one Category 3
  </Text>

  <br/>
  <Text as="div" align="left" fontSize="12" fontWeight="normal">
          
  </Text>

  <table style={{ width: '250px', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={{ width:'125px', textAlign:'left' }}>
          Risk</th>
        
          <th style={{ width:'125px', textAlign:'center' }}>Likelihood Score</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ width:'125px', textAlign:'left' }}>Moderate</td>
        <td style={{ width:'125px', textAlign:'center' }}>{riskData.rawdata.moderate['likelihood score']}</td>
      </tr>
      <tr>
        <td style={{ width:'125px', textAlign:'left' }}>High</td>
        <td style={{ width:'125px', textAlign:'center' }}>{riskData.rawdata.high['likelihood score']}</td>
      </tr>
    </tbody>
  </table>
      </>
  );
};



  useEffect(() => {
  }, [expandedCPTSections]);

  useEffect(() => {
    if (selectedKey === null || !NeditorRef.current) return;

    const highlightDetails = formatDetailsList[selectedKey];
    const quill = NeditorRef.current.getEditor();
    const isHighlighted = highlightStates[selectedKey] || false;

    for (const detail of highlightDetails) {
      const { start, end, color, textDecoration, fontStyle } = detail;

      if (isHighlighted) {
        // Remove highlight
        quill.removeFormat(start, end - start);
      } else {
        // Apply highlight
        quill.formatText(start, end - start, {
          background: color,
          textDecoration: textDecoration,
          fontStyle: fontStyle,
        });
      }
    }

    // Update the highlight state for the uniqueKey
    setHighlightStates({ ...highlightStates, [selectedKey]: !isHighlighted });
  }, [selectedKey, clickCount]);

  const handleTextClick = (uniqueKey, formatDetailsList, e) => {
    e.stopPropagation();
    setSelectedKey(uniqueKey);
    setClickCount(prevClickCount => prevClickCount + 1);
  };

  // END HIGHLIGHTING CODE

  //first check the url for an id parameter
  let noteText = "";


  const [viewParam, setViewParam] = useState(null);

  useEffect(() => {
    if (loading || !accessToken) return;
    const urlParams = new URLSearchParams(location.search);
    const noteIdParam = urlParams.get('id');
    const versionIdParam = urlParams.get('version');
    const runIdParam = urlParams.get('run');
    const viewParam = urlParams.get('view');
    const nidParam = urlParams.get('nid');
    setViewParam(viewParam);


    // If there are noteId and versionId parameters, handle them as before
    handleNoteButtonClick(NeditorRef, navigate, noteIdParam, versionIdParam, runIdParam, nidParam, viewParam);
  }, [socket, accessToken, loading]);

  
  let sNote = noteText.replace(/\\n/g, "<br/>");
  //same line as above but replace all new lines with <br/>
  sNote = sNote.replace(/(\r\n|\n|\r)/gm, "<br/>");
  sNote = noteText;

  const NeditorRef = useRef(null);
  const [NeditorText, setNeditorText] = useState(sNote);
  const[rawNoteText, setRawNoteText] = useState(noteText);

  const [noteId, setNoteId] = useState(null);
  const [versionId, setVersionId] = useState(null);
  const [nId, setNId] = useState(null);
  const [view, setView] = useState(null);
  const [noteContent, setNoteContent] = useState(null);
  const [zCodes, setZCodes] = useState(null);

  const NhandleEditorChange = (value) => {
    setNeditorText(value);
  }

  const NeditorRef2 = useRef(null);
  const [NeditorText2, setNeditorText2] = useState(sNote);
  const NhandleEditorChange2 = (value) => {
    setNeditorText2(value);
  }



  const handleAnalyzeButtonClick = async (NeditorRef, noteId, versionId, nId) => {
    const editor = NeditorRef.current.getEditor();
    let data = { value: editor.getText() };
    setHighlightStates({});
    let element = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        running analysis... <br />
        <span className="spinner"></span>
      </div>
    );
    setLegendResultsContent(element);
    setPatientData({
      data: null,
      status: "in progress",
    });
    setDiscussionData({
      data: null,
      status: "in progress",
    });
    setProblemsData({
      data: null,
      status: "in progress",
    });
    setIntroData({
      data: null,
      status: "in progress",
    });
    setAnalysisData({
      data: null,
      status: "in progress",
    });
    setRiskData({
      data: null,
      status: "in progress",
    });
    setScoreData({
      data: null,
      status: "in progress",
    });
    
    setCPTProcedureData({
      data: null,
      status: "in progress",
    });

    setSelectedData(null);
    let defaualtDiagnosticGrid = 
    (
        <div key="ICD10 interim">
      <Grid textColor={"black"} templateRows="repeat(5, 1fr)" templateColumns="repeat(1, 1fr)" gap={2}>
      <GridItem bg="gray.100" borderRadius={3} boxShadow="md" padding={2}>
        <Text fontSize="md" fontWeight="normal" align="left">
          Diagnosis Pending
        </Text>
      </GridItem>
      <GridItem bg="gray.100" borderRadius={3} boxShadow="md" padding={2}>
        <Text fontSize="md" fontWeight="normal" align="left">
          Diagnosis Pending
        </Text>
      </GridItem>
      <GridItem bg="gray.100" borderRadius={3} boxShadow="md" padding={2}>
        <Text fontSize="md" fontWeight="normal" align="left">
          Diagnosis Pending
        </Text>
      </GridItem>
      </Grid>
      </div>
    );
    
    setDiagnosisGrid(defaualtDiagnosticGrid);
    setDiagnosisData({
      data: "",
      status: "in progress",
    });
    setICD10Data({
      data: null,
      status: "in progress",
    });

  
    let fullProgressArray = [];
    setProgressResultsContent([]);
    
    const fetchAnalyze = async (noteId, versionId, noteContent, nId) => {
      //check to see if socket exists, and if so wait for it to be set

      try {

        let startTime = new Date().getTime();
        let response = await callApi("/analyze", accessToken, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            note: editor.getText(),
            note_id: noteId,
            version_id: versionId,
            nid: nId,
            processing_speed: "fast",
            socket_id: socket.id, // Pass the socket ID to the backend
          }),
        });
        let endTime = new Date().getTime();
        let timeSpent = endTime - startTime;
        //store time spent in the response object
        let responseJson = await response.json();
        //pretty print all of the response
        console.log(JSON.stringify(responseJson, null, 2));
        responseJson.CPTtimeSpent = timeSpent;
        return responseJson;
      } catch (error) {
      }
    };
  
    const fetchNoteVersion = async (noteId, versionId, noteText, nId = "") => {
        //quill or something seems to add a newline even when you clear it out
          try {
            let noteText = editor.getText().replace(/\n$/, '');
              const response = await callApi('/noteversion', accessToken, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                  note_id: noteId, 
                  version_id: versionId, 
                  note: noteText,
                  nid: nId,
                }),
              });
          
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();

              return data;
        } catch (error) {
          throw error;
        }
    };
    
    
    
    const fetchData = async () => {

      try {
        //let's assume we have a valid note id and version id
        console.log("fetchnoteversion: nid: " + nId);
        const updatedNoteVersion = await fetchNoteVersion(noteId, versionId, noteText, nId);
        nId = updatedNoteVersion.nid;
        setNId(nId);
        noteId = updatedNoteVersion.note_id;
        versionId = updatedNoteVersion.version_id;
        setResetChat(noteId+"-"+versionId);
        setVersionId(updatedNoteVersion.version_id);
        setNoteId(updatedNoteVersion.note_id);
        if (nId === null) {
          navigate(`/note/?id=${updatedNoteVersion.note_id}&version=${updatedNoteVersion.version_id}&nid=${nId}&view=${view}`, { replace: true });
          await getAndDisplayNoteVersions(NeditorRef, navigate, noteId, nId);
        }
        const [results, results2] = await Promise.all([
          fetchAnalyze(noteId, versionId, noteContent, nId),
        ]);
        props.onDataReceived(results);
      } catch (error) {
      }
    }

    fetchData();


  };
  
  const getAndDisplayNoteVersions = async (NeditorRef, navigate, noteId) => {
    let responseNoteVersions = await callApi('/getversions', accessToken, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({note_id: noteId}),
    });
    let noteVersions = await responseNoteVersions.json();
    //sent text for version 1 to "Original"
    noteVersions[0].diff = "Original";

    let output = (
      <>
      {noteVersions.map(version => {
        return (
          <table key={version.version_id} style={{width: '100%', marginBottom: '10px', borderBottom: '1px solid #ddd'}}>
            <tbody>
              <tr>
                <td style={{width: '30%', textAlign: 'center', verticalAlign: 'middle', paddingBottom: '5px', paddingTop: '5px'}}>
                <a
                  href={`/note/?id=${noteId}&version=${version.version_id}`}
                  style={{color: 'blue', textDecoration: 'underline'}}
                  onClick={(event) => {
                    event.preventDefault();
                    handleNoteButtonClick(NeditorRef, navigate, noteId, version.version_id, "", "", "");
                  }}
                >
                  Version {version.version_id}
                </a>
                </td>
                <td style={{width: '70%', paddingBottom: '5px', paddingTop: '5px', wordWrap: 'break-word'}}>
                  <div className="indented">
                    <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word', margin: 0}}>{version.date}</pre>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        );
      })}
      </>
    );
    setNoteVersionsContent(output);
  };


  const handleCreateNoteButtonClick = async (NeditorRef, navigate, newNoteText = null, useSampleNote = 1) => {
    try {
      // Call the /createnote endpoint
      let response = null;
      if (newNoteText != null) {
        response = await callApi('/createnote', accessToken, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({use_sample_note: useSampleNote,
                                note: newNoteText,
        })
        });
      }
      else {
        response = await callApi('/createnote', accessToken, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
                    use_sample_note: useSampleNote,
          }),
        });
      }
      let results = await response.json();
  
      // Update the editor with the new note and set the note_id and version_id
      const editor = NeditorRef.current.getEditor();
      let note = results.note;
      if (note == "") {
        note = "Type your note here (replace this text), then press Analyze to see results. Or, click Try our Sample Note to see a sample note.";
      }
      
      let noteId = results.note_id;
      let versionId = results.version_id;
      setVersionId(versionId);
      setNoteId(noteId);
      navigate("/note/?id=" + noteId + "&version=" + versionId, { replace: true });
      editor.setContents([]);
      editor.root.innerHTML = note;
      await getAndDisplayNoteVersions(NeditorRef, navigate, noteId);
      if (newNoteText) {  //we'll automatically analyze the note if it was generated
        handleNoteButtonClick(NeditorRef, navigate, noteId, versionId, "","","");
      }
    } catch (error) {
    }
  };

  const handleNoteButtonClick = async (NeditorRef, navigate, note_id, version_id, run_id="", nid="", view="") => {
    const editor = NeditorRef.current.getEditor();
    let data = {value: editor.getText()};
    if (!note_id || !version_id) {
      //get them from the URL
      const urlParams = new URLSearchParams(window.location.search);
      nid = urlParams.get('nid');
      view = urlParams.get('view');
      note_id = urlParams.get('id');
      version_id = urlParams.get('version');  
      run_id = urlParams.get('run');
      if (!note_id || !version_id) {
        let notes = await handleNoteList(accessToken);
        if (notes.length > 0) {
          note_id = notes[0].note_id;
          version_id = notes[0].version_id;
        }
      }
    }
    try {
        let response = await callApi('/getnote', accessToken, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({note: "getnote", 
                                  note_id: note_id,
                                  version_id: version_id,
                                  run_id: run_id,
                                  nid: nid,
                                }),
        });
        let results = await response.json();

        if (results.error_code === 'no_note_found' || results.error_code === 'no_note_id') {
          return;
        }

        let note = results.note;
        let noteId = results.note_id;
        let versionId = results.version_id;
        let nId = results.nid;
        setVersionId(versionId);
        setView(view);
        setNId(nid);
        setNoteContent(note);
        setNoteId(noteId);
        navigate("/note/?id="+noteId+"&version="+versionId+"&nid="+nid+"&view="+view, { replace: true });
        editor.setContents([]);
        editor.root.innerHTML = note;
        if (nid === null) {
          let responseNoteVersions = await callApi('/getversions', accessToken, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({note_id: noteId}),
          });
          //await getAndDisplayNoteVersions(NeditorRef, navigate, noteId);    
        }
        //handleAnalyzeButtonClick(NeditorRef, noteId, versionId, nId);
      } catch (error) {
      }
  };

  let results = props.results;
  const [resultsContent, setresultsContent] = useState('Initial content');

  useEffect(() => {
    let results = props.results;
    if (!results) {
      return;
    }

    if (!results.note) {
      return;
    }
    let value = JSON.stringify(results.note, null, 2);
  
    value = value.replace(/\\n/g, "<br>");
    value = value.substring(1, value.length-1);
    let legend = "";
    let medication_count = 0;
    let problem_count = 0;
    let color = "";
    let colors = ['#bde8ff','#ffbde8','#e8ffbd','#ffe8bd','#e8bdff','#ffb8b8','#85ff85','#72ffe6','#56b5ff','	#d889ff'];
    let analysisList = "";
    let emAnalysisCatArray = {};
    let element = "";

    emAnalysisCatArray["1"] = [];
    emAnalysisCatArray["2"] = [];
    emAnalysisCatArray["3"] = [];
    let emAnalysisCatCountArray = {};


    if (NeditorRef.current) {
      const quill = NeditorRef.current.getEditor();
      quill.setContents([]);
      quill.clipboard.dangerouslyPasteHTML(value);
      let legendArray = [];
      let formatDetailsList = {};

      
      if (results.medications && Object.keys(results.medications).length > 0) {
        color = colors.shift();
        for (let t in results.medications) {
          let start = results.medications[t]['start'];
          let end = results.medications[t]['end'];
          let length = end - start;
          //quill.setSelection(start, length);
          //quill.format('background', color);
        }
        medication_count = Object.keys(results.medications).length;
      }
      else {
        medication_count = 0;
      }
      

      legend = "";

      let icd_10_legend = "";
      
      if (icd_10_legend === "") {
        //icd_10_legend = 'No ICD-10 evidence or diagnoses found';
      }

      if (typeof results.ICD10.model_response_array === 'undefined') {
        //do nothing
      }
      else {
        let modelResponseArray = results.ICD10.model_response_array;
        let modelResponseLegend = "";
        modelResponseLegend += '<details><summary>Details</summary><span>';
        for (let i in modelResponseArray) {
          if (i === 'note_id') {
            continue;
          }
          let modelResponseItem = modelResponseArray[i];
          let modelResponse = modelResponseItem.model_response;
          if (typeof modelResponse === 'object') {
            modelResponse = JSON.stringify(modelResponse);
            modelResponse = modelResponse.replace(/\\n/g, '<br>');
          }
          let promptName = modelResponseItem.prompt_name;
          let promptText = modelResponseItem.prompt_text;
        
          if (promptName === 'ICD10 code validation') {
            let firstLine = modelResponse.split('\n')[0];
            promptName += ': ' + firstLine;
          }

          modelResponseLegend += '<div class="indented"><details><summary>'+promptName+'</summary><span>';
        
          let formattedModelResponse = modelResponse.replace(/(\r\n|\n)/g, '<br>');
          formattedModelResponse = formattedModelResponse.replace(/(\r|\n)/g, '<br>');
        
          modelResponseLegend += '<div class="indented2">' + formattedModelResponse + '</div>';
        
          modelResponseLegend += '<div class="indented2"><details><summary>Prompt Text</summary><span></div>';
          let formattedPromptText = promptText.replace(/(\r\n|\n)/g, '<br>');
          formattedPromptText = formattedPromptText.replace(/(\r|\n)/g, '<br>');
          modelResponseLegend += '<div class="indented2">' + formattedPromptText + '</div>';
          modelResponseLegend += '</span></details>';
        
          modelResponseLegend += '</span></details></div>';
        }
        modelResponseLegend += '</span></details>';

        if (typeof results.CPT.model_tracker === 'undefined') {
          //do nothing
        }
        else {
          //replace newlines with <br>
          let modelTracker = results.CPT.model_tracker.replace(/(\r\n|\n)/g, '<br/>');
          modelResponseLegend += '<details><summary>' + "CPT" + '</summary><span>';
          modelResponseLegend += '<div class="indented"><b>Model Tracker</b><br/> ' + modelTracker + '</div>';
          modelResponseLegend += '</span></details>';
        }
        
              
        let element = (
          <div
            key = "model_response"
            dangerouslySetInnerHTML={{ __html: modelResponseLegend }}
          />
        );
        legendArray.push(element);
      
      }

      setLegendResultsContent(legendArray);
      setresultsContent(legend);
    }
    else {
      setLegendResultsContent();
      setresultsContent();

    }
  }, [results]);

  const { isAuthenticated, user } = useAuth0();

if (viewParam && viewParam === "short") {
  return (
<>
<Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          SOAP
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel>

    <Box margin={1}  bg="white"  borderRadius={5} marginBottom={3}>
    <div style={{ whiteSpace: 'pre-wrap' }}>
        <ReactMarkdown>{noteContent}</ReactMarkdown>
      </div>
      <div style={{ width: '0', height: '0', overflow: 'hidden', visibility: 'hidden' }}>
        <ReactQuill
          id="Neditor"
          modules={modules}
          options={options}
          ref={NeditorRef}
          style={{ ...editorStyles, minHeight: '300px' }}
          value={NeditorText}
          onChange={NhandleEditorChange}
        />
      </div>
    </Box>
      {/* Note content goes here */}
      </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
  <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          ICD-10
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel>

      <Box bg="white" textColor={"black"} padding={3} borderRadius={5} marginBottom={3}>

        {icd10Data.data}
        <br/>
      </Box>
    </AccordionPanel>
    {/* ICD-10 header and content */}
    </AccordionItem>

<AccordionItem>
<h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          CPT
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel>

      <Box bg="white" textColor={"black"} padding={3} borderRadius={5} marginBottom={3}>
        {/* CPT section */}

            <Text as="div" fontSize="sm" fontWeight="normal" align="left">
              CPT E/M
              {scoreData.status === "initial"
                ? ""
                : scoreData.status === "in progress"
                ?<Spinner size="xs" marginLeft="8px" />
                : scoreData.data}
            </Text>

            <Text fontSize="sm" fontWeight="normal" align="left">
              Risk{" "}
              {riskData.status === "initial" ? (
                <span></span>
              ) : riskData.status === "in progress" ? (
                <Spinner size="xs" marginLeft="8px" />        
              ) : (
                <Button
                  style={{
                    textAlign: 'left',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    maxHeight: '600px',
                    overflowY: 'scroll',
                    whiteSpace: 'pre-wrap',
                  }}
                  colorScheme="blue"
                  variant="link"
                  onClick={() => {
                    setExpandedCPTSections(prevState => ({
                      ...prevState,
                      risk: !prevState.risk
                    }));
                  }}
                >
                  Details
                </Button>
              )}
            </Text>

            {expandedCPTSections.risk && (
              <div
              className="selectedData"
              style={{
                textAlign: 'left',
                fontSize: '12px',
                fontWeight: 'normal',
                whiteSpace: 'pre-wrap',
                paddingLeft: '20px', 
              }}
            >{riskData.data}</div>
            )}

<Text  as="div" fontSize="sm" fontWeight="normal" align="left">
  Analysis{" "}
  {analysisData.status === "initial" ? (
    <span></span>
  ) : analysisData.status === "in progress" ? (
    <Spinner size="xs" marginLeft="8px" />        
  ) : (
    <Button
      style={{
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 'normal',
        maxHeight: '600px',
        overflowY: 'scroll',
        whiteSpace: 'pre-wrap',
      }}
      colorScheme="blue"
      variant="link"
      onClick={() => {
        setExpandedCPTSections(prevState => ({
          ...prevState,
          analysis: !prevState.analysis
        }));
      }}
    >
      Details
    </Button>
  )}
</Text>

{expandedCPTSections.analysis && (
  <div
    className="selectedData"
    style={{
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      paddingLeft: '20px', 
    }}
  >{analysisData.data}</div>
)}

<Text  as="div"  fontSize="sm" fontWeight="normal" align="left">
  Problems{" "}
  {problemsData.status === "initial" ? (
    <span></span>
  ) : problemsData.status === "in progress" ? (
    <Spinner size="xs" marginLeft="8px" />        
  ) : (
    <Button
      style={{
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 'normal',
        maxHeight: '600px',
        overflowY: 'scroll',
        whiteSpace: 'pre-wrap',
      }}
      colorScheme="blue"
      variant="link"
      onClick={() => {
        setExpandedCPTSections(prevState => ({
          ...prevState,
          problems: !prevState.problems
        }));
      }}
    >
      Details
    </Button>
  )}
</Text>

{expandedCPTSections.problems && (
  <div
    className="selectedData"
    style={{
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      paddingLeft: '20px', 
    }}
  >{problemsData.data}</div>
)}

<Text  as="div" fontSize="sm" fontWeight="normal" align="left">
  Discussion{" "}
  {discussionData.status === "initial" ? (
    <span></span>
  ) : discussionData.status === "in progress" ? (
    <></>        
  ) : (
    <Button
      style={{
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 'normal',
        maxHeight: '600px',
        overflowY: 'scroll',
        whiteSpace: 'pre-wrap',
      }}
      colorScheme="blue"
      variant="link"
      onClick={() => {
        setExpandedCPTSections(prevState => ({
          ...prevState,
          discussion: !prevState.discussion
        }));
      }}
    >
      Details
    </Button>
  )}
</Text>

{expandedCPTSections.discussion && (
  <div
    className="selectedData"
    style={{
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      paddingLeft: '20px', 
    }}
  >{discussionData.data}</div>
)}
        
            <Text  as="div"  fontSize="sm" textColor={"black"} fontWeight="normal" align="left">
              CPT Procedures
              {cptProcedureData.status === "initial" ? (
                <span></span>
              ) : cptProcedureData.status === "in progress" ? (
                <Spinner size="xs" marginLeft="8px" />
                ) : (
                <>
                  {cptProcedureData.data}
                </>
              )}
            </Text>
        
      </Box>
  {/* CPT header and content */}
  </AccordionPanel>
  </AccordionItem>

<AccordionItem>
<h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          Patient
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel>


    <Box
      bg='white'
      textColor={"black"}
      border='0px'
      borderColor='brand.200'
      padding={3}
      borderRadius={5}
    >
      <div
        className="progress"
        style={{
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: 'normal',
          maxHeight: '600px',
          overflowY: 'scroll',
          whiteSpace: 'pre-wrap',
        }}
      >

        {patientData.status === "initial" ? (
          <span></span>
        ) : patientData.status === "in progress" ? (
          <Spinner size="xs" marginLeft="8px" />        
          ) : (
          <>
            <ReactMarkdown>{patientData.rawcontent}</ReactMarkdown>
          </>
        )}
      </div>
    </Box>
      {/* Patient header and content */}
    </AccordionPanel>
</AccordionItem>
</Accordion>

    </>
  );
  }
  else {
  return (

    <Box textAlign="center" fontSize="xl" >
  <Grid
    templateAreas={`"header header"    
                    "main results"
                    "footer footer"`}
    gridTemplateRows={'60px 1fr 60px'}
    gridTemplateColumns={'1fr 1fr'}
    h='100%'
    gap='2'
    fontWeight='bold'
  >
                {/* Header with menu */}
                    <HeaderMenus />



    <GridItem bg='gray.50' padding={4} area={'main'}>

    {progressResultsContent  && (
      <Box
        bg='white'
        border='0px'  
        borderColor='white'
        padding={3}
        borderRadius={5}
        marginBottom={3}
        maxHeight={"100px"}
      >
        <div

          className="progress"
          style={{
            textAlign: 'left',
            fontSize: '12px',
            fontWeight: 'normal',
            maxHeight: '600px',
            overflowY: 'scroll',
            whiteSpace: 'pre-wrap',
          }}
        >
          {progressResultsContent}
        </div>
      </Box>
    )}

<Flex justifyContent="space-between" paddingBottom={2}>
  <Box>
       <NoteServiceList NeditorRef={NeditorRef} navigate={navigate} handleNoteButtonClick={handleNoteButtonClick} handleCreateNoteButtonClick={handleCreateNoteButtonClick} />    
  </Box>
  <Box>

          <Text
            fontSize="sm"
            color="blue.500"
            marginLeft={4}
            onClick={() => handleCreateNoteButtonClick(NeditorRef, navigate, null,0)}
            _hover={{ textDecoration: "underline" }}
          >
        Add Note
          </Text>

  </Box>

<Box>
    <Text
      fontSize="sm"
      color="blue.500"
      onClick={() => handleAnalyzeButtonClick(NeditorRef, noteId, versionId, nId)}
      _hover={{ textDecoration: "underline" }}
    >
      Analyze
    </Text>
  </Box>

</Flex>

    <Box
      bg='white'
      textColor={"black"}
      border='0px'
      borderColor='brand.200'
      padding={3}
      borderRadius={5}
      marginBottom={3}
    >
      <div>
        <ReactQuill
          id="Neditor"
          modules={modules}
          options={options}
          ref={NeditorRef}
          style={{ ...editorStyles, minHeight: '300px' }}
          value={NeditorText}
          onChange={NhandleEditorChange}
        />
      </div>
    </Box>

    
    <Box
      bg='white'
      border='0px'
      borderColor='white'
      padding={3}
      borderRadius={5}
    >
      <div
        className="progress"
        style={{
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: 'normal',
          maxHeight: '600px',
          overflowY: 'scroll',
          whiteSpace: 'pre-wrap',
        }}
      >

      <Text as="div" fontSize="md" fontWeight="normal" align="left">
            {noteVersionsContent}
      </Text>
      </div>
    </Box>

  </GridItem>
  <GridItem
    bg='white'
    padding={4}
    area={'results'}
  >


<Grid templateRows="repeat(2, auto)" templateColumns="repeat(2, 1fr)" gap={2}>

{icd10Data.data && (
  <GridItem bg="white" textColor="black" borderRadius={3} padding={2} colSpan={2}>

  <div>

<Box minHeight={"250px"} maxHeight={"500px"}>
  <Text align="right">
    {noteId}-{versionId}
  </Text>


  {noteId && versionId && resetChat && (
            <Chat 
              chatOnly={true} 
              contextType ={'revedy_note'} 
              contextPersona={'revedy_coder'}
              contextNoteID={noteId}
              contextVersionID={versionId}
              contextData={'revedy_note'}
              placeHolder={'Ask questions about the coding here!'}
              contextRouting={true}
              //resetChat={resetChat}
              contextID={resetChat}
              maintainContext={true}
              >
              </Chat> 
  )}
</Box>
</div>


  <Tabs>
  <TabList>
    <Tab>ICD-10</Tab>
    <Tab>CPT</Tab>
    <Tab>Patient</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>



      <Box bg="white" textColor={"black"} padding={3} borderRadius={5} marginBottom={3}>
      {zCodes && false && (
        <div>
          <Text fontSize="md" fontWeight="normal" align="left">
            Z Codes
          </Text>
          {Object.entries(zCodes.codes).map(([code, description]) => (
            <div key={code} style={{ marginBottom: '8px' }}>
              <Text fontSize="sm" fontWeight="bold" align="left">
                {code}
              </Text>
              <Text fontSize="sm" fontWeight="normal" align="left">
                {description}
              </Text>
            </div>
          ))}
        </div>
      )}


        {icd10Data.data}
        <br/>
      </Box>
    </TabPanel>
    <TabPanel>
          <Box bg="white" textColor={"black"} padding={3} borderRadius={5} marginBottom={3}>
        {/* CPT section */}

        <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(1, 1fr)" gap={2}>
          <GridItem bg="white" fontSize="12" borderRadius={3} boxShadow="md" padding={2}>
            <Text as="div" fontSize="sm" fontWeight="normal" align="left">
              CPT E/M
              {scoreData.status === "initial"
                ? ""
                : scoreData.status === "in progress"
                ?<Spinner size="xs" marginLeft="8px" />
                : scoreData.data}
            </Text>


            {analysisData.status === "initial" || problemsData.status === "initial" || riskData.status === "initial" ? (
                <span></span>
              ) : analysisData.status === "in progress" || problemsData.status === "in progress" || riskData.status === "in progress" ? (
                <Spinner size="xs" marginLeft="8px" />
              ) : (  <div
                className="selectedData"
                style={{
                  textAlign: 'left',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  whiteSpace: 'pre-wrap',
                  paddingLeft: '0px', 
                }}
              >
                <ScoringTable analysisData={analysisData} problemsData={problemsData} riskData={riskData} />
              </div>
            )}



            <Text fontSize="sm" fontWeight="normal" align="left">
              Risk{" "}
              {riskData.status === "initial" ? (
                <span></span>
              ) : riskData.status === "in progress" ? (
                <Spinner size="xs" marginLeft="8px" />        
              ) : (
                <Button
                  style={{
                    textAlign: 'left',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    maxHeight: '600px',
                    overflowY: 'scroll',
                    whiteSpace: 'pre-wrap',
                  }}
                  colorScheme="blue"
                  variant="link"
                  onClick={() => {
                    setExpandedCPTSections(prevState => ({
                      ...prevState,
                      risk: !prevState.risk
                    }));
                  }}
                >
                  Details
                </Button>
              )}
            </Text>

            {expandedCPTSections.risk && (
              <div
              className="selectedData"
              style={{
                textAlign: 'left',
                fontSize: '12px',
                fontWeight: 'normal',
                whiteSpace: 'pre-wrap',
                paddingLeft: '20px', 
              }}
            >{riskData.data}</div>
            )}







<Text  as="div" fontSize="sm" fontWeight="normal" align="left">
  Analysis{" "}
  {analysisData.status === "initial" ? (
    <span></span>
  ) : analysisData.status === "in progress" ? (
    <Spinner size="xs" marginLeft="8px" />        
  ) : (
    <Button
      style={{
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 'normal',
        maxHeight: '600px',
        overflowY: 'scroll',
        whiteSpace: 'pre-wrap',
      }}
      colorScheme="blue"
      variant="link"
      onClick={() => {
        setExpandedCPTSections(prevState => ({
          ...prevState,
          analysis: !prevState.analysis
        }));
      }}
    >
      Details
    </Button>
  )}
</Text>

{expandedCPTSections.analysis && (
  <div
    className="selectedData"
    style={{
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      paddingLeft: '20px', 
    }}
  >{analysisData.data}</div>
)}

<Text  as="div"  fontSize="sm" fontWeight="normal" align="left">
  Problems{" "}
  {problemsData.status === "initial" ? (
    <span></span>
  ) : problemsData.status === "in progress" ? (
    <Spinner size="xs" marginLeft="8px" />        
  ) : (
    <Button
      style={{
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 'normal',
        maxHeight: '600px',
        overflowY: 'scroll',
        whiteSpace: 'pre-wrap',
      }}
      colorScheme="blue"
      variant="link"
      onClick={() => {
        setExpandedCPTSections(prevState => ({
          ...prevState,
          problems: !prevState.problems
        }));
      }}
    >
      Details
    </Button>
  )}
</Text>

{expandedCPTSections.problems && (
  <div
    className="selectedData"
    style={{
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      paddingLeft: '20px', 
    }}
  >{problemsData.data}</div>
)}

<Text  as="div" fontSize="sm" fontWeight="normal" align="left">
  Discussion{" "}
  {discussionData.status === "initial" ? (
    <span></span>
  ) : discussionData.status === "in progress" ? (
    <></>        
  ) : (
    <Button
      style={{
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 'normal',
        maxHeight: '600px',
        overflowY: 'scroll',
        whiteSpace: 'pre-wrap',
      }}
      colorScheme="blue"
      variant="link"
      onClick={() => {
        setExpandedCPTSections(prevState => ({
          ...prevState,
          discussion: !prevState.discussion
        }));
      }}
    >
      Details
    </Button>
  )}
</Text>

{expandedCPTSections.discussion && (
  <div
    className="selectedData"
    style={{
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
      paddingLeft: '20px', 
    }}
  >{discussionData.data}</div>
)}
          </GridItem>
          <GridItem bg="gray.100" borderRadius={3} boxShadow="md" padding={2}>
            <Text  as="div"  fontSize="sm" textColor={"black"} fontWeight="normal" align="left">
              CPT Procedures
              {cptProcedureData.status === "initial" ? (
                <span></span>
              ) : cptProcedureData.status === "in progress" ? (
                <></>
                ) : (
                <>
                  {cptProcedureData.data}
                </>
              )}
            </Text>
          </GridItem>
        </Grid>
      </Box>

    </TabPanel>
    <TabPanel>
    <Flex direction="row" justify="left" mb={3}>
  <Box fontSize="sm">
    <Select fontSize={"sm"} placeholder="Reading Level" variant="unstyled" cursor="text" borderColor="transparent" color="black">
      <option value="beginner">Reading level: 5th grade</option>
      <option value="intermediate">Reading level: 8th grade</option>
      <option value="advanced">Reading level: 10th grade</option>
    </Select>
  </Box>
  <Box fontSize="sm">
    <Select fontSize={"sm"}  placeholder="Detail" variant="unstyled" cursor="text" borderColor="transparent" color="black">
      <option value="low">Low level of detail</option>
      <option value="medium">Medium level of detail</option>
      <option value="high">High level of detail</option>
    </Select>
  </Box>
</Flex>

    <Box
      bg='white'
      textColor={"black"}
      border='0px'
      borderColor='brand.200'
      padding={3}
      borderRadius={5}
    >
      <div
        className="progress"
        style={{
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: 'normal',
          maxHeight: '600px',
          overflowY: 'scroll',
          whiteSpace: 'pre-wrap',
        }}
      >

      <Text  as="div" fontSize="sm" fontWeight="normal" align="left">
        {patientData.status === "initial" ? (
          <span></span>
        ) : patientData.status === "in progress" ? (
          <></>
          ) : (
          <>
            {patientData.data}
          </>
        )}
      </Text>
      </div>
    </Box>
    </TabPanel>



  </TabPanels>
</Tabs>

</GridItem>
)}
{!icd10Data.data && noteId && (
    <GridItem bg="white" textColor="black" borderRadius={3} padding={2} colSpan={2}>
      <Text fontSize="sm" color="3e81c3" textAlign="left" marginLeft={2} verticalAlign={"center"}>
        <br/>
        <br/>
        Coding results will appear here.
      </Text>
    </GridItem>
)
}

</Grid>

  </GridItem>


  <GridItem
  bg='white' // Change the background color to white
  borderTop='1px'
  borderColor='brand.200'
  paddingY={4} // Increase the vertical padding
  paddingX={6}
  area={'footer'}
>
  <Flex justifyContent="center" alignItems="center">
    <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" /> {/* Add your logo */}
    <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
      &copy; {new Date().getFullYear()}
    </Text>
  </Flex>
</GridItem>

</Grid>
<div 
    bg='white'
    textcolor={"black"}
    border='1px'
>
<ChatToggle
            contextType={'revedy'}
            contextData={'revedy_automated_coding'}
            contextPersona={'revedy_automated_coding'}
            firstMessage={"Hi, what can I do on this page?"}
            useOptions={false}
            maintainContext={true}
            contextMode={'popup'}
            messageToSend={messageToSend}
            triggerSubmit={triggerSubmit}
        />
</div>
</Box>
  );
  }
}


export { TextEntry };

