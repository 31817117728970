import React, { useState, useEffect, useRef, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
//import { useApi } from "../api";
import { callApi } from '../callApi'; 
import { useAccessToken } from '../useAccessToken';
import { AppContext } from './AppContext';
import { throttle } from 'lodash'; // or use a custom throttle function
import { useCallback } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";

import { VStack, Box, Button, Input, Textarea, Grid, GridItem, Flex, Text, 
    AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, 
    AlertDialogContent, AlertDialogOverlay, IconButton, 
    FormControl, FormLabel    } from "@chakra-ui/react";
    import { ArrowForwardIcon } from "@chakra-ui/icons";
    import { FaRegEye } from 'react-icons/fa';

import HeaderMenus from './HeaderMenus'; // Assuming you have this component
import { renderProblems,
    renderCPTProcedure, 
    renderMdmProblems, 
    renderAnalysis, 
    renderRisk, 
    renderCPTScore,
    renderDiscussion,
    renderICD10,
    renderICD10Grid,
    renderPatient,
    connectWebSocket } from '../utils';


function Chat({ chatOnly = false, contextType = null, contextData = null, contextPersona = null, 
        contextCaseID = null, chatOptions = null, useOptions = false, maintainContext = false,
        firstMessage = null, 
        contextNoteID = null, 
        contextVersionID = null,
        contextMode = 'popup',
        placeHolder = 'Type any question here!',
        contextRouting = null,
        triggerSubmit = false,
        messageToSend = null,
        resetChat = null,
        contextID = null,
    }) {
    //const { callApi } = useApi();
    const { accessToken, loading } = useContext(AppContext);

    const [chatHistory, setChatHistory] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [chatId, setChatId] = useState(null);
    const [templateId, setTemplateId] = useState(null);
    const [templateName, setTemplateName] = useState(null);
    const [hiddenMessages, setHiddenMessages] = useState([]);
    const [modelFamily, setModelFamily] = useState("enhanced"); // Set default to "enhanced" for Sonnet 3.7
    const [templateData, setTemplateData] = useState({
        system_message: '',
        temperature: 0.10,
        top_p: 0.10,
        max_length: 1000,
        other: ''
    });
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const onClose = () => setIsAlertOpen(false);
    const cancelRef = useRef();
    const [chatToDelete, setChatToDelete] = useState(null);
    const [chatStreamData, setChatStreamData] = useState({ data: [], status: 'incomplete' });

    const [editingChatId, setEditingChatId] = useState(null);
    const [newName, setNewName] = useState('');
    const [templateList, setTemplateList] = useState([]);
    const [showUpdateForm, setShowUpdateForm] = useState(false);

    const [showOptions, setShowOptions] = useState(true);
    const [optionData, setOptionData] = useState(chatOptions);
    
    const messagesContainerRef = useRef(null);
    const firstMessageSubmittedRef = useRef(false);
    const userMessageStyle = { marginLeft: 'auto', marginRight: '0', textAlign:'left' }; // Aligns to the right
    const assistantMessageStyle = { marginLeft: '0', marginRight: 'auto' }; // Aligns to the left
    const userMessageWidth = '95%'
    const assistantMessageWidth = '100%'

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');    
    const [expandedMessageId, setExpandedMessageId] = useState(null);

    //if useOptions is true, but chatOptions is null, then make a call to the backend to get the options
    //call get_chat_options
    useEffect(() => {
        //console.log("checking for use options ", useOptions);
        //console.log("checking for chat options ", chatOptions);
        //console.log("checking for chat id ", chatId);
        const fetchChatOptions = async () => {
            if (useOptions && !chatOptions && !chatId) {
                const response = await callApi('/get_chat_options', accessToken,  {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ chat_id: chatId })
                });
                const data = await response.json();
                //console.log("chat options data: ", data);
                setOptionData(data.response.options);
                setShowOptions(true);
            }
        };
        fetchChatOptions();
    }, [chatId, chatOptions, useOptions]);

    

    const fetchTemplateList = async () => {
        const response = await callApi(`/chat_template_list`, accessToken, { method: 'GET' });
        const data = await response.json();
        setTemplateList(data);
        
        // If no template is selected yet, try to set "Software Engineer" as default
        if (!templateId) {
            const softwareEngineerTemplate = findSoftwareEngineerTemplate(data);
            if (softwareEngineerTemplate) {
                setTemplateId(softwareEngineerTemplate.id);
                setTemplateName(softwareEngineerTemplate.name);
            }
        }
    };


    const handleNameChange = (id, name) => {
        setEditingChatId(id);
        setNewName(name);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
        if (diffDays === 1) {
            return 'today';
        } else {
            return `${diffDays} days ago`;
        }
    };

    const handleNameBlur = async () => {
        if (editingChatId) {
            // Save the changes to the server
            const response = await callApi(`/chats`, accessToken,  { 
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, 
                body: JSON.stringify({ 
                    id: editingChatId, 
                    name: newName 
                }) 
            });
            const data = await response.json();
    
            // Handle the response data as needed
            //console.log("update chat name response: ", data);
            setChatList(chatList.map(chat => chat.id === editingChatId ? { ...chat, name: newName } : chat));

            setEditingChatId(null);
            setNewName('');
        }
    };

    useEffect(() => {
        if (!loading && accessToken)
        {
            fetchChatList();
            fetchTemplateList();
        }
    }, [chatId, templateId, accessToken, loading]);

    const fetchChatList = async () => {
        console.log("fetching chat list", accessToken);
        const response = await callApi('/chat_list', accessToken,  { method: 'GET' });
        //console.log("chat list response: ", response);
        const data = await response.json();
        setChatList(data.chat_list);
    };

    const hideMessage = async (chatMessageId) => {
        setHiddenMessages([...hiddenMessages, chatMessageId]);
    
        // Send a request to the server to set 'display' to False
        const response = await callApi(`/hide_message`,  accessToken, { 
            method: 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
            display: false,
            chat_message_id: chatMessageId
         }) 
        });
        const data = await response.json();
    
        // Handle the response data as needed
        //console.log("hide message response: ", data);
    };


    const hideTemplate = async (templateId) => {
        // Send a request to the server to hide the template
        const response = await callApi(`/hide_template`, accessToken,  { 
            method: 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                display: false,
                template_id: templateId
            }) 
        });
        const data = await response.json();
    
        // Handle the response data as needed
        //console.log("hide template response: ", data);
    
        // Remove the template from the templates list
        setTemplateList(templateList.filter(template => template.id !== templateId));
    };

    const hideChat = async (chatId) => {
        // Send a request to the server to hide the chat
        const response = await callApi(`/hide_chat`,  accessToken, { 
            method: 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                display: false,
                chat_id: chatId
            }) 
        });
        const data = await response.json();
    
        // Handle the response data as needed
        //console.log("hide chat response: ", data);
    
        // Remove the chat from the chat list
        setChatList(chatList.filter(chat => chat.id !== chatId));
        setChatHistory([]);
    };


    const fetchChatHistory = async (chatId) => {
        const response = await callApi('/chat_history', accessToken,  {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ chat_id: chatId })
        });
        const data = await response.json();
        setChatId(data.chat_id);
        //console.log("chat history data: ", data);
        //console.log("chat id: ", chatId);
        setChatHistory(data.chat_history);
    };

    const handleNewMessageChange = (event) => {
        event.preventDefault();
        setNewMessage(event.target.value);
    };


    const endOfMessagesRef = useRef(null);

    const scrollToBottom = () => {
        const scrollHeight = messagesContainerRef.current.scrollHeight;
        const height = messagesContainerRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        messagesContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    const truncateMessage = (message, maxLength = 5000) => {
        if (message.length <= maxLength * 2) return message; // No need to truncate if message is short enough
        
        const start = message.slice(0, maxLength);
        const end = message.slice(-maxLength);
        return `${start}...TRUNCATED...${end}`;
      };

    const throttledScrollToBottom = useCallback(
        throttle(() => {
          if (messagesContainerRef.current) {
            const scrollHeight = messagesContainerRef.current.scrollHeight;
            const height = messagesContainerRef.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            messagesContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
          }
        }, 500), // Adjust the interval (in milliseconds) as needed
        [messagesContainerRef]
      );

      useEffect(() => {
        throttledScrollToBottom();
      }, [chatHistory, chatStreamData, throttledScrollToBottom]);
    
    //useEffect(scrollToBottom, [chatHistory, chatStreamData]);
    

    // Add this effect after the existing useEffect hooks
    useEffect(() => {
            // Log the dependency values to see which ones are changing
        console.log("useEffect triggered");
        console.log("maintainContext:", maintainContext);
        console.log("contextPersona:", contextPersona);
        console.log("chatId:", chatId);
        console.log("context id: ", contextID);
        console.log("context note id: ", contextNoteID);
        if (loading || !accessToken) return;
        const fetchRecentChatId = async () => {
            if (maintainContext && (contextPersona || contextID) && !chatId) {
                try {
                    const response = await callApi('/get_recent_chatid', accessToken, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            persona: contextPersona,
                            context_id: contextID,
                         })
                    });
                    const data = await response.json();
                    if (data && data.chat_id) {
                        setChatId(data.chat_id);
                        // Fetch chat history for the new chatId
                        //console.log("fetching chat history for chat id: ", data.chat_id);
                        //console.log("setChatId: ", chatId);
                        fetchChatHistory(data.chat_id);
                    }
                    else {
                        //console.log("no recent chat id found");
                        //let's create a new chat if there is a first message
                        if (!firstMessageSubmittedRef.current && firstMessage && firstMessage.trim() && !chatId) {
                            firstMessageSubmittedRef.current = true; // Mark as submitted
                            await handleNewMessageSubmit(firstMessage);
                            //console.log("first message submitted");
                        }                                    
                    }
                } catch (error) {
                    console.error('Error fetching recent chat ID', error);
                }
            }
        };
        fetchRecentChatId();
    }, [maintainContext, contextPersona, chatId, accessToken, loading]);

    const MemoizedMarkdown = React.memo(({ children }) => (
        <ReactMarkdown>{children}</ReactMarkdown>
      ));


    const handleResetChat = async () => {
        if (chatId) {
            try {
                // Call the backend to hide the current chat
                const response = await callApi(`/hide_chat`, accessToken, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        display: false,
                        chat_id: chatId
                    })
                });
                const data = await response.json();
                //console.log("hide chat response: ", data);
    
                // Clear the local state
                setChatId(null);
                setChatHistory([]);
                setOptionData([]);
                setShowOptions(false);
                
    
                // Optionally, fetch the updated chat list
                fetchChatList();
            } catch (error) {
                console.error('Error resetting chat', error);
            }
        }
    };

    useEffect(() => {
        //console.log("reset chat trigger: ", resetChat);
        if (resetChat) {
            handleResetChat();
        }
    }, [resetChat]);

    useEffect(() => {
        //console.log("trigger submit: ", triggerSubmit);
        if (triggerSubmit) {
            handleNewMessageSubmit(messageToSend);
        }
    }, [triggerSubmit]);


    const handleNewMessageSubmit = async (message) => {
        // Use the message parameter directly instead of relying on newMessage state
        if (!message.trim()) return;
        setShowOptions(false);
        
        // Don't clear the message yet - store it temporarily
        const messageToSend = message;
    
        let templateIdLocal = null;
    

        if (!templateId && templateList.length > 0 && !contextPersona) {
            // Try to find Software Engineer template first
            const softwareEngineerTemplate = findSoftwareEngineerTemplate(templateList);
            
            if (softwareEngineerTemplate) {
                setTemplateId(softwareEngineerTemplate.id);
                setTemplateName(softwareEngineerTemplate.name);
                templateIdLocal = softwareEngineerTemplate.id;
            } else {
                // Fall back to first template if Software Engineer doesn't exist
                setTemplateId(templateList[0].id);
                setTemplateName(templateList[0].name);
                templateIdLocal = templateList[0].id;
            }
        }
        else if (!templateId && !contextPersona) {
            alert("Please create a persona first.");
            return;
        }
        else if (contextPersona) {
            templateIdLocal = "";
        }
        else {
            templateIdLocal = templateId;
        }
    

        try {
            // Establish WebSocket connection
            const onProgress = (data) => {
              if ('chat_stream' in data) {
                setChatStreamData({
                  data: data.chat_stream,
                  status: "complete",
                });
              }
        
              if (data.status === 'complete' || data.status === 'error') {
                socket.disconnect();
              }
            };
            const socket = await connectWebSocket(onProgress);
    
            const response = await callApi('/consume_chat_message', accessToken, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_message: messageToSend, 
                    chat_id: chatId,
                    template_id: templateIdLocal,
                    context_type: contextType,
                    context_data: contextData,
                    context_persona: contextPersona,
                    socket_id : socket.id,
                    context_case_id: contextCaseID,
                    context_note_id: contextNoteID,
                    context_version_id: contextVersionID,
                    get_options: useOptions,
                    context_routing: contextRouting,
                    context_id: contextID,
                    model_family: modelFamily,
                })
            });
            
            const data = await response.json();
    
            // Only clear the message if the API call was successful
            setNewMessage('');
    
            if (useOptions) {
                setOptionData(data.response.options);
                setShowOptions(true);
            }
    
            let chatIdLocal = data.chat_id;
            await fetchChatHistory(chatIdLocal);
            setChatStreamData({
                data: "",
                status: "closed",
            });
        } catch (error) {
            console.error('Error sending chat message', error);
            // Don't clear the message if there was an error
            // Maybe show an error message to the user
            alert("There was an error sending your message. Please try again.");
        }
    }

    const findSoftwareEngineerTemplate = (templates) => {
        return templates.find(template => template.name.toLowerCase() === "software engineer");
      };
      


    
    const handleNewChat = async () => {
        setChatId(null);
        setChatHistory([]); // Clear the chat history
    };

    const handleTemplateDataChange = (event) => {
        setTemplateData({ ...templateData, [event.target.name]: event.target.value });
    }

    const handleNewTemplateSubmit = async (event) => {
        event.preventDefault();
        if (templateData.temperature === '') {
            setTemplateData({
                system_message: 'hello',
                temperature: .1,
                top_p: .1,
                max_length: 1000,
                name: 'Template',
                other: ''
            });
        }
        //console.log("templateData: ", templateData);
        const response = await callApi('/create_chat_template', accessToken, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(templateData)
        });
        const data = await response.json();
        setTemplateId(data.template_id); // Store the returned templateId in the state
        setTemplateName(data.template_name);
        //console.log("chat template data: ", data);
    };

    const handleUpdateTemplateSubmit = async (event, templateId) => {
        event.preventDefault();
        //console.log("templateData: ", templateData);
        const response = await callApi(`/update_chat_template/${templateData.id}`, accessToken, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(templateData)
        });
        const data = await response.json();
        setTemplateId(data.template_id); // Store the returned templateId in the state
        setTemplateName(data.template_name);
        //console.log("updated chat template data: ", data);
    };
    
    if (chatOnly) {
        return (
            <Box textAlign="left" fontSize="xl"
            alignItems={"flex-start"}
            >
                <VStack spacing={0} align='left'>
                    <Box         
                        ref={messagesContainerRef}
                        bg='white' padding={0}  style={{ maxHeight: '350px', maxWidth: '800px', overflowY: 'scroll' 
                    }}>

{chatHistory.map((message, index) => (
    message && message.actor && !hiddenMessages.includes(message.id) ? (
        <Box
            width={message.actor === 'user' ? userMessageWidth : assistantMessageWidth}
            key={index}
            borderWidth="1px"
            borderRadius="md"
            padding="1"
            marginY="2"
            style={message.actor === 'user' ? userMessageStyle : assistantMessageStyle}
        >
            <Flex justifyContent="space-between"> 
            {message.actor === 'assistant' ? (<Text as="span" fontWeight="bold">Revedy</Text>) : null}
            </Flex>
                
            <Box marginLeft="4px" className="markdown-content">

                <MemoizedMarkdown>{message.message}</MemoizedMarkdown>

            </Box>

        </Box>
    ) : null
))}

                        {chatStreamData.status === "in progress" || chatStreamData.status === "closed" ? (
                                    null
                            ) : chatStreamData.status === "complete" ? (
                                <>
                                <Box align="left" borderWidth="1px" borderRadius="md" padding="1" marginY="2" style={assistantMessageStyle} width="95%">
                                    <Flex justifyContent="space-between"> 
                                    </Flex>
                                    <Box marginLeft="20px" className="markdown-content">
                                    <Text as="span" fontWeight="bold">Revedy</Text>
                                        <ReactMarkdown>
                                        {chatStreamData.data}
                                        </ReactMarkdown>
                                    </Box>
{/*
                                    <Text as="span" display="block" marginLeft="20px" style={{ whiteSpace: 'pre-wrap' }}>
                                    {chatStreamData.data}
                                    </Text>
*/}
                                </Box>

                                <br/>
                                </>
                            ) : 
                            null
                            }
                        <div ref={endOfMessagesRef} />

                    </Box>
                    <Box bg='white' paddingBottom={8} padding={0}  style={{ maxHeight: '90px', maxWidth: '800px', width: '100%' }}>


                        <Box as="form" onSubmit={(event) => {
                            event.preventDefault();
                            handleNewMessageSubmit(newMessage);
                        }} marginBottom="4">

                            {/* Container that looks like a box wrapping both options and textarea */}
                            <Box border="0px" borderColor="gray.200" borderRadius="md" padding="0" width="95%"
                                style={userMessageStyle} 
                            >
    {/* Render chat options as vertically stacked buttons */}
    <div align="left" >
        <Textarea 
            placeholder={placeHolder}
            value={newMessage} 
            onChange={handleNewMessageChange} 
            height="50px"
            onKeyDown={(event) => {
                if ( event.key === 'Enter') {
                    handleNewMessageSubmit(newMessage);
                }
            }}
            style={{ flex: 1 }} // This allows the textarea to grow while leaving space for the button
        />
<Button
  type="submit"
  width="auto"
  height="auto" // Make buttons shorter
  padding={contextMode === 'popup' ? '4px 10px' : '8px 16px'} // Further reduced padding for 'popup'

  style={{
    fontSize: contextMode === 'popup' ? '0.8rem' : '1rem', // Smaller font size for 'popup'
    padding: contextMode === 'popup' ? '6px 12px' : '8px 16px', // Smaller padding for 'popup'
  }}
>
  Send
</Button>
        </div>

    {showOptions && optionData && optionData.length > 0 && (
        <VStack spacing={2} marginBottom="2" align="left"> {/* Align items to the left */}
                <Text fontSize="sm" marginBottom="2" align="left">
                </Text>
            {optionData.map((option, index) => (
                <Button
                    key={index}
                    height="auto" // Make buttons shorter
                    padding={contextMode === 'popup' ? '4px 10px' : '8px 16px'} // Further reduced padding for 'popup'
                    onClick={() => handleNewMessageSubmit(option)}
                    width="auto" // Make buttons narrower
                    boxShadow="sm" // Add a small drop shadow
                    fontSize="sm" // Make text smaller
                    fontWeight="normal" // Make text not bold
                    alignSelf="flex-start" // Align button to the left
                >
                    {option}
                </Button>
            ))}
        </VStack>
    )}
    {maintainContext && (
        <VStack spacing={contextMode === 'popup' ? 0 : 1} marginBottom="2" align="left">
<Button
  colorScheme="blue"
  fontSize={contextMode === 'popup' ? '0.8rem' : 'sm'}
  fontWeight="normal"
  width="auto"
  alignSelf="flex-end"
  padding={contextMode === 'popup' ? '4px 10px' : '8px 16px'} // Further reduced padding for 'popup'
  height={contextMode === 'popup' ? 'auto' : '10'} // Adjust height for 'popup'
  lineHeight={contextMode === 'popup' ? '1' : 'normal'} // Adjust line height for 'popup'
  minHeight={contextMode === 'popup' ? '12px' : 'auto'} // Adjust min height for 'popup'
  border={contextMode === 'popup' ? '1px solid' : '2px solid'} // Adjust border for 'popup'
  style={{ boxSizing: 'border-box' }} // Ensure padding and border are included in total height
  onClick={handleResetChat}
>
  New chat
</Button>
</VStack>
    )}
</Box>
    </Box>


                    </Box>
                </VStack>
            </Box>

    );
    }

    return (
        <Box textAlign="center" fontSize="xl">
            <Grid
                templateAreas={`"header header"    
                                "form main"
                                "footer footer"`}
                gridTemplateRows={'60px 1fr 60px'}
                gridTemplateColumns={'1fr 3fr'}
                h='100%'
                gap='2'
                color='blackAlpha.800'
                fontWeight='bold'
            >
              
                    <HeaderMenus />

                <GridItem bg='white' padding={4} area={'form'}>
                <br/>


                <Box>
                            <Text textAlign="left" fontWeight={"bold"} fontSize="sm">
                                Assistants
                            </Text>
                            <Box key="new persona">

                                    <Flex justifyContent="space-between"> 
                                    <Text
                                        fontSize="sm"
                                        color="blue.500"
                                        onClick={() => setIsFormVisible(!isFormVisible)}
                                        _hover={{ textDecoration: "underline" }}
                                    >
                                        Create New Assistant
                                    </Text>
                                    <Text as="span" color="gray.500" fontSize="sm" cursor="pointer" >
                                    </Text>
                                </Flex>
                            </Box>
                            {templateList.map((template, index) => (
                                <Box key={index}  backgroundColor={template.id === isUpdateFormVisible ? "lightgray" : "white"}>
                                    <Flex justifyContent="space-between"> 
                                        <Text as="span" fontWeight="bold" 

                                            onClick={() => { 
                                                setTemplateId(template.id); 
                                                setTemplateName(template.name); 
                                                setIsUpdateFormVisible(template.id);
                                                setTemplateData(template);
                                            }}
                                            cursor="pointer">{template.name}
                                        </Text>
                                        <Text as="span" color="gray.500" fontSize="7px" cursor="pointer" 
                                        onClick={() => hideTemplate(template.id)}
                                        >X</Text>
                                    </Flex>
                                </Box>
                            ))}
                        </Box>
                        <Box marginBottom={4} marginRight={4}>
    
</Box>

{isFormVisible && (
    <form onSubmit={handleNewTemplateSubmit}>
    <FormControl id="name">
        <FormLabel fontSize="sm">Name</FormLabel>
        <Input name="name" value={templateData.name} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="system_message">
        <FormLabel fontSize="sm">Persona Description</FormLabel>
        <Textarea name="system_message" value={templateData.system_message} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="temperature">
        <FormLabel fontSize="sm">Temperature (0-1, 0 for consistency, 1 for creativity)</FormLabel>
        <Input name="temperature" value={templateData.temperature} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="top_p">
        <FormLabel fontSize="sm">Top P</FormLabel>
        <Input name="top_p" value={templateData.top_p} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="max_length">
        <FormLabel fontSize="sm">Max Response Length</FormLabel>
        <Input name="max_length" value={templateData.max_length} onChange={handleTemplateDataChange} />
    </FormControl>
    <Button type="submit">Create</Button>
</form>
)}

{isUpdateFormVisible && !showUpdateForm && (
    <Button variant="link" fontSize="sm" onClick={() => setShowUpdateForm(true)}>Edit {templateData.name}</Button>
        )}

{isUpdateFormVisible && showUpdateForm && (
    
              
    <form onSubmit={handleUpdateTemplateSubmit}>
    <Button variant="link" fontSize="sm" onClick={() => setShowUpdateForm(false)}>Hide form</Button>

    <FormControl id="name">
        <FormLabel fontSize="sm">Name</FormLabel>
        <Input name="name" value={templateData.name} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="system_message">
        <FormLabel fontSize="sm">Persona Description</FormLabel>
        <Textarea name="system_message" value={templateData.system_message} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="temperature">
        <FormLabel fontSize="sm">Temperature (0-1, 0 for consistency, 1 for creativity)</FormLabel>
        <Input name="temperature" value={templateData.temperature} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="top_p">
        <FormLabel fontSize="sm">Top P</FormLabel>
        <Input name="top_p" value={templateData.top_p} onChange={handleTemplateDataChange} />
    </FormControl>
    <FormControl id="max_length">
        <FormLabel fontSize="sm">Max Response Length</FormLabel>
        <Input name="max_length" value={templateData.max_length} onChange={handleTemplateDataChange} />
    </FormControl>
    <Button type="submit">Update</Button>
</form>
)}


<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl">
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Full Message</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <MemoizedMarkdown>{modalContent}</MemoizedMarkdown>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={() => setIsModalOpen(false)}>
        Close
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>



<Box align="left">
    <Text textAlign="left" fontWeight="bold" fontSize="sm">
        Model Family
    </Text>
    <Flex justifyContent="space-between">
        <Flex flex="5" justifyContent="center" alignItems="center">
            <select
                value={modelFamily}
                onChange={(e) => setModelFamily(e.target.value)}
                style={{
                    width: '100%',
                    margin: '2px',
                    fontSize: 'sm',
                    color: 'blue.500',
                }}
            >
                <option value="" disabled>Select a model family</option>
                {/* Assuming you have a list of model families */}
                <option value="enhanced">Sonnet 3.7</option>
                <option value="standard">Gemini Pro 1.5</option>
                <option value="beta">OpenAI o1</option>
                <option value="o3m">OpenAI o3</option>
                <option value="new">DeepSeek r1</option>
                <option value="fast">OpenAI 4o</option>
                {/* Add additional options here */}
            </select>
        </Flex>
        <Flex flex="2" justifyContent="center" alignItems="center">
            {/* Additional UI elements or spacing */}
        </Flex>
        <Flex flex="0" justifyContent="right" alignItems="center">
            {/* Additional UI elements or spacing */}
        </Flex>
    </Flex>
</Box>

                    <Box>
                    
                        
                        <Text textAlign="left" fontWeight={"bold"} fontSize="sm">
                            Conversations
                        </Text>

                            <Box align="left" key="new chat" >
                                <Flex justifyContent="space-between">                                    
                                    <Flex flex="5" justifyContent="center" alignItems="center">
                                    <Text
                                        variant="unstyled"
                                        width="100%"
                                        margin="2px"
                                        fontSize="sm"
                                        color="blue.500"
                                        onClick={handleNewChat}
                                        _hover={{ textDecoration: "underline" }}
                                    >
                                        New Conversation
                                    </Text>
                                        
                                    </Flex>
                                    <Flex flex="2" justifyContent="center" alignItems="center">
                                        <Text align="left">
                                        </Text>
                                    </Flex>
                                    <Flex flex="0" justifyContent="right" alignItems="center">
                                        <Text >
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Box>

                            {chatList.map((chat, index) => (
                                <Box align="left" key={index} backgroundColor={chat.id === chatId ? "lightgray" : "white"} >
                                    <Flex justifyContent="space-between">
                                        <Flex flex="5" justifyContent="center" alignItems="center">
                                            <Input 
                                                value={chat.id === editingChatId ? newName : chat.name} 
                                                onChange={(e) => handleNameChange(chat.id, e.target.value)} 
                                                onBlur={handleNameBlur}
                                                variant="unstyled"
                                                width="100%"
                                                fontSize={"sm"}
                                                margin="2px"
                                                onClick={() => { fetchChatHistory(chat.id); setNewMessage(''); }}

                                            />
                                        </Flex>
                                        <Flex flex="2" justifyContent="center" alignItems="center">
                                            <Text align="left">
                                                {formatDate(chat.created_at)}
                                            </Text>
                                        </Flex>
                                        <Flex flex="0" justifyContent="right" alignItems="center">
                                            <Text 
                                                fontSize="7px" 
                                                cursor="pointer" 
                                                onClick={() => { setChatToDelete(chat); setIsAlertOpen(true); }}
                                            >
                                                X
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Box>
                            ))}
                            <AlertDialog
                                isOpen={isAlertOpen}
                                leastDestructiveRef={cancelRef}
                                onClose={onClose}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                            Delete Chat
                                        </AlertDialogHeader>

                                        <AlertDialogBody>
                                            Are you sure? This will permanently delete the chat.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme="red" onClick={() => { hideChat(chatToDelete.id); setChatToDelete(null); onClose(); }} ml={3}>
                                                Delete
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
</Box>
<br/>
<br/>


                </GridItem>

                <VStack spacing={0}>
                    <GridItem         
                        ref={messagesContainerRef}
                        bg='white' padding={4} area={'main'} style={{ maxHeight: '600px', maxWidth: '1000px', overflowY: 'scroll' 
                    }}>
                        <Flex align="left">
                            <Text>Persona: {templateName}</Text>
                        </Flex>





                        {chatHistory.map((message, index) => (
                            message && message.actor && !hiddenMessages.includes(message.id) ? (
                                <Box align="left" key={index} borderWidth="1px" borderRadius="md" padding="1" marginY="2">
                                <Flex justifyContent="space-between"> 
                                    <Text as="span" fontWeight="bold">{message.actor}</Text>
                                    <Text as="span" color="gray.500" fontSize="sm" cursor="pointer" onClick={() => hideMessage(message.id)}>X</Text>
                                </Flex>
                                <Box marginLeft="20px" className="markdown-content">
                                    {expandedMessageId === message.id ? (
                                    <MemoizedMarkdown>{message.message}</MemoizedMarkdown>
                                    ) : (
                                    <>
                                        <MemoizedMarkdown>{truncateMessage(message.message)}</MemoizedMarkdown>
                                        {message.message.length > 10000 && (
    
                                        <Button size="sm" onClick={() => {
                                            setModalContent(message.message);
                                            setIsModalOpen(true);
                                        }}>View All</Button>


                                        )}
                                    </>
                                    )}
                                </Box>
                                </Box>
                            ) : null
                            ))}







                        {chatStreamData.status === "in progress" || chatStreamData.status === "closed" ? (
                                    null
                            ) : chatStreamData.status === "complete" ? (
                                <>
                                <Box align="left" borderWidth="1px" borderRadius="md" padding="1" marginY="2">
                                    <Flex justifyContent="space-between"> 
                                        <Text as="span" fontWeight="bold" >user</Text>
                                    </Flex>
                                    <Text as="span" display="block" marginLeft="20px" style={{ whiteSpace: 'pre-wrap' }}>
                                    {newMessage}
                                    </Text>
                                </Box>


                                <Box align="left" borderWidth="1px" borderRadius="md" padding="1" marginY="2">
                                    <Flex justifyContent="space-between"> 
                                        <Text as="span" fontWeight="bold" >assistant</Text>
                                    </Flex>
                                    <Text as="span" display="block" marginLeft="20px" style={{ whiteSpace: 'pre-wrap' }}>
                                    {chatStreamData.data}
                                    </Text>
                                </Box>

                                <br/>
                                </>
                            ) : 
                            null
                            }
                        <div ref={endOfMessagesRef} />

                    </GridItem>
                    <GridItem bg='white' paddingBottom={8} padding={4} area={'main'} style={{ maxHeight: '200px', maxWidth: '1000px', width: '100%' }}>
                        <Box as="form" onSubmit={(event) => {
                            event.preventDefault();
                            handleNewMessageSubmit(newMessage);
                        }} marginBottom="4">

                            <Textarea 
                                placeholder="New message" 
                                value={newMessage} 
                                onChange={handleNewMessageChange} 
                                height="200px"
                                onKeyDown={(event) => {
                                    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                                        handleNewMessageSubmit(newMessage);
                                    }
                                }}
                            />
                            <Button type="submit">Send</Button>
                        </Box>
                    </GridItem>
                </VStack>

                <GridItem
                    bg='white'
                    borderTop='10px'
                    borderColor='brand.200'
                    paddingY={4}
                    paddingX={6}
                    area={'footer'}
                >
                    {/* Footer content */}
                </GridItem>
            </Grid>
        </Box>
    );
}

export default Chat;