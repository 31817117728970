import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { callApi } from '../callApi';
import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs, TabList, TabPanel, TabPanels, Spinner, Heading, Divider, 
 } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';

function AnalyticsPage() {
    const { accessToken } = useContext(AppContext);
    const [statusHistory, setStatusHistory] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const getStatusHistory = useCallback(async () => {
        const response = await callApi('/status_history', accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },            
        });
        const data = await response.json();
        setStatusHistory(data);
    }, [callApi, accessToken]);

    useEffect(() => {
        getStatusHistory();
    }, [getStatusHistory]);

    // Sort function
    const sortedData = useMemo(() => {
        if (!statusHistory) return [];
        let sortableItems = [...statusHistory];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [statusHistory, sortConfig]);

    // Search function
    const filteredData = useMemo(() => {
        return sortedData.filter(item =>
            Object.values(item).some(val =>
                val.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [sortedData, searchTerm]);

    // Pagination
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        return filteredData.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredData, currentPage]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <Box textAlign="center" fontSize="xl">
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'60px auto 1fr 60px'}
                gridTemplateColumns={'1fr 1fr'}
                h='100%'
                gap='2'
                color='blackAlpha.800'
                fontSize='12px'
            >
                <HeaderMenus />
                <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>
                    <Tabs>
                        <TabList>
                            <Tab>Integrations</Tab>
                            {/* Other tabs... */}
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex justifyContent="space-between" mb={4}>
                                    <Button onClick={getStatusHistory}>Refresh</Button>
                                    <Input
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        width="200px"
                                    />
                                </Flex>
                                {statusHistory && (
                                    <>
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr>
                                                    <Th onClick={() => requestSort('message')}>Message</Th>
                                                    <Th onClick={() => requestSort('type')}>Type</Th>
                                                    <Th onClick={() => requestSort('timestamp')}>Timestamp</Th>
                                                    <Th>Data</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {paginatedData.map((statusMessage, index) => (
                                                    <Tr key={index}>
                                                        <Td>{statusMessage.message}</Td>
                                                        <Td>{statusMessage.type}</Td>
                                                        <Td>{new Date(statusMessage.timestamp).toLocaleString()}</Td>
                                                        <Td>
                                                            {Array.isArray(statusMessage.data) 
                                                                ? statusMessage.data.map((item, index) => (
                                                                    <div key={index}>{item}</div>
                                                                ))
                                                                : statusMessage.data
                                                            }
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                        <Flex justifyContent="center" mt={4}>
                                            <Button 
                                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                                isDisabled={currentPage === 1}
                                            >
                                                Previous
                                            </Button>
                                            <Text mx={2}>Page {currentPage} of {totalPages}</Text>
                                            <Button 
                                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                                isDisabled={currentPage === totalPages}
                                            >
                                                Next
                                            </Button>
                                        </Flex>
                                    </>
                                )}
                            </TabPanel>
                            {/* Other tab panels... */}
                        </TabPanels>
                    </Tabs>
                </GridItem>
            </Grid>
            <ChatToggle
                contextType={'revedy'}
                contextData={'revedy_integration_services'}
                contextPersona={'revedy_integration_services'}
                firstMessage={"Hi, what can I do on this page?"}
                useOptions={false}
                maintainContext={true}
                contextMode={'popup'}
            />
        </Box>
    );
}

export default AnalyticsPage;