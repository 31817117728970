import React from 'react';
import {
  Box, Grid, GridItem, Heading, Text, Button, VStack, HStack, Icon, Link,
  Divider, List, ListItem, ListIcon, Code, Container, SimpleGrid, Card, CardHeader, CardBody
} from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';
import { 
  FiExternalLink, FiCode, FiFileText, FiActivity, FiUpload, 
  FiServer, FiCheckCircle, FiArrowRight, FiDatabase, FiLayers
} from 'react-icons/fi';

function IONMDocumentation() {
  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={8} area={'main'}>
          <Container maxW="container.xl">
            <VStack spacing={10} align="stretch">
              <Box textAlign="center">
                <Heading as="h1" size="xl" mb={4} color="blue.600">
                  IONM Documentation
                </Heading>
                <Text fontSize="lg" color="gray.600" maxW="800px" mx="auto">
                  This guide explains how to use our IONM (Intraoperative Neurophysiological Monitoring) 
                  coding solutions through various interfaces.
                </Text>
              </Box>

              <Box>
                <Heading as="h2" size="lg" mb={4} color="blue.500">
                  IONM Coding Options
                </Heading>
                <Text mb={6}>
                  We offer two primary methods for IONM coding, depending on whether you have Cadwell JSON files available:
                </Text>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                  <Card variant="outline" borderWidth="1px" borderRadius="lg" boxShadow="md">
                    <CardHeader bg="blue.50" borderTopRadius="lg">
                      <HStack>
                        <Icon as={FiDatabase} boxSize={6} color="blue.500" />
                        <Heading size="md">With Cadwell JSON Files</Heading>
                      </HStack>
                    </CardHeader>
                    <CardBody>
                      <VStack align="start" spacing={4}>
                        <Text>
                          This method provides the most accurate and detailed coding by utilizing the structured data 
                          from Cadwell monitoring systems.
                        </Text>
                        
                        <Box bg="gray.50" p={4} borderRadius="md" w="full">
                          <Heading as="h4" size="sm" mb={2}>How to try it:</Heading>
                          <List spacing={2}>
                            <ListItem>
                              <ListIcon as={FiArrowRight} color="blue.500" />
                              Go to <Code>/external-case-ionm</Code>
                            </ListItem>
                            <ListItem>
                              <ListIcon as={FiUpload} color="blue.500" />
                              Upload your Cadwell JSON files and other case documentation
                            </ListItem>
                            <ListItem>
                              <ListIcon as={FiCheckCircle} color="blue.500" />
                              Click "Code Case" to generate results
                            </ListItem>
                          </List>
                        </Box>
                        
                        <Button 
                          as={Link} 
                          href="/external-case-ionm" 
                          colorScheme="blue" 
                          rightIcon={<FiExternalLink />}
                          _hover={{ textDecoration: 'none' }}
                        >
                          Go to Cadwell IONM Coder
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>

                  <Card variant="outline" borderWidth="1px" borderRadius="lg" boxShadow="md">
                    <CardHeader bg="green.50" borderTopRadius="lg">
                      <HStack>
                        <Icon as={FiLayers} boxSize={6} color="green.500" />
                        <Heading size="md">Without Cadwell JSON Files</Heading>
                      </HStack>
                    </CardHeader>
                    <CardBody>
                      <VStack align="start" spacing={4}>
                        <Text>
                          This method works with any IONM case documentation, including PDFs, text files, 
                          and other formats from any monitoring system.
                        </Text>
                        
                        <Box bg="gray.50" p={4} borderRadius="md" w="full">
                          <Heading as="h4" size="sm" mb={2}>How to try it:</Heading>
                          <List spacing={2}>
                            <ListItem>
                              <ListIcon as={FiArrowRight} color="green.500" />
                              Go to <Code>/full-cpt-coder</Code>
                            </ListItem>
                            <ListItem>
                              <ListIcon as={FiUpload} color="green.500" />
                              Upload all case files (reports, notes, etc.)
                            </ListItem>
                            <ListItem>
                              <ListIcon as={FiCheckCircle} color="green.500" />
                              Click "Code Case" to generate results
                            </ListItem>
                          </List>
                        </Box>
                        
                        <Button 
                          as={Link} 
                          href="/full-cpt-coder" 
                          colorScheme="green" 
                          rightIcon={<FiExternalLink />}
                          _hover={{ textDecoration: 'none' }}
                        >
                          Go to Full CPT Coder
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                </SimpleGrid>
              </Box>

              <Divider />

              <Box>
                <Heading as="h2" size="lg" mb={4} color="purple.500">
                  API Integration
                </Heading>
                <Text mb={6}>
                  Both IONM coding methods are fully supported through our API, with batch processing capabilities 
                  for high-volume integration.
                </Text>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                  <Card variant="outline" borderWidth="1px" borderRadius="lg" boxShadow="md">
                    <CardHeader bg="purple.50" borderTopRadius="lg">
                      <HStack>
                        <Icon as={FiFileText} boxSize={6} color="purple.500" />
                        <Heading size="md">API Documentation</Heading>
                      </HStack>
                    </CardHeader>
                    <CardBody>
                      <VStack align="start" spacing={4}>
                        <Text>
                          Comprehensive documentation of our IONM coding API endpoints, request formats, 
                          response structures, and integration guidelines.
                        </Text>
                        
                        <Button 
                          as={Link} 
                          href="/apiionm" 
                          colorScheme="purple" 
                          rightIcon={<FiExternalLink />}
                          _hover={{ textDecoration: 'none' }}
                        >
                          View API Documentation
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>

                  <Card variant="outline" borderWidth="1px" borderRadius="lg" boxShadow="md">
                    <CardHeader bg="orange.50" borderTopRadius="lg">
                      <HStack>
                        <Icon as={FiServer} boxSize={6} color="orange.500" />
                        <Heading size="md">API Demo</Heading>
                      </HStack>
                    </CardHeader>
                    <CardBody>
                      <VStack align="start" spacing={4}>
                        <Text>
                          Interactive playground to test API calls, experiment with different request parameters, 
                          and see example responses.
                        </Text>
                        
                        <Button 
                          as={Link} 
                          href="/apiionmdemo" 
                          colorScheme="orange" 
                          rightIcon={<FiExternalLink />}
                          _hover={{ textDecoration: 'none' }}
                        >
                          Try API Demo
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                </SimpleGrid>
              </Box>

              <Box bg="blue.50" p={6} borderRadius="lg" mt={4}>
                <HStack spacing={4} align="start">
                  <Icon as={FiActivity} boxSize={8} color="blue.500" mt={1} />
                  <VStack align="start" spacing={2}>
                    <Heading as="h3" size="md">Coming Soon</Heading>
                    <Text>
                      We're continuously improving our IONM coding solutions. Check back for updates on:
                    </Text>
                    <List spacing={2}>
                      <ListItem>
                        <ListIcon as={FiCheckCircle} color="blue.500" />
                        Enhanced modality detection
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FiCheckCircle} color="blue.500" />
                        Support for additional monitoring systems
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FiCheckCircle} color="blue.500" />
                        Advanced reporting features
                      </ListItem>
                    </List>
                  </VStack>
                </HStack>
              </Box>

              <Box textAlign="center" p={4}>
                <Text fontSize="md" color="gray.600">
                  For additional support or questions about IONM coding, please contact our support team.
                </Text>
              </Box>
            </VStack>
          </Container>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default IONMDocumentation;