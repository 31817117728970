import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { callApi } from '../callApi';

import {
  ChakraProvider,
  Box,
  VStack,
  Heading,
  Text,
  Button,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useToast,
  Link,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';

const steps = [
  { title: 'User Type', description: 'Select your role' },
  { title: 'Specialty', description: 'Choose your specialty' },
  { title: 'Interests', description: 'Select your interests' },
];

function RevedyWelcome() {
  const [userType, setUserType] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [otherSpecialty, setOtherSpecialty] = useState('');
  const [interests, setInterests] = useState([]);
  const { accessToken } = useContext(AppContext);
  const navigate = useNavigate();
  const toast = useToast();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    if (userType && activeStep === 0) {
      setActiveStep(1);
    }
  }, [userType, activeStep, setActiveStep]);

  useEffect(() => {
    if (specialty && activeStep === 1 && specialty !== 'Other') {
      setActiveStep(2);
    }
  }, [specialty, activeStep, setActiveStep]);

  const handleSubmit = async () => {
    try {
      const onboardingData = {
        userType,
        specialty: specialty === 'Other' ? otherSpecialty : specialty,
        interests,
      };

      const response = await callApi('/save_onboarding', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(onboardingData),
      });

      if (!response.ok) {
        throw new Error('Failed to save onboarding data');
      }

      toast({
        title: "Setup Complete",
        description: "Thank you for providing your information. Your experience has been personalized.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setTimeout(() => navigate('/home'), 3000);
    } catch (error) {
      console.error('Error saving onboarding data:', error);
      toast({
        title: "Error",
        description: "There was an error saving your information. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const renderStepContent = (step) => {
    const buttonSize = isMobile ? "md" : "lg";
    const buttonWidth = isMobile ? "auto" : "auto";

    switch (step) {
      case 0:
        return (
          <VStack align="stretch" spacing={4}>
            <Heading size="md">I am a:</Heading>
            <RadioGroup onChange={setUserType} value={userType}>
              <VStack align="stretch" spacing={4}>
                <Button as={Radio} value="Physician" size={buttonSize} width={buttonWidth} variant={userType === "Physician" ? "solid" : "outline"}>Physician or Healthcare System</Button>
                <Button as={Radio} value="RevenueCycle" size={buttonSize} width={buttonWidth} variant={userType === "RevenueCycle" ? "solid" : "outline"}>Revenue Cycle Professional</Button>
                <Button as={Radio} value="Startup" size={buttonSize} width={buttonWidth} variant={userType === "Startup" ? "solid" : "outline"}>Healthcare Startup</Button>
                <Button as={Radio} value="Vendor" size={buttonSize} width={buttonWidth} variant={userType === "Vendor" ? "solid" : "outline"}>Healthcare Vendor</Button>
              </VStack>
            </RadioGroup>
          </VStack>
        );
      case 1:
        return (
          <VStack align="stretch" spacing={4}>
            <Heading size="md">My specialty is:</Heading>
            <RadioGroup onChange={setSpecialty} value={specialty}>
              <VStack align="stretch" spacing={4}>
                <Button as={Radio} value="IONM" size={buttonSize} width={buttonWidth} variant={specialty === "IONM" ? "solid" : "outline"}>IONM</Button>
                <Button as={Radio} value="Ortho" size={buttonSize} width={buttonWidth} variant={specialty === "Ortho" ? "solid" : "outline"}>Ortho</Button>
                <Button as={Radio} value="PrimaryCare" size={buttonSize} width={buttonWidth} variant={specialty === "PrimaryCare" ? "solid" : "outline"}>Primary Care</Button>
                <Button as={Radio} value="Other" size={buttonSize} width={buttonWidth} variant={specialty === "Other" ? "solid" : "outline"}>Other</Button>
              </VStack>
            </RadioGroup>
            {specialty === 'Other' && (
              <Input
                placeholder="Please specify"
                value={otherSpecialty}
                onChange={(e) => setOtherSpecialty(e.target.value)}
                mt={2}
              />
            )}
          </VStack>
        );
      case 2:
        return (
          <VStack align="stretch" spacing={4}>
            <Heading size="md">I'm interested in:</Heading>
            <Text fontSize="sm">(Select all that apply)</Text>
            <CheckboxGroup onChange={setInterests} value={interests}>
              <VStack align="start" spacing={4}>
                <Checkbox value="ReducingCosts">Reducing coding costs</Checkbox>
                <Checkbox value="ImprovingThroughput">Improving throughput</Checkbox>
                <Checkbox value="ImprovingAccuracy">Improving accuracy</Checkbox>
                <Checkbox value="ReducingBurnout">Reducing burnout</Checkbox>
                <Checkbox value="JustCurious">Just curious</Checkbox>
              </VStack>
            </CheckboxGroup>
          </VStack>
        );
      default:
        return null;
    }
  };

  return (
    <ChakraProvider>
      <Box>
        <Box maxWidth={{ base: "100%", md: "800px" }} margin="auto" p={{ base: 4, md: 8 }}>
          <VStack spacing={8}>
            <Heading textAlign="center" fontSize={{ base: "xl", md: "2xl" }}>Revedy Setup (3 Questions)</Heading>
            <Text textAlign="center" fontSize={{ base: "sm", md: "md" }}>
              Help us personalize your experience by answering a few quick questions.
              Your responses will be used to tailor our services to your needs.
            </Text>
            <Card width="100%">
              <CardHeader>
                <Stepper index={activeStep} width="100%" size={isMobile ? "sm" : "md"}>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>
                      {!isMobile && (
                        <Box flexShrink='0'>
                          <StepTitle>{step.title}</StepTitle>
                          <StepDescription>{step.description}</StepDescription>
                        </Box>
                      )}
                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
              </CardHeader>
              <CardBody>
                {renderStepContent(activeStep)}
              </CardBody>
              <CardFooter>
                <Flex width="100%" justifyContent="flex-end">
                  {activeStep === steps.length - 1 ? (
                    <Button onClick={handleSubmit} colorScheme="blue" width={isMobile ? "100%" : "auto"}>
                      Complete Setup
                    </Button>
                  ) : (
                    <Button onClick={() => setActiveStep((prev) => prev + 1)} colorScheme="blue" width={isMobile ? "100%" : "auto"} isDisabled={activeStep === 1 && specialty === 'Other' && !otherSpecialty}>
                      Next
                    </Button>
                  )}
                </Flex>
              </CardFooter>
            </Card>
            <Flex width="100%" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "center" : "flex-start"}>
              <Link onClick={() => navigate('/home')} fontSize="sm" mb={isMobile ? 2 : 0}>
                Skip for now
              </Link>
              <Link href="/privacy-policy" fontSize="sm">
                Privacy Policy
              </Link>
            </Flex>
          </VStack>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default RevedyWelcome;