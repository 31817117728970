import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Text, VStack, Spinner, Divider, Grid, GridItem } from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';

function EncounterDetailPage() {
  const { encounterId } = useParams();
  const [encounterData, setEncounterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { accessToken } = useContext(AppContext);

  useEffect(() => {
    fetchEncounterData();
  }, [encounterId, accessToken]);

  const fetchEncounterData = async () => {
    try {
      const response = await callApi('/get_encounter', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ encounter_id: encounterId }),
      });
      const data = await response.json();
      setEncounterData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching encounter data:', error);
      setError('Failed to fetch encounter data');
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box>
      <HeaderMenus />
      <Box padding={4}>
        <Heading mb={4}>Encounter Details</Heading>
        
        {/* ID Items */}
        <Box bg="gray.100" p={4} borderRadius="md" mb={4}>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            <GridItem>
              <Text fontWeight="bold">Encounter ID:</Text>
              <Text>{encounterData.provider_encounter_id}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">External Patient ID:</Text>
              <Text>{encounterData.external_patient_id}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Encounter Date:</Text>
              <Text>{encounterData.encounter_date}</Text>
            </GridItem>
          </Grid>
        </Box>

        {/* Main Content */}
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem>
            <Box>
              <Text fontWeight="bold" mb={2}>Structured Transcript:</Text>
              <Box bg="gray.50" p={3} borderRadius="md" height="300px" overflowY="auto">
                <Text whiteSpace="pre-wrap">{encounterData.structured_transcript}</Text>
              </Box>
            </Box>
          </GridItem>
          <GridItem>
            <Box>
              <Text fontWeight="bold" mb={2}>Note Content:</Text>
              <Box bg="gray.50" p={3} borderRadius="md" height="300px" overflowY="auto">
                <Text whiteSpace="pre-wrap">{encounterData.note_content}</Text>
              </Box>
            </Box>
          </GridItem>
        </Grid>

        <Box mt={6}>
          <Text fontWeight="bold" mb={2}>Raw Transcript:</Text>
          <Box bg="gray.50" p={3} borderRadius="md" maxHeight="300px" overflowY="auto">
            <Text whiteSpace="pre-wrap">{encounterData.raw_transcript}</Text>
          </Box>
        </Box>

        <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={6}>
          <GridItem>
            <Text fontWeight="bold">Encounter Code:</Text>
            <Text>{encounterData.encounter_code}</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Audio Length (KB):</Text>
            <Text>{encounterData.audio_length_kb}</Text>
          </GridItem>
        </Grid>

        <Box mt={6}>
          <Text fontWeight="bold" mb={2}>Patient Content:</Text>
          <Box bg="gray.50" p={3} borderRadius="md" maxHeight="300px" overflowY="auto">
            <Text whiteSpace="pre-wrap">{encounterData.patient_content}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EncounterDetailPage;
