import React, { useState, useContext } from 'react';
import { callApi } from '../callApi';
import { AppContext } from '../components/AppContext';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  VStack,
  Text,
  Button,
  Input,
  FormLabel,
  Spinner,
  IconButton,
  useToast,
  FormControl,
  Select,
} from "@chakra-ui/react";
import { DeleteIcon } from '@chakra-ui/icons';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';

function ApiTestingPage() {
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  // State variables
  const [externalCaseId, setExternalCaseId] = useState('');
  const [caseId, setCaseId] = useState('');
  const [processing, setProcessing] = useState(false);
  const [fileTypeId, setFileTypeId] = useState('');
  const [files, setFiles] = useState([]);
  const [apiResponses, setApiResponses] = useState([]); // For storing API responses
  const [selectedCaseType, setSelectedCaseType] = useState(""); // Add this state if not already present

  // Hardcoded values
  const payer = 'cms';

  // Function to create a case
  const handleCreateCase = async () => {
    if (!externalCaseId) {
      toast({
        title: 'Missing information',
        description: "Please enter an External Case ID.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    try {
      const response = await callApi('/create_external_case', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          external_case_id: externalCaseId,
          case_type: selectedCaseType,
        }),
      });

      const data = await response.json();
      // Store the API response
      setApiResponses(prevResponses => [...prevResponses, { endpoint: '/create_external_case', data }]);

      if (response.ok) {
        // Set the caseId
        setCaseId(data.case_id);
        // Clear inputs
        setExternalCaseId('');
        toast({
          title: 'Case created successfully',
          description: `Case ID: ${data.case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(data.error || 'Failed to create case');
      }
    } catch (error) {
      console.error('Error creating case:', error);
      toast({
        title: 'Error creating case',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  // Function to upload files
  const handleFileUpload = async () => {
    if (!caseId || !fileTypeId || files.length === 0) {
      toast({
        title: 'Missing information',
        description: "Please ensure Case ID, File Type ID, and files are provided.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    try {
      const formData = new FormData();
      formData.append('file_type_id', fileTypeId);

      files.forEach((file) => {
        formData.append('file', file);
      });

      const response = await callApi(`/upload_file/${caseId}`, accessToken, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      // Store the API response
      setApiResponses(prevResponses => [...prevResponses, { endpoint: `/upload_file/${caseId}`, data }]);

      if (response.ok) {
        toast({
          title: 'Files uploaded successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Clear files
        setFiles([]);
        setFileTypeId('');
      } else {
        throw new Error(data.error || 'Failed to upload files');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      toast({
        title: 'Error uploading files',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  // Function to create and code the case
  const handleCodeCase = async () => {
    if (!caseId) {
      toast({
        title: 'Missing information',
        description: "Please ensure Case ID is provided.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    try {

      let apiEndpoint = '/xxx';
      console.log("selectedCaseType: ", selectedCaseType);
      if (selectedCaseType == 'IONM General') {
        apiEndpoint = '/create_and_code_case';
      } else if (selectedCaseType == 'IONM') {
        apiEndpoint = '/process_ionm_case';
      }

      const response = await callApi(apiEndpoint, accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          case_id: caseId,
          case_type: selectedCaseType,
        }),
      });

      const data = await response.json();
      // Store the API response
      setApiResponses(prevResponses => [...prevResponses, { endpoint: apiEndpoint, data }]);

      if (response.ok) {
        toast({
          title: 'Case coded successfully',
          description: `External Case ID: ${data.external_case_id}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(data.error || 'Failed to code case');
      }
    } catch (error) {
      console.error('Error coding case:', error);
      toast({
        title: 'Error coding case',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };






    const getIonmPdf = async () => {
      
      if (!caseId) {
        toast({
          title: 'Missing Case ID',
          description: "Please ensure Case ID is available.",
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      try {
        const response = await callApi(`/generate_ionm_pdf/${caseId}`, accessToken, {
          method: 'GET',
        });
    
        if (response.ok) {
          const blob = await response.blob();
    
          // Create a link element and trigger the download
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.download = `ionm_report_${caseId}.pdf`;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        } else {
          console.error('Error generating IONM PDF');
        }
      } catch (error) {
        console.error('Error generating IONM PDF:', error);
        alert('Error generating IONM PDF. Please try again.');
      }
    };



  // Function to call the /medical_necessity_general endpoint
  const handleMedicalNecessityAnalysis = async () => {
    if (!caseId) {
      toast({
        title: 'Missing Case ID',
        description: "Please ensure Case ID is available.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    try {

      let apiEndpoint = '/xxx';
      let sourceCaseId = null;
      console.log("selectedCaseType: ", selectedCaseType);
      if (selectedCaseType == 'IONM General') {
        apiEndpoint = '/medical_necessity_general';
      } else if (selectedCaseType == 'IONM') {
        apiEndpoint = '/medical_necessity_analysis';
        sourceCaseId = caseId;
      }

      const response = await callApi(apiEndpoint, accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          case_id: caseId,
          external_case_id: sourceCaseId,
          payer: payer,
          codes_only: false, // Or set to true if you only need codes
        }),
      });

      const data = await response.json();
      // Store the API response
      setApiResponses(prevResponses => [...prevResponses, { endpoint: apiEndpoint, data }]);

      if (response.ok) {
        toast({
          title: 'Medical Necessity Analysis Completed',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(data.error || 'Failed to perform medical necessity analysis');
      }
    } catch (error) {
      console.error('Error in medical necessity analysis:', error);
      toast({
        title: 'Error in Medical Necessity Analysis',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  // Function to handle file selection
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFiles(Array.from(e.target.files));
    }
  };

  // Function to remove a selected file
  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid
        templateAreas={
          `"header header"
           "main results"
           "footer footer"`
        }
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'300px 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
      >
        {/* Header */}
        <HeaderMenus />

        {/* Main Content */}
        <GridItem bg='gray.50' padding={4} area={'main'}>
          <VStack spacing={6} align="start">
            {/* Create Case Section */}
            <Box w="100%">
              <Text fontSize="2xl" fontWeight="bold" mb={4}>
                Create a Case
              </Text>
              <FormControl isRequired mb={3}>
                <FormLabel>External Case ID</FormLabel>
                <Input
                  type="text"
                  value={externalCaseId}
                  onChange={(e) => setExternalCaseId(e.target.value)}
                />
              </FormControl>

              <FormControl mb={3}>
                <FormLabel>Case Type</FormLabel>
                <Select
                  value={selectedCaseType}
                  onChange={(e) => setSelectedCaseType(e.target.value)}
                  placeholder="Select case type"
                >
                  <option value="IONM">IONM</option>
                  <option value="IONM General">IONM General</option>
                </Select>
              </FormControl>

              <Button
                colorScheme="blue"
                onClick={handleCreateCase}
                isLoading={processing}
              >
                Create Case
              </Button>
            </Box>

            {/* Display Case ID */}
            {caseId && (
              <Box w="100%">
                <Text fontSize="normal" fontWeight="normal" mt={6} mb={4}>
                  Case ID: {caseId}
                </Text>

                {/* Upload Files Section */}
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                  Upload Files
                </Text>
                {selectedCaseType === 'IONM' && (
                <Text fontSize="sm" mb={4} textAlign="left">
                  File type requirements for IONM case type.<br />
                  File Type ID 1: JSON (required)<br />
                  File Type ID 2: Chat transcript (optional) <br />
                  File Type ID 3: Technical Report (required) <br />
                  File Type ID 4: Summary Report (required) <br />
                  </Text>
                  )
                }
                {selectedCaseType === 'IONM General' && (
                  <Text fontSize="sm" mb={4} textAlign="left">
                    File type requirements for IONM General case type.<br />
                    Include all available files, including Chat transcript, Technical Report, and Summary Report. Supports txt, doc, and pdf.
                  </Text>
                )}

                <FormControl isRequired mb={3}>
                  <FormLabel>File Type ID</FormLabel>
                  <Input
                    type="text"
                    value={fileTypeId}
                    onChange={(e) => setFileTypeId(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>Select Files</FormLabel>
                  <Input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                </FormControl>

                {/* Display Selected Files */}
                {files.length > 0 && (
                  <Box>
                    <Text fontWeight="bold">Selected Files:</Text>
                    {files.map((file, index) => (
                      <Flex key={index} alignItems="center" mt={2}>
                        <Text>{file.name}</Text>
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Remove file"
                          onClick={() => handleRemoveFile(index)}
                          size="sm"
                          ml={2}
                          colorScheme="red"
                        />
                      </Flex>
                    ))}
                  </Box>
                )}

                <Button
                  colorScheme="blue"
                  onClick={handleFileUpload}
                  isLoading={processing}
                  mt={4}
                >
                  Upload Files
                </Button>

                {/* Create and Code Case Section */}
                <Text fontSize="xl" fontWeight="bold" mt={8} mb={4}>
                  Create and Code Case
                </Text>
                <Button
                  colorScheme="green"
                  onClick={handleCodeCase}
                  isLoading={processing}
                >
                  Create and Code Case
                </Button>

                {/* Medical Necessity Analysis Section */}
                <Text fontSize="xl" fontWeight="bold" mt={8} mb={4}>
                  Medical Necessity Analysis
                </Text>
                <FormControl mb={3}>
                  <FormLabel>Payer</FormLabel>
                  <Input
                    type="text"
                    value={payer}
                    isReadOnly // Payer is hardcoded and not editable
                  />
                </FormControl>
                <Button
                  colorScheme="purple"
                  onClick={handleMedicalNecessityAnalysis}
                  isLoading={processing}
                >
                  Run Medical Necessity Analysis
                </Button>

                <Button
                  onClick={getIonmPdf}
                  variant="link"
                  size="sm"
                  colorScheme="green"
                >
                  Get IONM PDF
                </Button>

              </Box>
            )}
          </VStack>
        </GridItem>

        {/* Results Section */}
        <GridItem bg='gray.50' padding={4} area={'results'} maxW="1400px">
          {/* Display API Responses */}
          {apiResponses.length > 0 && (
            <Box textAlign="left" overflowX="auto">
              <Text fontWeight="bold" fontSize="xl" mb={4}>API Responses:</Text>
              {apiResponses.map((responseObj, index) => (
                <Box key={index} mb={6}>
                  <Text fontWeight="bold">Endpoint: {responseObj.endpoint}</Text>
                  <pre style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
                    {JSON.stringify(responseObj.data, null, 2)}
                  </pre>
                </Box>
              ))}
            </Box>
          )}
        </GridItem>

        {/* Footer */}
        <GridItem
          bg='white'
          borderTop='1px'
          borderColor='brand.200'
          paddingY={4}
          paddingX={6}
          area={'footer'}
        >
          <Flex justifyContent="center" alignItems="center">
            <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
            <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
              &copy; {new Date().getFullYear()}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      {/* ChatToggle Component */}
      <ChatToggle
        contextType={'revedy'}
        contextData={'revedy_api_testing'}
        contextPersona={'revedy_api_testing'}
        firstMessage={"Hi, how can I test the API on this page?"}
        useOptions={false}
        maintainContext={true}
        contextMode={'popup'}
      />
    </Box>
  );
}

export default ApiTestingPage;