import React, { useState, useContext, useEffect } from 'react';
import {
  Box, Text, Button, Grid, GridItem, Textarea, VStack, Heading,
  useToast, Select
} from "@chakra-ui/react";
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';
import { useLocation } from 'react-router-dom';

// Mapping of user-friendly labels to backend values
const CASE_TYPE_MAPPING = {
  'Standard': 'Simple',
  'Patient Query': 'Enhanced',
  'Surgery': 'Surgery'
};

function CptCodeGenerator() {
  const [inputText, setInputText] = useState('');
  const [cptResults, setCptResults] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [caseType, setCaseType] = useState('Standard'); // Default to Standard
  const { accessToken } = useContext(AppContext);
  const toast = useToast();
  const location = useLocation();

  // Parse URL query parameters on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const caseTypeParam = queryParams.get('case_type');
    
    // If case_type is in URL and it's a valid option, set it
    if (caseTypeParam && Object.keys(CASE_TYPE_MAPPING).includes(caseTypeParam)) {
      setCaseType(caseTypeParam);
    }
  }, [location]);

  const generateCptCodes = async () => {
    setIsProcessing(true);
    try {
      const externalCaseId = Math.random().toString(36).substr(2, 9);
      const caseId = await createCase(externalCaseId);
      await addTextToCase(caseId, inputText);
      const results = await performCptCoding(caseId, CASE_TYPE_MAPPING[caseType]);
      setCptResults(results);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const createCase = async (externalCaseId) => {
    const response = await callApi('/create_external_case', accessToken, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        external_case_id: externalCaseId,
        case_type: 'Surgery'
      }),
    });

    const data = await response.json();
    if (response.ok) {
      return data.case_id;
    } else {
      throw new Error(data.error || 'Failed to create case');
    }
  };

  const addTextToCase = async (caseId, text) => {
    const formData = new FormData();
    formData.append('file_type_id', '5');
    const blob = new Blob([text], { type: 'text/plain' });
    formData.append('file', blob, 'content.txt');

    const response = await callApi(`/upload_content/${caseId}`, accessToken, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to upload content');
    }
  };

  const performCptCoding = async (caseId, caseTypeValue) => {
    const response = await callApi(`/cpt_coding`, accessToken, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        case_id: caseId,
        case_type: caseTypeValue
      }),
    });

    const data = await response.json();
    if (response.ok) {
      console.log("CPT coding results:", data.results);
      return data.results;
    } else {
      throw new Error(data.error || 'Failed to perform CPT coding');
    }
  };

  const formatText = (text) => {
    if (!text) return ''; 
    return text.replace(/\n/g, '<br/>').replace(/•/g, '&bull;');
  };
  
  const formatResults = (results) => {
    if (!results) return null;
    
    let jsonString = JSON.stringify(results, null, 2);
    jsonString = jsonString.replace(/\\n/g, '<br/>');
    
    return (
      <Box>
        <Heading size="lg" mb={4}>CPT Coding Results</Heading>
        <Box p={4} borderWidth="1px" borderRadius="md" bg="gray.50" overflowX="auto">
          <div 
            dangerouslySetInnerHTML={{ 
              __html: `<pre style="white-space: pre-wrap;">${jsonString}</pre>` 
            }} 
          />
        </Box>
      </Box>
    );
  };
    
  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <VStack spacing={4} align="stretch">
            
            <Box>
              <Text mb={2}>Case Type:</Text>
              <Select 
                value={caseType}
                onChange={(e) => setCaseType(e.target.value)}
                width="200px"
              >
                {Object.keys(CASE_TYPE_MAPPING).map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Select>
            </Box>

            <Textarea
              placeholder="Enter text for CPT coding"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              rows={10}
            />

            <Button
              onClick={generateCptCodes}
              isLoading={isProcessing}
              loadingText="Generating"
              colorScheme="blue"
            >
              Generate CPT Codes
            </Button>

            {cptResults && formatResults(cptResults)}

          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default CptCodeGenerator;