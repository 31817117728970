import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import { callApi } from '../callApi';
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Input,
  Textarea,
  useToast,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';

function TemplateManager() {
  const [templates, setTemplates] = useState([]);
  const [name, setName] = useState('');
  const [caseType, setCaseType] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [templateText, setTemplateText] = useState('');
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    fetchTemplates();
  }, [accessToken]);

  const fetchTemplates = async () => {
    try {
      const response = await callApi('/templates', accessToken);
      if (response.ok) {
        setTemplates(await response.json());
      } else {
        throw new Error('Failed to fetch templates');
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      toast({
        title: 'Error fetching templates',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await callApi('/templates', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: editingTemplateId,
          name,
          case_type: caseType,
          template_description: templateDescription,
          template_text: templateText,
        }),
      });
  
      if (response.ok) {
        const result = await response.json();
        toast({
          title: editingTemplateId ? 'Template updated successfully' : 'Template added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchTemplates();
        resetForm();
      } else {
        throw new Error(editingTemplateId ? 'Failed to update template' : 'Failed to add template');
      }
    } catch (error) {
      console.error('Error submitting template:', error);
      toast({
        title: 'Error submitting template',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (template) => {
    setEditingTemplateId(template.id);
    setName(template.name);
    setCaseType(template.case_type);
    setTemplateDescription(template.content.template_description || '');
    setTemplateText(template.content.template_text || '');
  };

  const resetForm = () => {
    setEditingTemplateId(null);
    setName('');
    setCaseType('');
    setTemplateDescription('');
    setTemplateText('');
  };

  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <Grid templateColumns="1fr 1fr" gap={6}>
            {/* Left Column: Edit/Create Form */}
            <Box>
              <Heading size="md" mb={4}>{editingTemplateId ? 'Edit Template' : 'Add New Template'}</Heading>
              <form onSubmit={handleSubmit}>
                <VStack spacing={4} align="stretch">
                  <Input
                    placeholder="Template Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <Input
                    placeholder="Case Type"
                    value={caseType}
                    onChange={(e) => setCaseType(e.target.value)}
                    required
                  />
                  <Textarea
                    placeholder="Template Description"
                    value={templateDescription}
                    onChange={(e) => setTemplateDescription(e.target.value)}
                    required
                    minHeight="100px"
                  />
                  <Textarea
                    placeholder="Template Text"
                    value={templateText}
                    onChange={(e) => setTemplateText(e.target.value)}
                    required
                    minHeight="300px"
                  />
                  <Button type="submit" colorScheme="blue">
                    {editingTemplateId ? 'Update Template' : 'Add Template'}
                  </Button>
                  {editingTemplateId && (
                    <Button onClick={resetForm}>Cancel Edit</Button>
                  )}
                </VStack>
              </form>
            </Box>

            {/* Right Column: Template List */}
            <Box>
              <Heading size="md" mb={4}>Template List</Heading>
              <Accordion allowMultiple>
                {templates.map((template) => (
                  <AccordionItem key={template.id}>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          {template.name} ({template.case_type})
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <VStack align="stretch" spacing={2}>
                        <Text><strong>Active:</strong> {template.is_active ? 'Yes' : 'No'}</Text>
                        <Text><strong>Description:</strong> {template.content?.template_description || 'No description available'}</Text>
                        <Text><strong>Template Text:</strong></Text>
                        <Box
                          bg="gray.50"
                          p={2}
                          borderRadius="md"
                          whiteSpace="pre-wrap"
                          maxHeight="200px"
                          overflowY="auto"
                        >
                          {template.content?.template_text || 'No template text available'}
                        </Box>
                        <Button onClick={() => handleEdit(template)} colorScheme="teal">
                          Edit
                        </Button>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default TemplateManager;
