import React, { useState } from 'react';
import { 
  TextEntry
} from './TextEntry';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './quill.snow-custom.css';
import ReactQuill from 'react-quill';
import './custom.css';
import ProtectedRoute from './components/ProtectedRoute';
import BackendCheck from './components/BackendCheck';
import TermsAndConditions from './components/TermsAndConditions';
import CodeIOM from './components/IOM';
import GenerateNote from './components/GenerateNote';
import GenerateTranscript from './components/GenerateTranscript';
import Chat from './components/Chat';
import LoginPage from './loginpage';
import Admin from './components/Admin';
import ClientAdmin from './components/ClientAdmin';
import APIDocs from './components/APIDocs';
import APIDocsPublic from './components/APIDocsPublic';
import CaseServiceTable from './components/CaseServiceTable';
import { WebSocketProvider } from './components/WebSocket';
import { AppContextProvider } from './components/AppContext'; // adjust the import based on your project structure
import CreateNote from './components/CreateNote';
import ABN from './components/ABN';
import StripeCheckout from './components/StripeCheckout';
import FileUpload from './components/FileUpload';
import EncounterAnalysis from './components/EncounterAnalysis';
import EncounterList from './components/EncounterList';
import StripeThankYou from './components/StripeThankYou';
import RevedyWelcome from './components/RevedyWelcome';
import ExternalIONMCase from './components/IONMExternalCase';
import LogViewer from './components/LogViewer';
import EncountersTable from './components/ProviderEncountersTable';
import EncountersTableAdmin from './components/ProviderEncountersTableAdmin';
import EncounterDetailPage from './components/EncounterDetailPage';
import CognitiveAssessment from './components/APICognitiveAssessment';
import MedicalNecessityAnalysis from './components/MedicalNecessityAnalysis';
import MedicalNecessityGeneral from './components/MedicalNecessityGeneral';
import ExternalCaseManager from './components/ExternalCaseManager';
import TemplateManager from './components/TemplateManager';
import IONMCodingDocs from './components/APIIONMdocs';
import APIIONMdemo from './components/APIIONMdemo';
import CPTCaseCoder from './components/CPTCaseCoder';
import FullCPTCoder from './components/FullCPTCoder';
import IONMDocumentation from './components/IONMDocumentation';

import {
  extendTheme,
  ChakraProvider,
  Box,
} from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({
  colors,
  components: {
    Text: {
      baseStyle: {
        fontSize: "sm",
        fontWeight: "normal",
      },
    },
  },
});


function App() {
  const [dataReceived, setDataReceived] = useState(false);

  const [results, setResults] = useState(undefined);
  
  const handleDataReceived = (results) => {
    console.log ("data received");    
    setDataReceived(true);
    setResults(results);
  };

  const [PTdataReceived, PTsetDataReceived] = useState(false);
  const [PTresults, PTsetResults] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const PThandleDataReceived = (PTresults) => {
    console.log ("data received");    
    PTsetDataReceived(true);
    PTsetResults(PTresults);
  };


  return (
    <Router>
      <AppContextProvider>
        <Routes>        
          <Route path="/test" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
            <BackendCheck>
            <WebSocketProvider>
              <ChakraProvider theme={theme}>
                <Box>
                  <ReactQuill />
                </Box>
              </ChakraProvider>
              </WebSocketProvider>
            </BackendCheck>
            </ProtectedRoute>
            } />

          {/* good - used to enter note text and code icd-10*/}
          <Route path="/note" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
            <BackendCheck>
              <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <TextEntry onDataReceived={handleDataReceived} results={results} />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

          {/* good - terms and conditions as part of new user registration */}
          <Route path="/terms" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <TermsAndConditions />
            </ProtectedRoute>
          } />

          {/* good -used for full cpt coding */}
          <Route  path="/" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                      <FullCPTCoder />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />  
          
          {/* good - used for login error message*/}
          <Route path="/login" element={
            <ChakraProvider theme={theme}>
              <LoginPage errorMessage={errorMessage}/>
            </ChakraProvider>
          } />

          {/* good - used for new user data collection*/}
            <Route path="/welcome" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                      <ChakraProvider theme={theme}>
                          <RevedyWelcome />
                      </ChakraProvider>
              </ProtectedRoute>
            } />

            {/* good - displays IONM coded with CADWELL JSON */}
            <Route path="/ionmform" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <CodeIOM />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            {/* good - displays IONM mnemonics */}
            <Route path="/ionmcase" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <ExternalIONMCase />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            {/*good - log viewer for error messages  */}
            <Route path="/logviewer" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <LogViewer />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            {/*good - list of encounters with notes */}
            <Route path="/encounters" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>              
                    <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <EncountersTable />
                      </ChakraProvider>
                    </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            {/*good - table for IONM Cadwell cases  */}
            <Route path="/ionmtable" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <CaseServiceTable />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            {/*good - used to see how API works for note transcription etc  */}
            <Route path="/ea" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <EncounterAnalysis />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            {/*good - used to see how api works for getting lists of notes  */}
            <Route path="/el" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <EncounterList />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            {/*Review - might be useful - not sure it works  */}
            <Route path="/securefiles" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                          <FileUpload />
                      </ChakraProvider>
                      </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            {/* keep, just goes to goes to full-cpt-coder  */}
            <Route path="/home" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                  <BackendCheck>
                  <WebSocketProvider>
                      <ChakraProvider theme={theme}>
                        <FullCPTCoder />
                      </ChakraProvider>
                    </WebSocketProvider>
                  </BackendCheck>
              </ProtectedRoute>
            } />

            {/*Review - maybe we should keep and extend? public api docs */}
            <Route path="/api" element={
                    <ChakraProvider theme={theme}>
                      <APIDocsPublic />
                    </ChakraProvider>
            } />

            {/*good - current api dcos  */}
            <Route path="/api-docs" element={
              <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                      <APIDocs />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
              </ProtectedRoute>
            } />

            {/*keep - see how the API can be used to generate notes  */}
            <Route path="/generate" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <GenerateNote />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*good - interface to do healthcare chats */}
            <Route path="/chat" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <Chat />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*good - scribe capabilities   */}
            <Route path="/transcribe" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <GenerateTranscript />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*good - scribe capabilities same page as /transcribe  */}
            <Route path="/scribe" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <GenerateTranscript />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

          {/* used internally by /generate to create the note */}
          <Route path="/create-note" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <CreateNote />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*good - purchase pages  */}
            <Route path="/checkout" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <StripeCheckout />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*good - purchase pages  */}
            <Route path="/thankyou" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
              <BackendCheck>
                <WebSocketProvider>
                  <ChakraProvider theme={theme}>
                    <StripeThankYou />
                  </ChakraProvider>
                </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*good - used by admin - don't link to it though  */}
            <Route path="/admin" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <Admin />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />
          
            {/*client admin - can put in menu  */}
            <Route path="/client-admin" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <ClientAdmin />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*assess - probably keep but needs review  */}
            <Route path="/abn/:docId" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <ABN />
                    </ChakraProvider>
                  </WebSocketProvider>
              </BackendCheck>
            </ProtectedRoute>
          } />

            {/*delete - but save case defintion for cognitive assessment  */}
            <Route path="/cognitive-api" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <CognitiveAssessment />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*keep - this is primary for IONM and probably can be used for other cases  */}
            <Route path="/medical-necessity-analysis" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <MedicalNecessityAnalysis />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*keep for now - shows deprecated page  */}
            <Route path="/external-case-manager" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <ExternalCaseManager />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*keep for now - need to merge with medical-necessity-analysis somehow  */}
            <Route path="/medical-necessity-general" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <MedicalNecessityGeneral />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*keep - shows details of encounters, linked from encountersadmin  */}
            <Route path="/encounter/:encounterId" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <EncounterDetailPage />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*  */}
            <Route path="/templatemanager" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <TemplateManager />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*keep - good overview of notes encounters - needs cleanup */}
            <Route path="/encountersadmin" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <EncountersTableAdmin />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*good - api docs for IONM specifically  */}
            <Route path="/apiionm" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <IONMCodingDocs />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/* keep - part of showing how the IONM api works  */}
            <Route path="/apiionmdemo" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <APIIONMdemo />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*  */}
            <Route path="/external-case-ionm" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <ExternalIONMCase />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*delete - outdated  */}
            <Route path="/cpt-coder" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <CPTCaseCoder />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*keep - for creating IONM with Cadwell files  */}
            <Route path="/full-cpt-coder" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <FullCPTCoder />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

            {/*keep - for showing how IONM works */}
            <Route path="/ionm-docs" element={
            <ProtectedRoute setErrorMessage={setErrorMessage}>
                <BackendCheck>
                  <WebSocketProvider>
                    <ChakraProvider theme={theme}>
                        <IONMDocumentation />
                    </ChakraProvider>
                  </WebSocketProvider>
                </BackendCheck>
            </ProtectedRoute>
          } />

        </Routes>
      </AppContextProvider> 
    </Router>
  );
}

export default App;