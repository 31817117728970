// ./loginpage.js
import React from 'react';
import {
  Box, Button, Text, Grid, GridItem, Flex, VStack, HStack,
  Heading, Card, CardHeader, CardBody, Textarea, Divider,
  useToast, Tag, TagLabel, Badge, Spacer, Menu, MenuButton,
  MenuList, MenuItem, Image, IconButton, Collapse
} from '@chakra-ui/react';
import { AddIcon, AttachmentIcon, EditIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  const toast = useToast();
  const [showContentEntry, setShowContentEntry] = React.useState(true);

  // Menu structure from the provided JSON
  const menuStructure = {
    "logo": {"alt": "Revedy", "src": "/images/rev_logo.png", "path": "/home", "height": "25px"}, 
    "items": [
      {"CPT Coding": {"sub": [{"CPT Coding": "/full-cpt-coder"}, {"Medical Necessity Analysis": "/medical-necessity-analysis"}, {"My Encounters": "/encounters"}, {"All Encounters": "/encountersadmin"}]}}, 
      {"ICD-10": {"sub": [{"Code ICD-10": "/note"}]}}, 
      {"Scribe": {"sub": [{"Try It Now": "/scribe"}]}}, 
      {"IONM": {"sub": [{"Overview": "/ionm-docs"}, {"Cases Dashboard": "/ionmtable"}, {"New IONM Case": "/ionmcase"}, {"Medical Necessity Analysis": "/medical-necessity-analysis"}]}}, 
      {"API & Documentation": {"sub": [{"API Documentation": "/api-docs"}, {"IONM API": "/apiionm"}, {"IONM API Demo": "/apiionmdemo"}, {"Encounter Analysis": "/ea"}, {"Encounter List": "/el"}, {"Generate Note": "/generate"}]}}, 
      {"Admin": {"sub": [{"Client Admin": "/client-admin"}, {"Template Manager": "/templatemanager"}, {"Log Viewer": "/logviewer"}, {"File Upload": "/securefiles"}, {"Admin Panel": "/admin"}, {"Chat Assistant": "/chat"}]}}
    ]
  };

  const handleSignup = () => {
    if (!process.env.REACT_APP_REVEDY_API_HOST) {
      console.error('REACT_APP_REVEDY_API_HOST is not set');
      return;
    }
  
    loginWithRedirect({
      authorizationParams: {
        'screen_hint': 'signup',
      },
      appState: { returnTo: `${window.location.origin}/full-cpt-coder` },
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      scope: "read:current_user",
    });
  };

  const handleLogin = () => {
    if (!process.env.REACT_APP_REVEDY_API_HOST) {
      console.error('REACT_APP_REVEDY_API_HOST is not set');
      return;
    }

    loginWithRedirect({
      appState: { returnTo: `${window.location.origin}/full-cpt-coder` },
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      scope: "read:current_user",
    });
  };

  // Mock data for the preview
  const mockCases = [
    { id: 1, external_case_id: "DEMO-001", status: "complete", date_updated: "2023-11-15 14:30:00", case_type: "Surgery", case_description: "Total Hip Replacement" },
    { id: 2, external_case_id: "DEMO-002", status: "in_progress", date_updated: "2023-11-15 10:15:00", case_type: "Surgery", case_description: "Laparoscopic Cholecystectomy" },
    { id: 3, external_case_id: "DEMO-003", status: "pending", date_updated: "2023-11-14 16:45:00", case_type: "Surgery", case_description: "Carpal Tunnel Release" }
  ];

  // Helper function to format date in a more readable way
  const formatDateShort = (dateString) => {
    if (!dateString) return '';
    
    const parts = dateString.split(' ');
    if (parts.length !== 2) return dateString;
    
    const datePart = parts[0];
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    const [year, month, day] = datePart.split('-').map(Number);
    const dateObj = new Date(year, month - 1, day);
    
    if (dateObj.getTime() === today.getTime()) {
      return 'Today';
    } else if (dateObj.getTime() === yesterday.getTime()) {
      return 'Yesterday';
    } else {
      return `${month}/${day}/${year.toString().substr(2)}`;
    }
  };

  // Function to render menu items based on the structure
  const renderMenuItems = () => {
    return menuStructure.items.map((item, index) => {
      const [label, value] = Object.entries(item)[0];
      
      if (typeof value === 'string') {
        return (
          <Text 
            key={index}
            fontSize="sm" 
            textDecoration="underline" 
            color="gray.700" 
            cursor="pointer" 
            onClick={handleSignup}
          >
            {label}
          </Text>
        );
      }
      
      if (typeof value === 'object' && value.sub) {
        return (
          <Menu key={index}>
            <MenuButton as={Text} fontSize="sm" textDecoration="underline" color="gray.700">
              {label}
              <ChevronDownIcon ml={1} />
            </MenuButton>
            <MenuList>
              {value.sub.map((subItem, subIndex) => {
                const [subLabel, subPath] = Object.entries(subItem)[0];
                return (
                  <MenuItem 
                    key={subIndex} 
                    fontSize="sm" 
                    onClick={handleSignup}
                  >
                    {subLabel}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        );
      }
      
      return null;
    });
  };

  // Toggle content entry visibility
  const toggleContentEntry = () => {
    setShowContentEntry(!showContentEntry);
  };

  // Render status indicator based on case status
  const renderStatusIndicator = (status) => {
    if (!status) return null;
    
    if (status.toLowerCase() === 'in_progress') {
      return (
        <Box as="span" w="10px" h="10px" borderRadius="50%" bg="orange.500" mr={1} />
      );
    } else if (status.toLowerCase() === 'complete') {
      return (
        <Box as="span" w="10px" h="10px" borderRadius="50%" bg="green.500" mr={1} />
      );
    }
    return null;
  };

  return (    
    <Box textAlign="center" fontSize="md" bg="white">
      <Grid
        templateAreas={`"header"
                        "main"
                        "footer"`}
        gridTemplateRows={'60px 1fr 30px'}
        gridTemplateColumns={'1fr'}
        h='100vh'
        gap='1'
        color='blackAlpha.800'
        fontWeight='bold'
      >
        {/* Header with menu */}
        <GridItem area="header" bg="white" borderBottom="1px" borderColor="gray.200">
          <Flex px={6} h="100%" alignItems="center" bg="header_background" borderBottom="1px" borderColor="gray.200">
            {/* Logo and Tagline */}
            <Flex alignItems="center" mr={8}>
              <Image 
                src={menuStructure.logo.src}
                alt={menuStructure.logo.alt}
                height={menuStructure.logo.height}
                onClick={handleSignup}
                cursor="pointer"
              />
              <Text 
                ml={3} 
                fontSize="sm" 
                color="gray.600" 
                fontStyle="italic"
              >
                Your AI Agent for RCM
              </Text>
            </Flex>
            
            {/* Navigation Menu with consistent styling */}
            <Flex style={{ gap: '20px' }}>
              {renderMenuItems()}
            </Flex>
            
            <Spacer />
            
            <HStack spacing={4}>
              <Button 
                colorScheme="blue" 
                variant="outline" 
                size="sm"
                onClick={handleLogin}
              >
                Log In
              </Button>
              <Button 
                colorScheme="blue" 
                variant="solid" 
                size="sm"
                onClick={handleSignup}
              >
                Sign Up
              </Button>
            </HStack>
          </Flex>
        </GridItem>

        {/* Main content area with CPT Coder preview */}
        <GridItem 
          area="main" 
          bg='white' 
          p={6} 
          textAlign="left" 
          alignContent={"left"}
          overflowY="auto"
        >
          {/* Hero section with the requested text */}
          <Box mb={6} pb={4} borderBottom="1px" borderColor="gray.200">
            <Heading size="lg" mb={3}>Revedy AI Agent for RCM</Heading>
            <Text fontSize="md" maxW="800px">
              Our AI-powered Coding Agent helps physicians and revenue cycle leaders save time and improve accuracy. 
              Get immediate access to our platform by signing up today.
            </Text>
          </Box>

          <Flex>
            {/* Left Column - Case List */}
            <Box width="280px" pr={4} borderRightWidth="1px">
              {/* New Case Button */}
              <Button 
                leftIcon={<AddIcon />} 
                colorScheme="green" 
                variant="solid" 
                size="md" 
                width="100%" 
                mb={4}
                onClick={handleSignup}
              >
                New Case
              </Button>
              
              <Heading size="md" mb={4}>Cases</Heading>

              <VStack spacing={3} align="stretch" maxH="calc(100vh - 400px)" overflowY="auto">
                {mockCases.map(caseItem => (
                  <Box 
                    key={caseItem.id}
                    p={3}
                    borderWidth="1px"
                    borderRadius="md"
                    bg={caseItem.id === 2 ? "green.50" : "white"}
                    borderColor={caseItem.id === 2 ? "green.400" : "gray.200"}
                    cursor="pointer"
                    onClick={handleSignup}
                    _hover={{ 
                      bg: caseItem.id === 2 ? "green.100" : "gray.50",
                      borderColor: caseItem.id === 2 ? "green.500" : "gray.300"
                    }}
                    transition="all 0.2s"
                  >
                    <HStack mb={1}>
                      {renderStatusIndicator(caseItem.status)}
                      
                      <Text fontWeight="bold" fontSize="sm">
                        {caseItem.case_description}
                      </Text>
                    </HStack>
                    
                    <Text fontSize="xs" color="gray.600" mb={2}>
                      {formatDateShort(caseItem.date_updated)} - {caseItem.external_case_id}
                    </Text>
                    
                    <HStack spacing={2} mt={1}>
                      <Badge 
                        colorScheme={caseItem.status === 'complete' ? "green" : 
                                    caseItem.status === 'in_progress' ? "orange" : "gray"} 
                        fontSize="xs"
                      >
                        {caseItem.status}
                      </Badge>
                      <Badge colorScheme="purple" fontSize="xs">{caseItem.case_type}</Badge>
                      <Badge colorScheme="blue" fontSize="xs">2 files</Badge>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </Box>

            {/* Middle Column - Content Entry and CPT Results */}
            <Box flex="1" pl={4} pr={4}>
              <VStack spacing={6} align="stretch">
                {/* Content Entry Section with Collapsible Header */}
                <Card variant="outline" borderColor="teal.200">
                  <CardHeader p={3} bg="teal.50" onClick={toggleContentEntry} cursor="pointer">
                    <Flex justify="space-between" align="center">
                      <Text fontWeight="bold" fontSize="md">
                        Code Note: Paste or Upload Here
                      </Text>
                      <IconButton
                        icon={showContentEntry ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        variant="ghost"
                        aria-label={showContentEntry ? "Collapse" : "Expand"}
                        size="sm"
                      />
                    </Flex>
                  </CardHeader>
                  
                  <Collapse in={showContentEntry} animateOpacity>
                    <CardBody>
                      {/* Text Entry Section */}
                      <Box width="100%" mb={4}>
                        <Textarea
                          placeholder="Enter note or any other documentation here..."
                          rows={6}
                          mb={3}
                          onClick={handleSignup}
                        />
                        <HStack spacing={3}>
                          <Button
                            leftIcon={<CheckCircleIcon />}
                            colorScheme="teal"
                            onClick={handleSignup}
                          >
                            Submit & Code
                          </Button>
                          <Button
                            variant="outline"
                            colorScheme="teal"
                            onClick={handleSignup}
                            size="md"
                          >
                            Submit and Add More Content
                          </Button>
                        </HStack>
                      </Box>

                      {/* Horizontal Divider */}
                      <Divider my={4} />

                      {/* File Upload Section */}
                      <Box width="100%">
                        <Text mb={3}>Or upload pdf, txt, png, or jpg files:</Text>
                        <HStack spacing={3}>
                          <Button
                            leftIcon={<AttachmentIcon />}
                            colorScheme="blue"
                            onClick={handleSignup}
                          >
                            Upload & Code
                          </Button>
                          <Button
                            variant="outline"
                            colorScheme="blue"
                            onClick={handleSignup}
                          >
                            Upload and Add More Content
                          </Button>
                        </HStack>
                      </Box>
                    </CardBody>
                  </Collapse>
                </Card>


                {/* Marketing Section */}
                <Box mt={6} p={4} borderWidth="1px" borderRadius="md" bg="blue.50">
                  <VStack spacing={4} align="start">
                    <Heading size="md">Why Choose Revedy CPT Coding?</Heading>
                    <HStack spacing={8} width="100%">
                      <VStack align="start" flex="1">
                        <Heading size="sm">Integration & API</Heading>
                        <Text fontSize="sm">Seamlessly integrate with your existing systems through our robust API, enabling automated workflows and real-time coding.</Text>
                      </VStack>
                      <VStack align="start" flex="1">
                        <Heading size="sm">Accuracy</Heading>
                        <Text fontSize="sm">Our AI models are trained on millions of medical records to ensure accurate coding recommendations.</Text>
                      </VStack>
                      <VStack align="start" flex="1">
                        <Heading size="sm">Efficiency & Compliance</Heading>
                        <Text fontSize="sm">Save hours of manual coding work while staying compliant with the latest guidelines and reducing audit risks.</Text>
                      </VStack>
                    </HStack>
                    <Button
                      size="md" 
                      variant="solid"
                      colorScheme="blue"
                      onClick={handleSignup}
                      mt={2}
                      _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
                      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                    >
                      Sign Up Now
                    </Button>
                  </VStack>
                </Box>
              </VStack>
            </Box>

            {/* Right Column - Case Details */}
            <Box width="350px" pl={4} borderLeftWidth="1px">
              <Card variant="outline" height="100%">
                <CardHeader p={3} bg="blue.50">
                  <Text fontWeight="bold" fontSize="md">Case Details</Text>
                </CardHeader>
                <CardBody>
                  <Badge colorScheme="orange" mb={2}>
                    <Box as="span" w="8px" h="8px" borderRadius="50%" bg="orange.500" mr={1} display="inline-block" />
                    in_progress
                  </Badge>
                  <Text fontSize="sm"><strong>Case ID:</strong> DEMO-002</Text>
                  <Text fontSize="sm"><strong>Type:</strong> Surgery</Text>
                  <Text fontSize="sm"><strong>Created:</strong> November 15, 2023 10:15 AM</Text>
                  
                  <Box mt={4}>
                    <Heading size="sm" mb={2}>Uploaded Content</Heading>
                    <Box 
                      p={2} 
                      borderWidth="1px" 
                      borderRadius="md" 
                      cursor="pointer"
                      _hover={{ bg: "blue.50" }}
                      onClick={handleSignup}
                    >
                      <HStack>
                        <AttachmentIcon color="blue.500" />
                        <Text color="blue.600" textDecoration="underline" fontSize="sm">operative_report.pdf</Text>
                        <Tag size="sm" colorScheme="blue" borderRadius="full">
                          <TagLabel fontSize="xs">PDF</TagLabel>
                        </Tag>
                      </HStack>
                    </Box>
                  </Box>
                </CardBody>
              </Card>
            </Box>
          </Flex>
        </GridItem>

        {/* Footer */}
        <GridItem area="footer" bg='white' borderTop='1px' borderColor='gray.200' p={2}>
          <Text fontSize="xs" color="gray.500">© 2023 Revedy. All rights reserved.</Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default LoginPage;