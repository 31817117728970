import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Box,
  Button,
  Input,
  HStack,
  Text,
  Heading,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
  VStack,
  Link,
  Select,
} from '@chakra-ui/react';
import HeaderMenus from './HeaderMenus';
import { callApi } from '../callApi';
import { AppContext } from './AppContext';

const MedicalNecessityGeneral = () => {
  const { accessToken } = useContext(AppContext);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [selectedCptCode, setSelectedCptCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [CPTCodesData, setCPTCodesData] = useState({});

  const [caseId, setCaseId] = useState('');
  const [cptCode, setCptCode] = useState('');
  const [payer, setPayer] = useState('cms');
  const [analysisPayer, setAnalysisPayer] = useState('');
  const [caseOverview, setCaseOverview] = useState(null);
  const [isGeneratingOverview, setIsGeneratingOverview] = useState(false);

  const [cptCodingResults, setCptCodingResults] = useState(null);
  const [isCptCoding, setIsCptCoding] = useState(false);

  const handleGenerateOverview = async () => {
    setIsGeneratingOverview(true);
    try {
      const response = await callApi('/generate_case_overview', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ encounter_id: caseId }),
      });
      const data = await response.json();
      if (data.case_overview) {
        setCaseOverview(data.case_overview);
      } else {
        console.error('Error generating case overview:', data.error);
      }
    } catch (error) {
      console.error('Error generating case overview:', error);
    } finally {
      setIsGeneratingOverview(false);
    }
  };

  const addLinksToReferences = (text) => {
    return text.replace(/\b(A\d+|LCD\d+|https:\/\/www.aetna.com\/cpb\/medical\/data.*.html)\b/g, (match) => {
      if (match.startsWith('A') && !match.startsWith('Aetna_')) {
        return `[${match}](https://www.cms.gov/medicare-coverage-database/view/article.aspx?articleId=${match.slice(1)})`;
      } else if (match.startsWith('LCD')) {
        return `[${match}](https://www.cms.gov/medicare-coverage-database/view/lcd.aspx?LCDId=${match.slice(3)})`;
      } else if (match.startsWith('https://www.aetna.com/cpb/medical/data/')) {
        return `[${match}](${match})`;
      }
      return match;
    });
  };

  const LinkRenderer = ({ href, children }) => (
    <Link href={href} target="_blank" rel="noopener noreferrer" color="blue.500">
      {children}
    </Link>
  );

  const fetchAnalysis = async (payload) => {
    setIsLoading(true);
    try {
      const response = await callApi('/medical_necessity_general', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.status === 'success') {
        console.log('Analysis results:', data);
        setCPTCodesData(data.cpt_codes_data);
        if (!payload.codes_only) {
          setAnalysisResults(data.results);
          setAnalysisPayer(payload.payer);
          if (data.results.length > 0) {
            setSelectedCptCode(data.results[0].cpt_code);
          }
        }
      } else {
        console.error('Error:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event, submittedCaseId = null, submittedCodesOnly = false, submittedPayer = null) => {
    if (event && event.preventDefault) event.preventDefault();       
    const payload = {
      case_id: submittedCaseId || caseId,
      payer: submittedPayer || payer,
      codes_only: submittedCodesOnly || false,
    };
    fetchAnalysis(payload);
  };

  const handleCptCoding = async () => {
    setIsCptCoding(true);
    try {
      const response = await callApi('/cpt_coding', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ case_id: caseId }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        setCptCodingResults(data.results);
      } else {
        console.error('Error:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsCptCoding(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const case_id = searchParams.get('case_id') || '';
    const payer_param = searchParams.get('payer') || 'cms';
    const codes_only = searchParams.get('codes_only') || true;
  
    if (case_id) setCaseId(case_id);
    if (payer_param) setPayer(payer_param);
  
    if (case_id) {
      handleSubmit(null, case_id, codes_only, payer_param);
    }
  }, []);

  const SourcesList = ({ sources, title }) => (
    <Box>
      <Heading as="h3" size="md" mb={2}>
        {title}
      </Heading>
      {sources.map((source, index) => (
        <Box key={index} mb={4} p={4} borderWidth={1} borderRadius="md" boxShadow="sm">
          <ReactMarkdown components={{ a: LinkRenderer }}>
            {addLinksToReferences(source.source_id)}
          </ReactMarkdown>
          <Text mt={2}>{source.relevancy_explanation}</Text>
        </Box>
      ))}
    </Box>
  );

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const renderCptCodes = () => {
    if (!CPTCodesData || Object.keys(CPTCodesData).length === 0) {
      return <Text>No CPT codes found</Text>;
    }
  
    const cptCodesArray = Object.values(CPTCodesData);
  
    return (
      <VStack align="stretch" spacing={2}>
        <Heading as="h3" size="md" mb={2}>
          CPT Codes
        </Heading>
        {cptCodesArray
          .sort((a, b) => {
            const reimbA = a.estimated_non_facility_reimbursement || 0;
            const reimbB = b.estimated_non_facility_reimbursement || 0;
            return reimbB - reimbA;
          })
          .map((item, index) => {
            const analysis = analysisResults.find(
              (result) => result.cpt_code === item.cpt_code
            );
            const score = analysis ? analysis.analysis.score : 'N/A';
  
            return (
              <Box key={item.cpt_code} p={2} borderWidth={1} borderRadius="md">
                <HStack justifyContent="space-between">
                  <Link
                    onClick={() => setSelectedCptCode(item.cpt_code)}
                    color="blue.500"
                    cursor="pointer"
                  >
                    {item.cpt_code}
                  </Link>
                  <Text fontSize="sm" fontWeight="bold">
                    Score: {score}
                  </Text>
                </HStack>
                <Text fontSize="sm">
                  {truncateText(item.long_description || '', 100)}
                </Text>
                <VStack align="start" mt={2} spacing={0}>
                  <Text fontSize="xs">Work RVU: {item.work_rvu || 'N/A'}</Text>
                  <Text fontSize="xs">Non-Facility Total: {item.non_facility_total || 'N/A'}</Text>
                  <Text fontSize="xs">Facility Total: {item.facility_total || 'N/A'}</Text>
                  <Text fontSize="xs">Est. Non-Facility Reimb: ${item.estimated_non_facility_reimbursement?.toFixed(2) || 'N/A'}</Text>
                  <Text fontSize="xs">Est. Facility Reimb: ${item.estimated_facility_reimbursement?.toFixed(2) || 'N/A'}</Text>
                </VStack>
              </Box>
            );
          })}
      </VStack>
    );
  };

  const selectedAnalysis = analysisResults.find(
    (result) => result.cpt_code === selectedCptCode
  )?.analysis;

  return (
    <Box>
      <HeaderMenus />
      <Container maxW="container.xl" py={8}>
        <Heading as="h1" mb={6}>
          Medical Necessity Analysis
        </Heading>
        <form onSubmit={handleSubmit}>
          <HStack spacing={4} mb={4}>
            <Input
              name="case_id"
              placeholder="Case ID"
              required
              value={caseId}
              onChange={(e) => setCaseId(e.target.value)}
              width="200px"
            />
            <Select
              name="payer"
              value={payer}
              onChange={(e) => setPayer(e.target.value)}
              width="200px"
            >
              <option value="cms">Medicare</option>
              <option value="aetna">Aetna</option>
            </Select>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              Analyze
            </Button>
            <Button
              onClick={handleGenerateOverview}
              colorScheme="green"
              isLoading={isGeneratingOverview}
            >
              Generate Overview
            </Button>
            <Button
              onClick={handleCptCoding}
              colorScheme="purple"
              isLoading={isCptCoding}
            >
              Run CPT Coding
            </Button>
          </HStack>
        </form>

        {CPTCodesData && Object.keys(CPTCodesData).length > 0 && (
          <Grid templateColumns="250px 1fr" gap={6}>
            <GridItem>{renderCptCodes()}</GridItem>
            <GridItem>
              {selectedAnalysis && (
                <Box>
                  <Tabs>
                    <TabList>
                      <Tab>Analysis Results</Tab>
                      <Tab>Sources</Tab>
                      <Tab>Case Overview</Tab>
                      {cptCodingResults && <Tab>CPT Coding Results</Tab>}
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <Heading as="h2" size="lg" mb={4}>
                          Analysis Results for {selectedCptCode}
                        </Heading>
                        <Text mb={4}>
                          Payer:{' '}
                          {analysisPayer === 'cms'
                            ? 'Medicare'
                            : analysisPayer === 'aetna'
                            ? 'Aetna'
                            : analysisPayer}
                        </Text>
                        <Text fontWeight="bold" mb={2}>
                          Explanation:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.explanation)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold" mt={4} mb={2}>
                          Key Factors:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.key_factors)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold" mb={2}>
                          Recommendations:
                        </Text>
                        <Box mb={4}>
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {addLinksToReferences(selectedAnalysis.recommendations)}
                          </ReactMarkdown>
                        </Box>

                        <Text fontWeight="bold">Score: {selectedAnalysis.score}</Text>
                      </TabPanel>

                      <TabPanel>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                          <GridItem>
                            <SourcesList
                              sources={selectedAnalysis.used_sources}
                              title="Used Sources"
                            />
                          </GridItem>
                          <GridItem>
                            <SourcesList
                              sources={selectedAnalysis.unused_sources}
                              title="Unused Sources"
                            />
                          </GridItem>
                        </Grid>
                      </TabPanel>

                      <TabPanel>
                        <Heading as="h2" size="lg" mb={4}>
                          Case Overview
                        </Heading>
                        
                        {caseOverview ? (
                            <ReactMarkdown>
                              {caseOverview.overview}
                            </ReactMarkdown>
                        ) : (
                          <Text>No case overview available. Click "Generate Overview" to create one.</Text>
                        )}
                      </TabPanel>

                      {cptCodingResults && (
                        <TabPanel>
                          <Heading as="h2" size="lg" mb={4}>
                            CPT Coding Results
                          </Heading>
                          {Object.entries(cptCodingResults).map(([cptCode, decision]) => (
                            <Box key={cptCode} mb={4} p={4} borderWidth={1} borderRadius="md">
                              <Heading as="h3" size="md" mb={2}>
                                CPT Code: {cptCode}
                              </Heading>
                              <Text fontWeight="bold">Decision: {decision.decision}</Text>
                              <Text mt={2}>Discussion:</Text>
                              <Text>{decision.discussion}</Text>
                            </Box>
                          ))}
                        </TabPanel>
                      )}
                    </TabPanels>
                  </Tabs>
                </Box>
              )}
            </GridItem>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default MedicalNecessityGeneral;