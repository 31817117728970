//ProviderEncountersTable.js
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Input, Button, Flex, Link, Text,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  useClipboard
} from "@chakra-ui/react";
import { AppContext } from '../components/AppContext';
import { callApi } from '../callApi';
import HeaderMenus from './HeaderMenus';
import { Select, Spinner } from "@chakra-ui/react";

function FullTextPopup({ isOpen, onClose, title, content }) {
  const { hasCopied, onCopy } = useClipboard(content);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text whiteSpace="pre-wrap">{content}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onCopy}>
            {hasCopied ? "Copied!" : "Copy to Clipboard"}
          </Button>
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
function GeneratedNoteModal({ isOpen, onClose, note, isLoading }) {
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generated Note</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <Text whiteSpace="pre-wrap">{note}</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ProviderEncountersTableAdmin() {
  const [encounters, setEncounters] = useState([]);
  const [loading, setLoading] = useState(true);
  const { accessToken } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState([]);
  const [popupContent, setPopupContent] = useState({ isOpen: false, title: '', content: '' });
  const rowsPerPage = 30;

  const [caseTypes, setCaseTypes] = useState([]);
  const [selectedCaseType, setSelectedCaseType] = useState('');
  const [isGeneratingNote, setIsGeneratingNote] = useState(false);
  const [generatedNote, setGeneratedNote] = useState('');

  useEffect(() => {
    fetchEncounters();
  }, [accessToken]);

  const truncateText = (text, maxLength = 100) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || '';
  };

  const generateNote = async (structuredTranscript, localselectedCaseType) => {
    if (!localselectedCaseType) {
      alert("Please select a case type first.");
      return;
    }
    setIsGeneratingNote(true);
    console.log('selectedCaseType:', localselectedCaseType);
    console.log('structuredTranscript:', structuredTranscript);
    try {
      const response = await callApi('/generate_note_from_data', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sent_data: structuredTranscript,
          case_type: localselectedCaseType,
          display_only: true
        }),
      });
      const data = await response.json();
      setGeneratedNote(data.note);
    } catch (error) {
      console.error('Error generating note:', error);
    } finally {
      setIsGeneratingNote(false);
    }
  };


  const fetchEncounters = async () => {
    try {
      const response = await callApi('/case_types', accessToken);
      const data = await response.json();
      setCaseTypes(data);
      console.log('Case types:', data);
    } catch (error) {
      console.error('Error fetching case types:', error);
    }

    try {
      const response = await callApi('/get_encounters', accessToken);
      const data = await response.json();
      setEncounters(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching encounters:', error);
      setLoading(false);
    }
  };

  const handleShowFullText = (title, content) => {
    setPopupContent({ isOpen: true, title, content });
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Encounter Date', accessor: 'encounter_date' },
      {
        Header: 'Generate Note',
        accessor: 'generate_note',
        Cell: ({ row }) => (
          <Flex direction="column" alignItems="flex-start">
            {caseTypes.map((caseType) => (
              <Button
                key={caseType.id}
                onClick={() => generateNote(row.original.structured_transcript, caseType.id)}
                variant="link"
                size="sm"
                mb={1}
              >
                {caseType.name.replace(/_/g, ' ')+ ' template'}
              </Button>
            ))}
          </Flex>
        )
      },
      { 
        Header: 'Public Note ID', 
        accessor: 'public_note_id',
        Cell: ({ value }) => (
          <a 
            href={`/noteanalyze/${value}`} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {value}
          </a>
        )
      },
      { 
        Header: 'Status', 
        accessor: 'has_clean_content',
        Cell: ({ value }) => (value ? 'Cleaned' : 'Not Cleaned')
      },
      {
        Header: 'View',
        accessor: 'view',
        Cell: ({ row }) => (
          <Button
            as="a"
            href={`/encounter/${row.original.provider_encounter_id}`}
            target="_blank"
            rel="noopener noreferrer"
            colorScheme="blue"
          >
            View Details
          </Button>
        )
      },      
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <Button
            as="a"
            href={`/adminreview/${row.original.provider_encounter_id}`}
            target="_blank"
            rel="noopener noreferrer"
            colorScheme="blue"
          >
            {row.original.has_clean_content ? 'Edit' : 'Clean'}
          </Button>
        )
      },
      { Header: 'Encounter Code', accessor: 'encounter_code' },
      { Header: 'External Patient ID', accessor: 'external_patient_id' },
      { Header: 'Audio Length (KB)', accessor: 'audio_length_kb' },
      { 
        Header: 'Note Content', 
        accessor: 'note_content',
        Cell: ({ value }) => (
          <Box>
            <Text>{truncateText(value)}</Text>
            <Link color="blue.500" onClick={() => handleShowFullText('Note Content', value)}>
              View Full Note
            </Link>
          </Box>
        )
      },
      { 
        Header: 'Raw Transcript', 
        accessor: 'raw_transcript',
        Cell: ({ value }) => (
          <Box>
            <Text>{truncateText(value)}</Text>
            <Link color="blue.500" onClick={() => handleShowFullText('Raw Transcript', value)}>
              View Full Transcript
            </Link>
          </Box>
        )
      },
      { 
        Header: 'Structured Transcript', 
        accessor: 'structured_transcript',
        Cell: ({ value }) => (
          <Box>
            <Text>{truncateText(value)}</Text>
            <Link color="blue.500" onClick={() => handleShowFullText('Structured Transcript', value)}>
              View Full Structured Transcript
            </Link>
          </Box>
        )
      },
      { 
        Header: 'Patient Content', 
        accessor: 'patient_content',
        Cell: ({ value }) => (
          <Box>
            <Text>{truncateText(value)}</Text>
            <Link color="blue.500" onClick={() => handleShowFullText('Patient Content', value)}>
              View Full Patient Content
            </Link>
          </Box>
        )
      },
      { Header: 'Encounter ID', accessor: 'provider_encounter_id' },
      { Header: 'Provider Encounter ID', accessor: 'provider_encounter_external_encounter_id' },

    ],
    [caseTypes]
  );

  const sortedAndFilteredData = useMemo(() => {
    let filteredData = encounters.filter((encounter) =>
      Object.values(encounter).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    if (sortBy.length) {
      const { id, desc } = sortBy[0];
      filteredData.sort((a, b) => {
        if (a[id] < b[id]) return desc ? 1 : -1;
        if (a[id] > b[id]) return desc ? -1 : 1;
        return 0;
      });
    }

    return filteredData;
  }, [encounters, searchTerm, sortBy]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: paginatedData,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: { sortBy: sortBy },
    },
    useSortBy
  );

  const handleSort = (column) => {
    const isDesc = sortBy[0]?.id === column.id && !sortBy[0]?.desc;
    setSortBy([{ id: column.id, desc: isDesc }]);
  };

  const totalPages = Math.ceil(sortedAndFilteredData.length / rowsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>


      
      <HeaderMenus />
      <Box padding={4}>
        <Flex justifyContent="space-between" mb={4}>
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            width="300px"
          />
<Select
            value={selectedCaseType}
            onChange={(e) => setSelectedCaseType(e.target.value)}
            placeholder="Select case type"
            width="200px"
          >
            {caseTypes.map((caseType) => (
              <option key={caseType.id} value={caseType.id}>
                {caseType.name}
              </option>
            ))}
          </Select>

          <Flex>
            <Button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              mr={2}
            >
              Previous
            </Button>
            <Box alignSelf="center" mr={2}>
              Page {currentPage} of {totalPages}
            </Box>
            <Button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Flex>
        </Flex>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps()} onClick={() => handleSort(column)}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
      <FullTextPopup
        isOpen={popupContent.isOpen}
        onClose={() => setPopupContent({ ...popupContent, isOpen: false })}
        title={popupContent.title}
        content={popupContent.content}
      />

    <GeneratedNoteModal
      isOpen={isGeneratingNote || generatedNote !== ''}
      onClose={() => {
        setIsGeneratingNote(false);
        setGeneratedNote('');
      }}
      note={generatedNote}
      isLoading={isGeneratingNote}
    />
    </Box>
  );
}

export default ProviderEncountersTableAdmin;