import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

// Create a new context 
export const AppContext = React.createContext();

// Create a provider component
export function AppContextProvider({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  const [appState, setAppState] = useState({
    caseId: null,
    generatedNote: null,
    generatedNoteText: null,
    payer: null,
    // Add more state variables here as needed
  });
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [colorMode, setColorMode] = useState('light');


  useEffect(() => {
    const fetchAccessToken = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
            scope: "read:current_user",
          },
          detailedResponse: true,
        });
        setAccessToken(token.access_token);
        setError(null);
      } catch (error) {
        console.error('Error getting access token', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessToken();
  }, [getAccessTokenSilently]);

  const toggleColorMode = () => {
    setColorMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
  };

  // The value that will be provided to consuming components
  const value = { 
    appState, 
    setAppState, 
    accessToken, 
    loading, 
    error, 
    colorMode, 
    toggleColorMode 
  };

  return (
    <AppContext.Provider value={value}>
      <ChakraProvider>
        <ColorModeScript initialColorMode={colorMode} />
        {children}
      </ChakraProvider>
    </AppContext.Provider>
  );
}