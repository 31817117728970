import React, { useState, useEffect, useContext } from 'react';
import NoSleep from 'nosleep.js';
import { callApi } from '../callApi';
import ReactMarkdown from 'react-markdown';
import {
  Box, Grid, GridItem, Flex, Image, VStack, Text, Button, Input, FormLabel, Spinner, HStack, FormControl,
  Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Link, Badge, Tooltip, CircularProgress,
  Icon
} from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import HeaderMenus from './HeaderMenus';
import ChatToggle from './ChatToggle';
import AudioVolumeVisualizer from './AudioVolumeVisualizer';
import { AppContext } from './AppContext';
import { AddIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import { FaMicrophone, FaStop, FaCloudUploadAlt } from 'react-icons/fa';

import { CopyIcon, EditIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import { DownloadIcon } from '@chakra-ui/icons';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';


const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

const theme = extendTheme({ colors });

function GenerateTranscript() {
  // Generate a random 6-digit number for Patient ID
  const generateRandomId = () => {
    return "Demo_ZZ" + Math.floor(100000 + Math.random() * 900000);
  };

  const [recordingState, setRecordingState] = useState('ready');
  const [transcribeState, setTranscribeState] = useState('ready');
  const [priorProviderEncounterId, setPriorProviderEncounterId] = useState('');

  const [audioData, setAudioData] = useState(null);
  const [transcribedText, setTranscribedText] = useState('');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [timer, setTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [providerEncounterId, setProviderEncounterId] = useState(generateRandomId());
  const [fileSize, setFileSize] = useState(null);
  const [fileDuration, setFileDuration] = useState(null);
  const { accessToken } = useContext(AppContext);
  const isSupportedBrowser = /Chrome/.test(navigator.userAgent) || 
    (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor));
  const [fullResponse, setFullResponse] = useState(null);
  const [structuredTranscript, setStructuredTranscript] = useState('');
  const [note, setNote] = useState('');
  const [icd10Codes, setIcd10Codes] = useState([]);
  const [cptCode, setCptCode] = useState('');
  const [noteId, setNoteId] = useState('');
  const [noSleep, setNoSleep] = useState(new NoSleep());
  const [uploadedFile, setUploadedFile] = useState(null);
  const [transcriptions, setTranscriptions] = useState([]);
  const [isLoadingTranscriptions, setIsLoadingTranscriptions] = useState(false);
  
  const [audioContext, setAudioContext] = useState(null);
  const [sourceNode, setSourceNode] = useState(null);
  const [gainNode, setGainNode] = useState(null);  
  const [processedStream, setProcessedStream] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();





  const fetchTranscriptions = async () => {
    setIsLoadingTranscriptions(true);
    try {
      const response = await callApi('/transcriptions', accessToken);
      if (response.ok) {
        const data = await response.json();
        setTranscriptions(data);
      } else {
        console.error('Failed to fetch transcriptions');
      }
    } catch (error) {
      console.error('Error fetching transcriptions:', error);
    } finally {
      setIsLoadingTranscriptions(false);
    }
  };
  
  // Add this function to download audio
  const downloadTranscriptionAudio = async (transcriptionId) => {
    try {
      const response = await callApi(`/transcriptions/${transcriptionId}/audio`, accessToken);
      if (response.ok) {
        // Create a blob from the response
        const blob = await response.blob();
        
        // Create a download link and trigger it
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        
        // Get the filename from the Content-Disposition header if available
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = `transcription_${transcriptionId}.m4a`;
        
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1];
          }
        }
        
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        
        toast({
          title: "Download started",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Download failed",
          description: "Could not download the audio file",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error downloading audio:', error);
      toast({
        title: "Download error",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  // Add this useEffect to load transcriptions when the component mounts
  useEffect(() => {
    if (accessToken) {
      fetchTranscriptions();
    }
  }, [accessToken]);
  
  // Add this useEffect to refresh transcriptions after a new recording is processed
  useEffect(() => {
    if (transcribeState === 'started') {
      fetchTranscriptions();
    }
  }, [transcribeState]);





  const handleCopy = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      toast({
        title: "Copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast({
        title: "Failed to copy",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const handleEdit = () => {
    window.open(`/note/?id=${noteId}&version=1&nid=&view=`, '_blank');
  };

  const ExportModal = () => {
    const [exportedTo, setExportedTo] = useState(null);
  
    const handleExport = (ehr) => {
      setExportedTo(ehr);
    };
  
    return (
      <Modal isOpen={isOpen} onClose={() => { onClose(); setExportedTo(null); }} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!exportedTo ? (
              <VStack spacing={4}>
                <Button 
                  leftIcon={<Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Epic_Systems.svg/1600px-Epic_Systems.svg.png?20220204054501" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('Epic')}
                >
                  Export to Epic
                </Button>
                <Button 
                  leftIcon={<Image src="https://triceimaging.com/wp-content/uploads/2015/10/athena-health-logo1-440x61.png" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('Athena')}
                >
                  Export to Athena
                </Button>
                <Button 
                  leftIcon={<Image src="https://seeklogo.com/images/N/nextgen-healthcare-logo-D35E27087E-seeklogo.com.png" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('NextGen')}
                >
                  Export to NextGen
                </Button>
                <Button 
                  leftIcon={<Image src="https://mma.prnewswire.com/media/1749045/Cerner_logo_Logo.jpg?w=200" height="20px" />} 
                  width="100%"
                  onClick={() => handleExport('Cerner')}
                >
                  Export to Cerner
                </Button>
              </VStack>
            ) : (
              <VStack spacing={4} align="center">
                <Text fontSize="lg" fontWeight="medium" color="green.500">
                  Success, exported to the {exportedTo} encounter record.
                </Text>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const handleUploadedFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'audio/mpeg' || file.type === 'audio/mp3' || file.type === 'audio/x-m4a')) {
      setUploadedFile(file);
    } else {
      alert('Please upload an MP3 file.');
      setUploadedFile(null);
    }
  };

  const transcribeUploadedFile = async () => {
    if (!uploadedFile) {
      alert('Please upload an MP3 file first.');
      return;
    }
  
    setRecordingState('transcribing');
    await sendAudioData(uploadedFile);
    setRecordingState('ready');
    setUploadedFile(null);
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const clearData = () => {
    setTranscribedText('');
    setStructuredTranscript('');
    setNote('');
    setIcd10Codes([]);
    setCptCode('');
    setFullResponse(null);
    setFileSize(null);
    setFileDuration(null);
    setNoteId('');
  };

  const startRecording = async () => {
    clearData();
    noSleep.enable();
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          channelCount: 2,
          sampleRate: 44100,
          sampleSize: 16
        }
      });
      setTranscribeState('ready');
      setMediaStream(stream);
      const recorder = new MediaRecorder(stream, { mimeType: 'audio/mp4' });
      setMediaRecorder(recorder);
  
      const chunks = [];
      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
          console.log('Chunk added, size:', e.data.size);
        }
      };
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/webm' });
        console.log('Recording stopped, blob size:', blob.size);
        setAudioData(blob);
        setFileSize(blob.size);
        transcribeAudio(blob);
        setRecordingState('stopped');
      };
  
      recorder.start(1000); // Request data every second
      setRecordingState('recording');
      startTimer();
    
      window.addEventListener('beforeunload', handleBeforeUnload);
    } catch (error) {
      console.error('Error accessing the microphone', error);
    }
  };
  
  const stopRecording = () => {
    noSleep.disable();
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      console.log('MediaRecorder stopped');
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
    }
    setRecordingState('stopped');
    setFileDuration(timer);
    stopTimer();
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };

  const startTimer = () => {
    setTimer(0);
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const stopTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  const transcribeAudio = async (audioBlob) => {
    const blobToUse = audioBlob || audioData;
    if (!blobToUse) {
      console.error('No audio data to transcribe');
      return;
    } 

    setRecordingState('transcribing');
    await sendAudioData(blobToUse);
    setRecordingState('ready');
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const sendAudioData = async (audioData) => {
    console.log('Sending audio data:', audioData);
    const formData = new FormData();
    formData.append('audio', audioData, audioData.name || 'audio.webm');
    formData.append('external_patient_id', providerEncounterId);
    formData.append('analysis_type', 'coding');
    formData.append('background_task', 'True');

    const response = await callApi('/v1/encounter-analysis', accessToken, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    setFullResponse(data);
    setTranscribedText(data.coding?.codes?.transcribed_text || '');
    setStructuredTranscript(data.coding?.codes?.structured_transcript || '');
    setNote(data.coding?.codes?.note || '');
    setIcd10Codes(Object.entries(data.coding?.codes?.results?.icd10 || {}));
    setCptCode(data.coding?.codes?.results?.cpt_em?.code || '');
    setNoteId(data.coding?.codes?.note_id || ''); // Set noteId
    setTranscribeState('started');
    setPriorProviderEncounterId(providerEncounterId);
  };

  return (
    <Box textAlign="center" fontSize="xl" bg="white">
      <style jsx global>{`
        @keyframes pulse {
          0% { opacity: 1; }
          50% { opacity: 0.5; }
          100% { opacity: 1; }
        }
      `}</style>
      <Grid
        templateAreas={`"header header"    
                        "main results"
                        "fullResponse fullResponse"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 800px 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
      >
        <GridItem area={'header'}>
          <HeaderMenus />
        </GridItem>

        <GridItem bg='gray.50' padding={4} area={'main'}>
          <Box borderWidth="1px" borderRadius="lg" p={5} bg="white" shadow="sm">
            <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="left">Audio Recording</Text>
            
            <FormControl mb={5}>
              <Flex alignItems="center">
                <Tooltip label="Enter a unique identifier for this patient encounter">
                  <FormLabel htmlFor="provider-encounter-id" mb={0} mr={3} width="80px">Patient ID:</FormLabel>
                </Tooltip>
                <Input
                  id="provider-encounter-id"
                  value={providerEncounterId}
                  onChange={(e) => setProviderEncounterId(e.target.value)}
                  mr={3}
                />
                <Button 
                  onClick={() => setProviderEncounterId(generateRandomId())} 
                  size="sm" 
                  colorScheme="blue" 
                  variant="outline"
                >
                  New ID
                </Button>
              </Flex>
            </FormControl>
            
            {isSupportedBrowser ? (
              <VStack spacing={5} align="stretch">
                <Flex align="center" justify="center" mb={2}>
                  {recordingState === 'recording' ? (
                    <Badge colorScheme="red" p={2} borderRadius="full" display="flex" alignItems="center">
                      <Box as="span" w={3} h={3} borderRadius="full" bg="red.500" mr={2} 
                        sx={{ animation: "pulse 1.5s infinite" }} />
                      Recording: {formatTime(timer)}
                    </Badge>
                  ) : (
                    <Badge colorScheme="green" p={2}>Ready to record</Badge>
                  )}
                </Flex>
                
                <HStack spacing={4} justify="center">
                  <Button
                    onClick={startRecording}
                    isDisabled={recordingState !== 'ready' || !providerEncounterId.trim()}
                    colorScheme="blue"
                    leftIcon={<Icon as={FaMicrophone} />}
                    size="lg"
                    px={8}
                  >
                    Start Recording
                  </Button>
                  <Button
                    onClick={stopRecording}
                    isDisabled={recordingState !== 'recording'}
                    colorScheme="red"
                    leftIcon={<Icon as={FaStop} />}
                    size="lg"
                    px={8}
                  >
                    Stop Recording
                  </Button>
                </HStack>
                
                {recordingState === 'recording' && mediaStream && (
                  <Box mt={4}>
                    <AudioVolumeVisualizer stream={mediaStream} />
                  </Box>
                )}
                
                {transcribeState === 'started' && (
                  <Box 
                    mt={4} 
                    p={4} 
                    borderWidth="1px" 
                    borderRadius="md" 
                    borderColor="green.200" 
                    bg="green.50"
                  >
                    <VStack align="stretch" spacing={2}>
                      <Text fontWeight="medium">Audio Saved for Patient ID: {priorProviderEncounterId}</Text>
                      <Text>Transcription Available on Encounters Page</Text>
                      <HStack justify="center" spacing={8} mt={2}>
                        <Badge colorScheme="blue">Duration: {formatTime(fileDuration)}</Badge>
                        <Badge colorScheme="purple">File size: {(fileSize / 1024 / 1024).toFixed(2)} MB</Badge>
                      </HStack>
                    </VStack>
                  </Box>
                )}
                
                {recordingState === 'transcribing' && (
                  <Box textAlign="center" p={4}>
                    <CircularProgress isIndeterminate color="blue.300" />
                    <Text mt={2}>Transcribing your audio...</Text>
                  </Box>
                )}
              </VStack>
            ) : (
              <Box 
                p={4} 
                borderWidth="1px" 
                borderRadius="md" 
                borderColor="orange.200" 
                bg="orange.50"
              >
                <Text>
                  The audio recording feature is not available. Please use Chrome on desktop or Safari on iOS.
                </Text>
              </Box>
            )}
            
            <Box mt={6} pt={4} borderTopWidth="1px" borderColor="gray.200">
              <Text fontSize="md" fontWeight="medium" mb={3} textAlign="left">Or upload an audio file</Text>
              
              <Flex 
                border="2px dashed" 
                borderColor="gray.300" 
                borderRadius="md" 
                p={4} 
                direction="column" 
                align="center" 
                justify="center"
                bg="gray.50"
                _hover={{ borderColor: "blue.300", bg: "blue.50" }}
                transition="all 0.2s"
                cursor="pointer"
                onClick={() => {
                  const input = document.getElementById('upload-audio-file-input');
                  if (input) {
                    input.click();
                  }
                }}
              >
                <Icon as={FaCloudUploadAlt} w={8} h={8} color="gray.400" mb={2} />
                <Text>{uploadedFile ? uploadedFile.name : "Drop audio file here or click to browse"}</Text>
                <Input
                  id="upload-audio-file-input"
                  type="file"
                  accept=".mp3,audio/mpeg,audio/mp3,.m4a,audio/x-m4a"
                  onChange={handleUploadedFileChange}
                  style={{ display: 'none' }}
                />
              </Flex>
              
              {uploadedFile && (
                <Button
                  onClick={transcribeUploadedFile}
                  isDisabled={recordingState === 'transcribing'}
                  colorScheme="teal"
                  mt={3}
                  width="100%"
                >
                  Transcribe Uploaded File
                </Button>
              )}
            </Box>
          </Box>
        </GridItem>

        <GridItem bg='gray.50' padding={4} area={'results'} align="left">
          <Box borderWidth="1px" borderRadius="lg" p={5} bg="white" shadow="sm" height="100%" overflowY="auto">
            <Text fontSize="xl" fontWeight="bold" mb={4}>Results</Text>
            
            <VStack align="stretch" spacing={4}>
              {noteId ? (
                <Accordion allowMultiple allowToggle defaultIndex={[0]}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          Note
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <HStack spacing={4} mb={3}>
                        <Link onClick={() => handleCopy(note)}>
                          <HStack>
                            <CopyIcon />
                            <Text>Copy</Text>
                          </HStack>
                        </Link>
                        <Link onClick={handleEdit}>
                          <HStack>
                            <EditIcon />
                            <Text>Edit</Text>
                          </HStack>
                        </Link>
                        <Link onClick={onOpen}>
                          <HStack>
                            <ExternalLinkIcon />
                            <Text>Export</Text>
                          </HStack>
                        </Link>
                      </HStack>
                      
                      <Box 
                        p={3} 
                        borderWidth="1px" 
                        borderRadius="md" 
                        borderColor="gray.200"
                        bg="gray.50"
                        fontSize="md"
                        fontWeight="normal"
                      >
                        <Text whiteSpace="pre-wrap">{note}</Text>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          CPT E/M Code
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <HStack spacing={4} mb={3}>
                        <Link onClick={() => handleCopy(cptCode)}>
                          <HStack>
                            <CopyIcon />
                            <Text>Copy</Text>
                          </HStack>
                        </Link>
                        <Link onClick={onOpen}>
                          <HStack>
                            <ExternalLinkIcon />
                            <Text>Export</Text>
                          </HStack>
                        </Link>
                      </HStack>
                      
                      <Badge colorScheme="blue" fontSize="md" p={2}>
                        {cptCode}
                      </Badge>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          ICD-10 Codes
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <HStack spacing={4} mb={3}>
                        <Link onClick={() => handleCopy(icd10Codes.map(([code, details]) => `${code}: ${details.description}`).join('\n'))}>
                          <HStack>
                            <CopyIcon />
                            <Text>Copy</Text>
                          </HStack>
                        </Link>
                        <Link onClick={onOpen}>
                          <HStack>
                            <ExternalLinkIcon />
                            <Text>Export</Text>
                          </HStack>
                        </Link>
                      </HStack>
                      
                      <VStack align="stretch" spacing={2}>
                        {icd10Codes.map(([code, details]) => (
                          <Box 
                            key={code} 
                            p={2} 
                            borderWidth="1px" 
                            borderRadius="md" 
                            borderColor="blue.100"
                            bg="blue.50"
                          >
                            <Text fontWeight="bold">{code}</Text>
                            <Text>{details.description}</Text>
                          </Box>
                        ))}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          Structured Transcript
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <HStack spacing={4} mb={3}>
                        <Link onClick={() => handleCopy(structuredTranscript)}>
                          <HStack>
                            <CopyIcon />
                            <Text>Copy</Text>
                          </HStack>
                        </Link>
                      </HStack>
                      
                      <Box 
                        p={3} 
                        borderWidth="1px" 
                        borderRadius="md" 
                        borderColor="gray.200"
                        bg="gray.50"
                        fontSize="md"
                        fontWeight="normal"
                        maxHeight="400px"
                        overflowY="auto"
                      >
                        <Text whiteSpace="pre-wrap">{structuredTranscript}</Text>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          Transcribed Text
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <HStack spacing={4} mb={3}>
                        <Link onClick={() => handleCopy(transcribedText)}>
                          <HStack>
                            <CopyIcon />
                            <Text>Copy</Text>
                          </HStack>
                        </Link>
                      </HStack>
                      
                      <Box 
                        p={3} 
                        borderWidth="1px" 
                        borderRadius="md" 
                        borderColor="gray.200"
                        bg="gray.50"
                        fontSize="md"
                        fontWeight="normal"
                        maxHeight="400px"
                        overflowY="auto"
                      >
                        <Text whiteSpace="pre-wrap">{transcribedText}</Text>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                <Box 
                  p={6} 
                  textAlign="center" 
                  borderWidth="1px" 
                  borderRadius="md" 
                  borderColor="gray.200"
                  bg="gray.50"
                >
                  <Text color="gray.500">
                    Record or upload audio to see results here
                  </Text>
                </Box>
              )}
            </VStack>
            <ExportModal />
          </Box>
        </GridItem>


        <GridItem bg='gray.50' padding={4} area={'fullResponse'} align="left" verticalAlign={'top'}>
  <Box borderWidth="1px" borderRadius="lg" p={4} bg="white" shadow="sm">
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="medium">
              Full Response
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {fullResponse ? (
            <Box 
              as="pre" 
              bg="gray.100" 
              p={4} 
              borderRadius="md" 
              overflowX="auto"
              fontSize="sm"
              maxHeight="600px"
              overflowY="auto"
            >
              <code>{JSON.stringify(fullResponse, null, 2)}</code>
            </Box>
          ) : (
            <Text color="gray.500" textAlign="center">
              No response data available
            </Text>
          )}
        </AccordionPanel>
      </AccordionItem>
      
      {/* Add the Transcriptions section */}
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="medium">
              My Transcriptions
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {isLoadingTranscriptions ? (
            <Flex justify="center" p={4}>
              <Spinner />
            </Flex>
          ) : transcriptions.length > 0 ? (
<TableContainer>
  <Table variant="simple" size="sm">
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>Created</Th>
        <Th>Size</Th>
        <Th>Preview</Th>
        <Th>Actions</Th>
      </Tr>
    </Thead>
    <Tbody>
      {transcriptions.map((t) => (
        <Tr key={t.transcription_id}>
          <Td>{t.transcription_id}</Td>
          <Td>{new Date(t.created_at).toLocaleString()}</Td>
          <Td>{t.audio_size_kb ? `${t.audio_size_kb} KB` : 'N/A'}</Td>
          <Td>
            <Text noOfLines={1}>{t.text_preview}</Text>
          </Td>
          <Td>
            <Button
              size="sm"
              leftIcon={<DownloadIcon />}
              colorScheme="blue"
              variant="outline"
              onClick={() => downloadTranscriptionAudio(t.transcription_id)}
            >
              Download
            </Button>
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
</TableContainer>

          ) : (
            <Text color="gray.500" textAlign="center">
              No transcriptions available
            </Text>
          )}
          
          <Button 
            mt={4} 
            colorScheme="blue" 
            size="sm" 
            onClick={fetchTranscriptions}
            isLoading={isLoadingTranscriptions}
          >
            Refresh Transcriptions
          </Button>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </Box>
</GridItem>



        <GridItem
          bg='white'
          borderTop='1px'
          borderColor='brand.200'
          paddingY={4}
          paddingX={6}
          area={'footer'}
        >
          <Flex justifyContent="center" alignItems="center">
            <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
            <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
              &copy; {new Date().getFullYear()}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      <ChatToggle
        contextType={'revedy'}
        contextData={'revedy_scribe_services'}
        contextPersona={'revedy_scribe_services'}
        firstMessage={"Hi, what can I do on this page?"}
        useOptions={false}
        maintainContext={true}
        contextMode={'popup'}
      />
    </Box>    
  );
}

export default GenerateTranscript;
