import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, Text, Spinner, MenuDivider,GridItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';
import { Flex, Image } from "@chakra-ui/react";
import { useFeatureFlags } from './FeatureFlags';  // Import your useFeatureFlags hook
import { ThemeProvider, CSSReset, ColorModeProvider } from "@chakra-ui/react";
import { nmaTheme, revedyTheme, theosTheme, hopdocTheme, usmonTheme, memorycareaiTheme } from '../themes';  // Import your themes
import { Button, useColorMode } from '@chakra-ui/react';

import { useContext } from 'react';
import { AppContext } from './AppContext';

const AccountMenu = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  //const { featureFlags } = useFeatureFlags();

  if (!isAuthenticated) {
    return (
      (  
      <Menu>
        <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
          Get Started
        </MenuButton>
        <MenuList>
          <MenuItem isDisabled fontSize="sm" color="black">Login Required</MenuItem>
        </MenuList>
      </Menu>
      )
    );
  }


  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  if (isLoading) {
    return <Spinner />;
  }

  //const displayEmail = featureFlags && featureFlags['email'] ? featureFlags['email'] : user.email;
  const displayEmail = user.email;

  return (
    <Menu>
      <MenuButton as={Text} rightIcon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Account
      </MenuButton>
      <MenuList>
        <MenuItem isDisabled fontSize="sm" color="black">
          {displayEmail}
        </MenuItem>
        <MenuItem onClick={handleLogout} fontSize="sm" color="black">Log Out</MenuItem>
      </MenuList>
    </Menu>
  ) 
};


const AccountMenuLoggedIn = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const { featureFlags } = useFeatureFlags();

  if (!isAuthenticated) {
    return (
      (  
      <Menu>
        <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
          Get Started
        </MenuButton>
        <MenuList>
          <MenuItem isDisabled fontSize="sm" color="black">Login Required</MenuItem>
        </MenuList>
      </Menu>
      )
    );
  }


  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  if (isLoading) {
    return <Spinner />;
  }

  const displayEmail = featureFlags && featureFlags['email'] ? featureFlags['email'] : user.email;

  return (
    <Menu>
      <MenuButton as={Text} rightIcon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Account
      </MenuButton>
      <MenuList>
        <MenuItem isDisabled fontSize="sm" color="black">
          {displayEmail}
        </MenuItem>
        <MenuItem onClick={handleLogout} fontSize="sm" color="black">Log Out</MenuItem>
      </MenuList>
    </Menu>
  ) 
};



const MenuVersionDefault = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <GridItem
      bg='header_background'
      borderBottom='1px'
      borderColor='brand.200'
      paddingY={4}
      paddingX={6}
      area={'header'}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
        <Image src="/images/revedy_logo.png" alt="Revedy" height={["15px"]} onClick={() => handleItemClick('/home')} />
        <Flex style={{ gap: '20px' }}>
        <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/home')}>
            Home
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/note')}>
            Code Notes
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/generate')}>
            Create Notes
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/chat')}>
            Interactive
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/ionmtext')}>
            Code IONM CPT
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/api-docs')}>
            API
          </Text>
          
          <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        More
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Other
        </MenuItem>
        {/* Add Integration Services as a new MenuItem */}
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/analyticsdemo')}>
          Integration Services
        </MenuItem>
        {/* Move the Coming Soon text into a MenuItem */}
        <MenuItem pl={5} fontSize="sm" textDecoration="underline" color="menu_text_secondary" isDisabled>
          More Features Coming Soon
        </MenuItem>
        {/* Existing menu items */}
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/transition')}>
          Care Transitions (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/denials')}>
          Denials (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/CPT2024')}>
          CPT 2024 changes (beta)
        </MenuItem>
      </MenuList>
    </Menu>
        </Flex>
        <AccountMenu />
      </Flex>
    </GridItem>
  );
};


const defaultMenuStructure = {
  'logo': { 'src': '/images/revedy_logo.png', 'alt': 'Revedy', 'height': '15px', 'path': '/home' },
  'items': [
    { 'Home': '/home' },
    { 'Code Notes': '/note' },
    { 'Create Notes': '/generate' },
    { 'Interactive': '/chat' },
    { 'Code IONM CPT': '/ionmtext' },
    { 'API': '/api-docs' },
    { 'More': {
      'sub': [
        { 'Integration Services': '/analyticsdemo' },
        { 'Care Transitions (beta)': '/transition' },
        { 'Denials (beta)': '/denials' },
        { 'CPT 2024 changes (beta)': '/CPT2024' }
      ]
    }}
  ]
};



const HeaderMenus = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { featureFlags } = useFeatureFlags();
  const navigate = useNavigate();

  // Use the menu structure from feature flags if it exists, otherwise use the default
  const menuStructure = featureFlags['menu_structure'] || defaultMenuStructure;

  let theme = revedyTheme;
  if (featureFlags && featureFlags['menu_version']) {
    switch (featureFlags['menu_version']) {
      case 'nma': theme = nmaTheme; break;
      case 'usmon': theme = usmonTheme; break;
      case 'memorycareai': theme = memorycareaiTheme; break;
      case 'hopdoc': theme = hopdocTheme; break;
      case 'theos': theme = theosTheme; break;
      default: theme = revedyTheme;
    }
  }

  const renderMenuItem = (item) => {
    const [label, value] = Object.entries(item)[0];

    if (typeof value === 'string') {
      return (
        <Text 
          key={label}
          fontSize="sm" 
          textDecoration="underline" 
          color="menu_text_primary" 
          cursor="pointer" 
          onClick={() => navigate(value)}
        >
          {label}
        </Text>
      );
    }

    if (typeof value === 'object' && value.sub) {
      return (
        <Menu key={label}>
          <MenuButton as={Text} rightIcon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
            {label}
          </MenuButton>
          <MenuList>
            <MenuDivider />
            <MenuItem fontSize="sm" color="black" isDisabled>Other</MenuItem>
            {value.sub.map((subItem, index) => {
              const [subLabel, subPath] = Object.entries(subItem)[0];
              return (
                <MenuItem key={index} pl={5} fontSize="sm" color="black" onClick={() => navigate(subPath)}>
                  {subLabel}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      );
    }

    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <ColorModeProvider>
        <GridItem
          bg='header_background'
          borderBottom='1px'
          borderColor='brand.200'
          paddingY={4}
          paddingX={6}
          area={'header'}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
            {/* Logo and tagline container */}
            <Flex alignItems="center">
              <Image 
                src={menuStructure.logo.src} 
                alt={menuStructure.logo.alt} 
                height={[menuStructure.logo.height]} 
                onClick={() => navigate(menuStructure.logo.path)}
                cursor="pointer"
              />
              <Text 
                ml={3} 
                fontSize="sm" 
                color="menu_text_secondary" 
                fontStyle="italic"
              >
                Your AI Agent for RCM
              </Text>
            </Flex>
            <Flex style={{ gap: '20px' }}>
              {menuStructure.items.map((item, index) => renderMenuItem(item, index))}
            </Flex>
            <AccountMenuLoggedIn />
          </Flex>
        </GridItem>
      </ColorModeProvider>
    </ThemeProvider>
  );
};


export default HeaderMenus;
export { MenuVersionDefault };