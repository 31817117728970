import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Box, Text, Button, Grid, GridItem, Textarea, VStack, Heading,
  useToast, Tabs, TabList, TabPanels, Tab, TabPanel,
  Badge, HStack, Divider, Table, Thead, Tbody, Tr, Th, Td,
  Tag, TagLabel, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
  ModalBody, ModalCloseButton, useDisclosure, Icon, Card, CardHeader, CardBody, Spinner,
  Collapse, IconButton
} from "@chakra-ui/react";
import { AddIcon, AttachmentIcon, EditIcon, CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, DownloadIcon } from '@chakra-ui/icons';

import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus';
import { useLocation } from 'react-router-dom';
import { WebSocketProvider, useWebSocket } from './WebSocket'; 

function FullCPTCoder() {
  const [textContent, setTextContent] = useState('');
  const [currentCaseId, setCurrentCaseId] = useState(null);
  const [cptResults, setCptResults] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [cases, setCases] = useState([]);
  const [caseDetails, setCaseDetails] = useState(null);
  const { accessToken } = useContext(AppContext);
  const toast = useToast();
  const location = useLocation();
  const currentCaseIdRef = useRef(null);
  const [showContentEntry, setShowContentEntry] = useState(true);
  
  // Modal state for file content
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);

  const refreshIntervalRef = useRef(null);
  const { socket } = useWebSocket();

  useEffect(() => {
    currentCaseIdRef.current = currentCaseId;
  }, [currentCaseId]);

  // Automatically collapse content entry when CPT results are available
  useEffect(() => {
    if (cptResults) {
      setShowContentEntry(false);
    }
  }, [cptResults]);

  useEffect(() => {
    if (socket) {
      // Create a handler that uses the ref value
      const socketMessageReceiver = (rawData) => {
        // Get the current case ID from the ref at the moment the event fires
        const enhancedData = {
          ...rawData,
          client_current_case_id: currentCaseIdRef.current
        };
        
        // Pass the enhanced data to your handler
        handleCptCodesReceived(enhancedData);
      };
  
      // Listen for CPT coding completion events
      socket.on('cpt_codes', socketMessageReceiver);
      console.log('Socket listener for cpt_codes event added');
      
      return () => {
        socket.off('cpt_codes', socketMessageReceiver);
      };
    }
  }, [socket]);



    const handleFileDownload = async (file) => {
      try {
        // Show loading indicator
        setProcessing(true);
        
        const response = await callApi('/download_case_file', accessToken, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            case_id: currentCaseId,
            file_id: file.id 
          }),
        });
    
        if (response.ok) {
          const blob = await response.blob();
          
          // Create a download link and trigger the download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = file.file_name; // Use the file's original name
          document.body.appendChild(a);
          a.click();
          
          // Clean up
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 100);
          
          toast({
            title: 'File Download Started',
            description: `Downloading ${file.file_name}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error('Failed to download file');
        }
      } catch (error) {
        console.error('Error downloading file:', error);
        toast({
          title: 'Download failed',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setProcessing(false);
      }
    };  

  const handleCptCodesReceived = (data) => {
    if (data) {
        console.log('Received CPT codes via socket:', data);
        
        // Check if the updated case matches the currently viewed case
        if (data.case_id && data.case_id === data.client_current_case_id) {
            // Update the current case results directly from the socket data
            if (data.results) {
                setCptResults(data.results);
                // Auto-collapse content entry when results arrive
                setShowContentEntry(false);
            }
            
            // Also fetch the latest case details to ensure everything is up to date
            fetchCaseDetails(data.case_id);
            
            toast({
                title: 'Current Case Updated',
                description: 'CPT coding results have been updated for the case you were viewing',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } else {
            console.log('CPT coding complete for another case:', data.case_id, 'current case at time of reception:', data.client_current_case_id);
            toast({
                title: 'CPT Coding Complete',
                description: 'Results have been updated for another case',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        }
        
        // Refresh cases list to update status
        fetchCases();
    }
  };
  
  // Load existing cases on component mount and set up interval
  useEffect(() => {
    fetchCases();
    
    // Set up interval for refreshing cases every 5 seconds
    refreshIntervalRef.current = setInterval(() => {
      if (accessToken) {
        fetchCases();
      }
    }, 180000); 
    
    // Clean up interval on component unmount
    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    };
  }, [accessToken]);

  // Fetch case details when currentCaseId changes
  useEffect(() => {
    if (currentCaseId) {
      fetchCaseDetails(currentCaseId);
    } else {
      setCaseDetails(null);
      setCptResults(null);
      setShowContentEntry(true); // Always show content entry when no case is selected
    }
  }, [currentCaseId, accessToken]);

  const fetchCases = async () => {
    try {
      const response = await callApi('/list_external_cases', accessToken);
      const data = await response.json();
      if (response.ok) {
        // Filter out cases without encounter_id
        const filteredCases = (data.cases || []).filter(caseItem => caseItem.encounter_id);
        
        // Sort filtered cases by date_updated in descending order (most recent first)
        const sortedCases = filteredCases.sort((a, b) => {
          return b.date_updated.localeCompare(a.date_updated);
        });
        
        setCases(sortedCases);
      } else {
        toast({
          title: 'Error fetching cases',
          description: data.error || 'Failed to fetch cases',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };  

  // Helper function to format date in a more readable way
  const formatDateShort = (dateString) => {
    if (!dateString) return '';
    
    const parts = dateString.split(' ');
    if (parts.length !== 2) return dateString;
    
    const datePart = parts[0];
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    const [year, month, day] = datePart.split('-').map(Number);
    const dateObj = new Date(year, month - 1, day);
    
    if (dateObj.getTime() === today.getTime()) {
      return 'Today';
    } else if (dateObj.getTime() === yesterday.getTime()) {
      return 'Yesterday';
    } else {
      return `${month}/${day}/${year.toString().substr(2)}`;
    }
  };

  const fetchCaseDetails = async (caseId) => {
    try {
      const response = await callApi(`/get_case_details/${caseId}`, accessToken);
      const data = await response.json();
      if (response.ok) {
        setCaseDetails(data);
        // Set CPT results based on the fetched data
        if (data.coding_results) {
          setCptResults(data.coding_results);
          // Auto-collapse content entry when results are loaded
          setShowContentEntry(false);
        } else {
          setCptResults(null);
        }
      } else {
        toast({
          title: 'Error fetching case details',
          description: data.error || 'Failed to fetch case details',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const createCase = async () => {
    try {
      const randomCaseId = Math.random().toString(36).substr(2, 9);
      
      const response = await callApi('/create_external_case', accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          external_case_id: randomCaseId,
          case_type: 'Surgery'
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setCurrentCaseId(data.case_id);
        toast({
          title: 'Case created automatically',
          description: `Working with new case ID: ${data.case_id}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        await fetchCases(); // Refresh the cases list
        return data.case_id;
      } else {
        throw new Error(data.error || 'Failed to create case');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };

  const selectCase = (caseId) => {
    setCurrentCaseId(caseId);
    // Clear previous results immediately when switching cases
    setCptResults(null);
    // Show content entry when selecting a new case
    setShowContentEntry(true);
    
    // Then fetch the new case details
    fetchCaseDetails(caseId).then(() => {
      // After fetching, update results if they exist
      if (caseDetails && caseDetails.coding_results) {
        setCptResults(caseDetails.coding_results);
        // Auto-collapse content entry when results are loaded
        setShowContentEntry(false);
      }
    });
    
    toast({
      title: 'Case selected',
      description: `Working with case ID: ${caseId}`,
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  // Function to handle creating a new case (clearing the current selection)
  const handleNewCase = () => {
    setCurrentCaseId(null);
    setCptResults(null);
    setTextContent('');
    setShowContentEntry(true); // Always show content entry for new cases
    toast({
      title: 'New Case',
      description: 'Ready to create a new case',
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  const submitContent = async (caseId, formData, endpoint) => {
    const response = await callApi(endpoint, accessToken, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    if (response.ok) {
      toast({
        title: 'Content uploaded',
        description: 'Your content was successfully uploaded',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCases(); // Refresh the cases list
      fetchCaseDetails(caseId); // Refresh the case details
      return data;
    } else {
      throw new Error(data.error || 'Failed to upload content');
    }
  };

  const handleTextSubmit = async () => {
    setProcessing(true);
    try {
      // Validate content
      if (!textContent.trim()) {
        toast({
          title: 'Empty content',
          description: 'Please enter some text before submitting',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        setProcessing(false);
        return;
      }

      // If no case is selected, create one automatically
      let caseIdToUse = currentCaseId;
      
      if (!caseIdToUse) {
        caseIdToUse = await createCase();
        if (!caseIdToUse) {
          throw new Error('Failed to create a new case');
        }
        // Explicitly set the current case ID here to ensure it's updated
        setCurrentCaseId(caseIdToUse);
      }

      const formData = new FormData();
      formData.append('file_type_id', '5'); // Default to Clinical Notes

      const blob = new Blob([textContent], { type: 'text/plain' });
      formData.append('file', blob, 'content.txt');

      await submitContent(caseIdToUse, formData, `/upload_content/${caseIdToUse}`);
      
      // Clear the text content after successful submission
      setTextContent('');
    } catch (error) {
      console.error('Error submitting content:', error);
      toast({
        title: 'Content submission failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleFileUpload = async () => {
    try {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.onchange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
          return; // User canceled file selection, do nothing
        }
        
        setProcessing(true);
        
        try {
          // Only create a new case if no case is selected AND we have a file
          let caseIdToUse = currentCaseId;
          if (!caseIdToUse) {
            caseIdToUse = await createCase();
            if (!caseIdToUse) {
              throw new Error('Failed to create a new case');
            }
          }
          
          const formData = new FormData();
          formData.append('file_type_id', '5'); // Default to Clinical Notes
          formData.append('file', file);
  
          await submitContent(caseIdToUse, formData, `/upload_file/${caseIdToUse}`);
        } catch (error) {
          console.error('Error uploading file:', error);
          toast({
            title: 'File upload failed',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setProcessing(false);
        }
      };
      fileInput.click();
    } catch (error) {
      console.error('Error initiating file upload:', error);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const performCptCoding = async () => {
    if (!currentCaseId) {
      toast({
        title: 'No case selected',
        description: 'Please add content first to create a case',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    setProcessing(true);
    console.log('socket status', socket?.id);

    try {
      const response = await callApi(`/cpt_coding`, accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          case_id: currentCaseId,
          case_type: 'Surgery', // Default to Surgery type
          socket_id: socket?.id // Include the socket ID for real-time updates
        }),
      });
  
      const data = await response.json();
      if (response.ok) {
        // We'll get the final results via socket, but we can still set initial results if available
        if (data.results) {
          setCptResults(data.results);
          // Auto-collapse content entry when results arrive
          setShowContentEntry(false);
        }
        
        toast({
          title: 'CPT Coding In Progress',
          description: 'You will be notified when coding is complete',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        
        // Refresh case details to get updated status
        fetchCaseDetails(currentCaseId);
        fetchCases(); // Refresh the cases list to update status
      } else {
        throw new Error(data.error || 'Failed to perform CPT coding');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const formatResults = (results) => {
    if (!results) return null;
    
    return (
      <Box>
        {/* Coder Notes Section */}
        {results.coder_notes && (
          <Box mb={5} p={4} borderWidth="1px" borderRadius="md" bg="gray.50">
            <Heading size="md" mb={3}>Coder Notes</Heading>
            <Text whiteSpace="pre-line">{results.coder_notes}</Text>
          </Box>
        )}
        {/* CPT Codes Section */}
        {results.cpt_codes && Object.entries(results.cpt_codes).map(([code, details], index, array) => (
          <Box key={code} mb={5}>
            <Heading size="md" mb={1}>{code}</Heading>
            <Text fontWeight="bold" mb={3}>{details.long_description}</Text>
            
            <Box pl={4} mb={3}> {/* Indented content under each CPT code */}
              <Text mb={1}>Billable: {details.billable ? "Yes" : "No"}</Text>
              {details.work_rvu !== 0 && <Text mb={1}>Work RVU: {details.work_rvu}</Text>}
              <Text mb={1}>Units: {details.units}</Text>
              <Text mb={1}>Modifiers: {details.modifiers || "none"}</Text>
              {details.estimated_facility_reimbursement && 
                <Text mb={1}>Facility Reimbursement: ${details.estimated_facility_reimbursement.toFixed(2)}</Text>
              }
              {details.estimated_non_facility_reimbursement && 
                <Text mb={1}>Non-Facility Reimbursement: ${details.estimated_non_facility_reimbursement.toFixed(2)}</Text>
              }
              
              <Text fontWeight="bold" mt={3} mb={1}>Justification:</Text>
              <Box 
                pl={2}
                fontSize="sm" /* Smaller font size for justification */
                dangerouslySetInnerHTML={{ 
                  __html: details.justification.replace(/\n/g, '<br/>') 
                }}
              />
            </Box>
            
            {index !== array.length - 1 && <Divider my={4} />}
          </Box>
        ))}
        
        {/* Raw JSON Output */}
        <Box p={4} borderWidth="1px" borderRadius="md" bg="gray.50" overflowX="auto">
          <Heading size="sm" mb={2}>Raw JSON Data</Heading>
          <div 
            dangerouslySetInnerHTML={{ 
              __html: `<pre style="white-space: pre-wrap; font-size: 0.875rem;">${JSON.stringify(results, null, 2).replace(/\\n/g, '<br/>')}</pre>` 
            }} 
          />
        </Box>
      </Box>
    );
  };

  // Format the date to a more readable format
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  // Format file size to human-readable format
  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' B';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
    else return (bytes / 1048576).toFixed(1) + ' MB';
  };

  // Get file count for a case
  const getFileCount = (caseItem) => {
    return Object.keys(caseItem.files || {}).length;
  };

  // Handle file click to show content in modal
  const handleFileClick = (file) => {
    setSelectedFile(file);
    onOpen();
  };

  // Render status indicator based on case status
  const renderStatusIndicator = (status) => {
    if (!status) return null;
    
    if (status.toLowerCase() === 'in_progress') {
      return (
        <Spinner size="xs" color="orange.500" mr={1} />
      );
    } else if (status.toLowerCase() === 'complete') {
      return (
        <Icon as={CheckIcon} color="green.500" mr={1} />
      );
    }
    return null;
  };

  // Function to handle text submission and then coding
  const handleSubmitAndCode = async () => {
    if (!textContent.trim()) {
      toast({
        title: 'Empty content',
        description: 'Please enter some text before submitting',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    try {
      // If no case is selected, create one automatically
      let caseIdToUse = currentCaseId;
      
      if (!caseIdToUse) {
        caseIdToUse = await createCase();
        if (!caseIdToUse) {
          throw new Error('Failed to create a new case');
        }
        // Explicitly set the current case ID
        setCurrentCaseId(caseIdToUse);
      }

      // Upload the text content
      const formData = new FormData();
      formData.append('file_type_id', '5'); // Default to Clinical Notes
      const blob = new Blob([textContent], { type: 'text/plain' });
      formData.append('file', blob, 'content.txt');
      
      await submitContent(caseIdToUse, formData, `/upload_content/${caseIdToUse}`);
      
      // Clear the text content after successful submission
      setTextContent('');
      
      // Now perform CPT coding on the case - using the local caseIdToUse variable
      // instead of relying on the currentCaseId state
      await callApi(`/cpt_coding`, accessToken, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          case_id: caseIdToUse,
          case_type: 'Surgery', // Default to Surgery type
          socket_id: socket?.id // Include the socket ID for real-time updates
        }),
      }).then(async response => {
        const data = await response.json();
        if (response.ok) {
          // We'll get the final results via socket, but we can still set initial results if available
          if (data.results) {
            setCptResults(data.results);
            // Auto-collapse content entry when results arrive
            setShowContentEntry(false);
          }
          
          toast({
            title: 'CPT Coding In Progress',
            description: 'You will be notified when coding is complete',
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
          
          // Refresh case details to get updated status
          fetchCaseDetails(caseIdToUse);
          fetchCases(); // Refresh the cases list to update status
        } else {
          throw new Error(data.error || 'Failed to perform CPT coding');
        }
      });
      
    } catch (error) {
      console.error('Error in submit and code process:', error);
      toast({
        title: 'Process failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleUploadAndCode = async () => {
    try {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.onchange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
          return; // User canceled file selection
        }
        
        setProcessing(true);
        try {
          // Only create a new case if no case is selected
          let caseIdToUse = currentCaseId;
          if (!caseIdToUse) {
            caseIdToUse = await createCase();
            if (!caseIdToUse) {
              throw new Error('Failed to create a new case');
            }
            // Update the state
            setCurrentCaseId(caseIdToUse);
          }
          
          const formData = new FormData();
          formData.append('file_type_id', '5'); // Default to Clinical Notes
          formData.append('file', file);

          // Upload the file
          await submitContent(caseIdToUse, formData, `/upload_file/${caseIdToUse}`);
          
          // Now perform CPT coding on the case - using the local caseIdToUse variable
          // instead of relying on the currentCaseId state
          await callApi(`/cpt_coding`, accessToken, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              case_id: caseIdToUse,
              case_type: 'Surgery', // Default to Surgery type
              socket_id: socket?.id // Include the socket ID for real-time updates
            }),
          }).then(async response => {
            const data = await response.json();
            if (response.ok) {
              // We'll get the final results via socket, but we can still set initial results if available
              if (data.results) {
                setCptResults(data.results);
                // Auto-collapse content entry when results arrive
                setShowContentEntry(false);
              }
              
              toast({
                title: 'CPT Coding In Progress',
                description: 'You will be notified when coding is complete',
                status: 'info',
                duration: 3000,
                isClosable: true,
              });
              
              // Refresh case details to get updated status
              fetchCaseDetails(caseIdToUse);
              fetchCases(); // Refresh the cases list to update status
            } else {
              throw new Error(data.error || 'Failed to perform CPT coding');
            }
          });
        } catch (error) {
          console.error('Error in upload and code process:', error);
          toast({
            title: 'Process failed',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setProcessing(false);
        }
      };
      fileInput.click();
    } catch (error) {
      console.error('Error initiating file upload:', error);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Toggle content entry visibility
  const toggleContentEntry = () => {
    setShowContentEntry(!showContentEntry);
  };

  return (
    <Box fontSize="xl">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
      >
        <HeaderMenus />
  
        <GridItem bg='white' padding={4} area={'main'}>
          <Flex>
  
            {/* Left Column - Case List */}
            <Box width="280px" pr={4} borderRightWidth="1px">
              {/* New Case Button */}
              <Button 
                leftIcon={<AddIcon />} 
                colorScheme="green" 
                variant="solid" 
                size="md" 
                width="100%" 
                mb={4}
                onClick={handleNewCase}
                isDisabled={!currentCaseId} // Disable when NO case is selected
              >
                New Case
              </Button>

              <Heading size="md" mb={4}>Cases</Heading>

              <VStack spacing={3} align="stretch" maxH="calc(100vh - 200px)" overflowY="auto">
                {cases.length > 0 ? (
                  cases.map(caseItem => (
                    <Box 
                      key={caseItem.id}
                      p={3}
                      borderWidth="1px"
                      borderRadius="md"
                      bg={currentCaseId === caseItem.id ? "green.50" : "white"}
                      borderColor={currentCaseId === caseItem.id ? "green.400" : "gray.200"}
                      cursor="pointer"
                      onClick={() => selectCase(caseItem.id)}
                      _hover={{ 
                        bg: currentCaseId === caseItem.id ? "green.100" : "gray.50",
                        borderColor: currentCaseId === caseItem.id ? "green.500" : "gray.300"
                      }}
                      transition="all 0.2s"
                    >
                      <HStack mb={1}>
                        {renderStatusIndicator(caseItem.status)}
                        
                        {/* Case description as main bold text that wraps */}
                        <Text fontWeight="bold" fontSize="sm">
                          {caseItem.case_description || "No description"}
                        </Text>
                      </HStack>
                      
                      {/* Date and ID on the second line */}
                      <Text fontSize="xs" color="gray.600" mb={2}>
                        {formatDateShort(caseItem.date_updated)} - {caseItem.external_case_id}
                      </Text>
                      
                      {/* Status, case type, and file count on the last line */}
                      <HStack spacing={2} mt={1}>
                        {caseItem.status && (
                          <Badge 
                            colorScheme={caseItem.status.toLowerCase() === 'complete' ? "green" : 
                                        caseItem.status.toLowerCase() === 'in_progress' ? "orange" : "gray"} 
                            fontSize="xs"
                          >
                            {caseItem.status}
                          </Badge>
                        )}
                        <Badge colorScheme="purple" fontSize="xs">{caseItem.case_type}</Badge>
                        <Badge colorScheme="blue" fontSize="xs">{getFileCount(caseItem)} files</Badge>
                      </HStack>
                    </Box>
                  ))
                ) : (
                  <Box p={4} borderWidth="1px" borderRadius="md" bg="gray.50">
                    <Text color="gray.500" fontSize="sm" textAlign="center">
                      No cases yet. Add content to create your first case.
                    </Text>
                  </Box>
                )}
              </VStack>
            </Box>
  
            {/* Middle Column - Content Entry and CPT Results */}
            <Box flex="1" pl={4} pr={4}>
              <VStack spacing={6} align="stretch">
                
                {/* Content Entry Section with Collapsible Header */}
                <Card variant="outline" borderColor="teal.200">
                  <CardHeader p={3} bg="teal.50" onClick={toggleContentEntry} cursor="pointer">
                    <Flex justify="space-between" align="center">
                      <Text fontWeight="bold" fontSize="md">
                        {!currentCaseId ? "Code Note: Paste or Upload Here" : "Add Content to Current Case"}
                      </Text>
                      <IconButton
                        icon={showContentEntry ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        variant="ghost"
                        aria-label={showContentEntry ? "Collapse" : "Expand"}
                        size="sm"
                      />
                    </Flex>
                  </CardHeader>
                  
                  <Collapse in={showContentEntry} animateOpacity>
                    <CardBody>
                      {/* Text Entry Section */}
                      <Box width="100%" mb={4}>
                        <Textarea
                          placeholder="Enter note or any other documentation here..."
                          value={textContent}
                          onChange={(e) => setTextContent(e.target.value)}
                          rows={6}
                          mb={3}
                        />
                        <HStack spacing={3}>
                          {!currentCaseId ? (
                            /* New Case Mode - Submit and Code */
                            <>
                              <Button
                                leftIcon={<CheckCircleIcon />}
                                colorScheme="teal"
                                onClick={handleSubmitAndCode}
                                isLoading={processing}
                              >
                                Submit & Code
                              </Button>
                              <Button
                                variant="outline"
                                colorScheme="teal"
                                onClick={handleTextSubmit}
                                isLoading={processing}
                                size="md"
                              >
                                Submit and Add More Content
                              </Button>
                            </>
                          ) : (
                            /* Adding to Existing Case */
                            <>
                              <Button
                                leftIcon={<AddIcon />}
                                colorScheme="teal"
                                onClick={handleTextSubmit}
                                isLoading={processing}
                              >
                                Add to Current Case
                              </Button>
                            </>
                          )}
                        </HStack>
                      </Box>

                      {/* Horizontal Divider */}
                      <Divider my={4} />

                      {/* File Upload Section */}
                      <Box width="100%">
                        <Text mb={3}>Or upload pdf, txt, png, or jpg files:</Text>
                        <HStack spacing={3}>
                          {!currentCaseId ? (
                            /* New Case Mode - Upload and Code */
                            <>
                              <Button
                                leftIcon={<AttachmentIcon />}
                                colorScheme="blue"
                                onClick={handleUploadAndCode}
                                isLoading={processing}
                              >
                                Upload & Code
                              </Button>
                              <Button
                                variant="outline"
                                colorScheme="blue"
                                onClick={handleFileUpload}
                                isLoading={processing}
                              >
                                Upload and Add More Content
                              </Button>
                            </>
                          ) : (
                            /* Adding to Existing Case */
                            <>
                              <Button
                                leftIcon={<AttachmentIcon />}
                                colorScheme="blue"
                                onClick={handleFileUpload}
                                isLoading={processing}
                              >
                                Add to Current Case
                              </Button>
                            </>
                          )}
                        </HStack>
                      </Box>
                    </CardBody>
                  </Collapse>
                </Card>

                {/* Generate CPT Codes Button */}
                {currentCaseId && (
                  <Box mb={4} display="flex" alignItems="center">
                    <Button 
                      colorScheme="green" 
                      size="lg" 
                      onClick={performCptCoding} 
                      isDisabled={!currentCaseId || (caseDetails?.status?.toLowerCase() === 'in_progress')} 
                      isLoading={processing} 
                      leftIcon={<CheckCircleIcon />} 
                      mr={3}
                    >
                      {cptResults ? "Re-Code Case" : "Code Case"}
                    </Button>
                    <Text color={currentCaseId ? "green.600" : "gray.500"} fontSize="md">
                      {currentCaseId 
                        ? (caseDetails?.status?.toLowerCase() === 'in_progress' 
                          ? "Coding in progress..." 
                          : caseDetails?.status?.toLowerCase() === 'complete' 
                            ? "Coding complete" 
                            : "Ready to code case") 
                        : "Add content first to create a case"}
                    </Text>
                  </Box>
                )}

                {/* Results Section */}
                {cptResults && (
                  <Card variant="outline">
                    <CardHeader p={3} bg="green.50">
                      <Text fontWeight="bold" fontSize="md">CPT Coder Results</Text>
                    </CardHeader>
                    <CardBody>
                      {formatResults(cptResults)}
                    </CardBody>
                  </Card>
                )}
              </VStack>
            </Box>

            {/* Right Column - Case Details */}
            <Box width="350px" pl={4} borderLeftWidth="1px">
              <Card variant="outline" height="100%">
                <CardHeader p={3} bg="blue.50">
                  <Text fontWeight="bold" fontSize="md">Case Details</Text>
                </CardHeader>
                <CardBody>
                  {caseDetails ? (
                    <>
                      {caseDetails.status && (
                        <Badge colorScheme={caseDetails.status.toLowerCase() === 'complete' ? "green" : caseDetails.status.toLowerCase() === 'in_progress' ? "orange" : "gray"} mb={2}>
                          {renderStatusIndicator(caseDetails.status)} {caseDetails.status}
                        </Badge>
                      )}
                      <Text><strong>Case ID:</strong> {caseDetails.external_case_id}</Text>
                      <Text><strong>Type:</strong> {caseDetails.case_type}</Text>
                      <Text><strong>Created:</strong> {formatDate(caseDetails.date_created)}</Text>

                      {caseDetails.files && caseDetails.files.length > 0 && (
                        <Box mt={4}>
                          <Heading size="sm" mb={2}>Uploaded Content</Heading>


                            <Table variant="simple" size="sm">
                              <Thead>
                                <Tr>
                                  <Th>File Name</Th>
                                  <Th>Type</Th>
                                  <Th>Size</Th>
                                  <Th>Actions</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {caseDetails.files.map(file => (
                                  <Tr key={file.id}>
                                    <Td 
                                      color="blue.600" 
                                      textDecoration="underline"
                                      cursor="pointer" 
                                      onClick={() => handleFileClick(file)}
                                    >
                                      {file.file_name}
                                    </Td>
                                    <Td>
                                      <Tag size="sm" colorScheme="blue" borderRadius="full">
                                        <TagLabel>{file.file_type}</TagLabel>
                                      </Tag>
                                    </Td>
                                    <Td>{formatFileSize(file.file_size)}</Td>

                                    <Td>
                                      <IconButton
                                        icon={<DownloadIcon />}
                                        size="sm"
                                        aria-label="View original file"
                                        title="View original file"
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent triggering the row click
                                          handleFileDownload(file);
                                        }}
                                        isDisabled={processing} // Use the existing processing state
                                      />
                                    </Td>

                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>









                        </Box>
                      )}
                    </>
                  ) : (
                    <Text color="gray.500">Select or create a case to view details</Text>
                  )}
                </CardBody>
              </Card>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
  
      {/* File Content Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>
            {selectedFile?.file_name}
            <Tag size="sm" colorScheme="blue" ml={2} mt={-1}>
              <TagLabel>{selectedFile?.file_type}</TagLabel>
            </Tag>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box 
              p={4} 
              borderWidth="1px" 
              borderRadius="md" 
              bg="gray.50" 
              maxHeight="70vh" 
              overflowY="auto"
              whiteSpace="pre-wrap"
              fontFamily="monospace"
            >
              {selectedFile?.file_text || "No content available"}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Text fontSize="sm" color="gray.500" mr="auto">
              {selectedFile ? formatFileSize(selectedFile.file_size) : ""} • 
              {selectedFile ? " Uploaded " + formatDate(selectedFile.upload_date) : ""}
            </Text>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default FullCPTCoder;